import * as React from "react";
import {Component} from "react";
import * as DOMPurify from "dompurify";

/**
 * props of a submit button
 */
interface ISubmitButtonProps {
    label: string;
    wrapClass?: string;
    buttonClass?: string;
    disabled?: boolean;
}

/**
 * a submit button react component
 */
export default abstract class SubmitButton extends Component<ISubmitButtonProps> {
    /**
     * the render
     */
    public render(): JSX.Element {
        return (
            <div className={"button-wrap " + (this.props.wrapClass || "")}>
                <button
                    type="submit"
                    className={(this.props.buttonClass || "")}
                    disabled={this.props.disabled}
                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.label)}}
                />
            </div>
        );
    }
}