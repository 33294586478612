
interface IDiaporamaConfig<T> {
    default: T;
    landing: T;
    product: T;
    priceList: T;
    agency: T;
    resa: T;
}

/**
 * Map of Slick options configuration
 * @type {IDiaporamaConfig<JQuerySlickOptions>}
 */
export const diaporamaConfig: IDiaporamaConfig<JQuerySlickOptions> = {
    // Config default for slider product
    default: {
        rows: 0,
        accessibility: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
    },
    landing: {
        rows: 0,
        accessibility: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5500,
        dots: true
    },
    product: {
        rows: 0,
        accessibility: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true
    },
    priceList: {
        rows: 0,
        accessibility: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5500,
        dots: true
    },
    agency: {
        rows: 0,
        accessibility: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5500
    },
    resa: {
        rows: 0,
        accessibility: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true
    }
};









