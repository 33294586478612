import * as React from "react";
import {Component} from "react";
import {getSearchItemComponent, ISearchItem, ISearchItemProps, SearchItemType} from "../index";
import {inject, observer} from "mobx-react";
import {SearchEngineStore} from "../../SearchEngineStore";
import {ISelectSearchItemProps} from "../SelectSearchItem";
import {IAsyncSelectSearchItemProps} from "../AsyncSelectSearchItem";
import {ISearchEngineText} from "../../text";
import {ICheckOption} from "../CheckSearchItem";
import {SyntheticEvent} from "react";

export interface IPanelSearchItemProps extends ISearchItemProps {
    subItems: ISearchItem[];
    closeable?: boolean;
    placeholder?: string;
    triggerDiv?: boolean;
    triggerDivLabel?: string;
    containerTitle?: string;
    valueLabelFormat?: string;
    footerControls?: boolean;
    validLabel?: string;
    resetLabel?: string;
    submitOnValid?: boolean;
    searchEngineStore: SearchEngineStore;
    searchEngineText?: ISearchEngineText;
    keepOnClean?: boolean;
}

interface IPanelSearchItemState {
    open: boolean;
    text?: string;
}

/**
 * the panel filter item, a multi filter item components
 */
@inject("searchEngineText")
@inject("searchEngineStore")
@observer
export default class PanelSearchItem extends Component<IPanelSearchItemProps, IPanelSearchItemState> {
    private ref: HTMLDivElement;
    constructor(props: IPanelSearchItemProps) {
        super(props);
        this.handleClosePanel = this.handleClosePanel.bind(this);
        this.cleanSubItems = this.cleanSubItems.bind(this);
        this.state = {open: !props.closeable, text: this.getValueLabel("")};
        this.handleValidation = this.handleValidation.bind(this);
    }

    /**
     * component did update
     * @param prevProps
     * @param prevState
     */
    public componentDidUpdate(prevProps: Readonly<IPanelSearchItemProps>, prevState: Readonly<IPanelSearchItemState>): void {
        if (prevState.open !== this.state.open) {
            $(this.ref).trigger("search-item:" + (this.state.open ? "opening" : "closing"));
        }
    }

    /**
     * clean input of all sub items
     */
    private cleanSubItems() {
        this.props.subItems.map((subItem: ISearchItem) => {
            this.props.searchEngineStore.clearOptions(subItem.data.name);
        });
        this.setState({text: undefined});
        $(this.ref).trigger("panel-search-item:change");
    }

    /**
     * clean input of sub item
     * @param subItem
     */
    private cleanSubItem(subItem: ISearchItem) {
        this.props.searchEngineStore.clearOptions(subItem.data.name);
        this.setState({
            text: undefined,
            open: this.props.keepOnClean
        });
        $(this.ref).trigger("panel-search-item:change");
    }

    /**
     * component did mount
     */
    public componentDidMount() {
        this.ref.addEventListener("panel-search-item:close", this.handleClosePanel);
    }

    /**
     * component will unmount
     */
    public componentWillUnmount() {
        this.ref.removeEventListener("panel-search-item:close", this.handleClosePanel);
    }

    /**
     * Renders component
     */
    public render(): JSX.Element {
        return this.props.manageOwnLabel ? this.renderItemWithLabel() : this.renderItem();
    }

    /**
     *  Renders item with its label
     */
    private renderItemWithLabel(): JSX.Element {
        return (
            <>
                <div className="item-label-se">{this.props.label}</div>
                <div className="item-content-panel">{this.renderItem()}</div>
            </>
        );
    }

    /**
     * Renders item
     */
    private renderItem(): JSX.Element {
        const subItems = this.props.subItems;
        return (
            <div ref={(panel: HTMLDivElement) => this.ref = panel} className={"panel-item" + (this.state.open ? " open" : "")}>
                {this.props.closeable && this.getTriggerElement()}
                {subItems && subItems.length > 0 &&
                    <div className={"panel-container" + (!this.state.open ? " d-none" : "")}>
                        {this.props.containerTitle &&
                            <div className="panel-container-title">{this.props.containerTitle}</div>
                        }
                        <div className="inner-panel-container">
                            <div className="panel-items">
                                {subItems.map(this.renderSubItem())}
                            </div>
                            {this.props.footerControls && this.renderFooterControls()}
                        </div>
                    </div>
                }
            </div>
        );
    }

    /**
     * Renders footer controls
     */
    private renderFooterControls() {
        return (
            <div className="footer-controls-block">
                <div className="button-wrap">
                    {
                        this.props.resetLabel &&
                        <span className="elem-button--border-default btn-panel" onClick={this.cleanSubItems}>
                            {this.props.resetLabel}
                        </span>
                    }
                    {
                        this.props.validLabel &&
                        <span className="elem-button--primary btn-panel" onClick={this.handleValidation}>
                            {this.props.validLabel}
                        </span>
                    }
                </div>
            </div>
        );
    }

    /**
     * Handles validation click
     */
    private handleValidation() {
        this.handleClosePanel();
        if (this.props.submitOnValid) {
            $(this.ref).trigger("search-engine:submit");
        }
    }

    /**
     * create inner components
     */
    private renderSubItem() {
        return (item: ISearchItem, i: number) => {
            if (item.type === SearchItemType.SELECT) {
                (item.data as ISelectSearchItemProps).focusOnUpdate = true;
            } else if (item.type === SearchItemType.ASYNC_SELECT) {
                (item.data as IAsyncSelectSearchItemProps).focusOnUpdate = true;
            }
            const searchItemComponent = getSearchItemComponent(item.type, item.data, item.label, item.disabled, (label: string) => {
                const newLabel = this.getValueLabel(label);
                this.setState({text: newLabel});
                $(this.ref).trigger("panel-search-item:change");
            }, ((label: string) => {
                const newLabel = this.getValueLabel(label);
                if (newLabel !== this.state.text) {
                    this.setState({text: newLabel});
                }
            }));

            return (
                <div key={"panelSearchItem_" + i} className={"panel-item " + (item.code || "")}>
                    {this.renderItemLabel(item)}
                    {searchItemComponent}
                </div>
            );
        };
    }

    /**
     * Renders item label
     */
    private renderItemLabel(item: ISearchItem) {
        if (item.label) {
            return (
                <div className={"header-content"}>
                    {!item.data.manageOwnLabel && <div className="item-label-se">{item.label}</div>}
                    <div className={"clean"} onClick={() => this.cleanSubItem(item)}>
                        {
                            this.props.searchEngineText && this.props.searchEngineText.common && this.props.searchEngineText.common.cleanTitle &&
                            <div className={"clean-title"}>{this.props.searchEngineText.common.cleanTitle}</div>
                        }
                        <div className={"clean-icon"}/>
                    </div>
                </div>
            );
        }
        return "";
    }

    /**
     * get selected sub items count
     */
    private getSelectedSubItemsCount() {
        return this.props.subItems.map((subItem: ISearchItem) => {
            if (this.props.searchEngineStore.values[subItem.data.name]) {
                if (subItem.type === SearchItemType.CHECK) {
                    const allOptions = this.props.searchEngineStore.values[subItem.data.name].join(",");
                    // @ts-ignore
                    return subItem.data.options.filter((option: ICheckOption) => allOptions.indexOf(option.code) !== -1).length;
                } else {
                    return this.props.searchEngineStore
                        .values[subItem.data.name]
                        .filter((value: string) => value).length;
                }
            }
            return 0;
        }).reduce((a: number, b: number) => a + b, 0);
    }

    /**
     * Gets updated value label
     * @param computedLabel the original computed label from sub item
     */
    private getValueLabel(computedLabel: string) {
        const selectedOptionNumber = this.getSelectedSubItemsCount();
        if (selectedOptionNumber === 0) {
            return undefined;
        }
        if (this.props.valueLabelFormat) {
            return this.props.valueLabelFormat
                .replace("{count}", String(selectedOptionNumber))
                .replace("{label}", String(computedLabel));
        }
        return computedLabel;
    }

    /**
     * Get trigger element
     */
    private getTriggerElement(): JSX.Element {
        if (this.props.triggerDiv) {
            return (
                <div
                    className={"trigger-panel" + (this.state.text ? "" : " no-value")}
                    onClick={() => this.setState({open: !this.state.open})}
                >
                    <div className="label-wrap">
                        {this.props.triggerDivLabel &&
                            <span className="label">
                                {this.props.triggerDivLabel}
                            </span>
                        }
                        <span className="input">{this.state.text || this.props.placeholder || ""}</span>
                    </div>
                    <div className="arrow">
                        <i
                            className="elem-ff-icon"
                            data-icon={(this.state.text ? "x" : ">")}
                            role="img"
                            onClick={(this.state.text ? (evt: SyntheticEvent<HTMLElement>) => {
                                evt.stopPropagation();
                                this.cleanSubItems();
                            } : undefined)}
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    <div
                        className={"elem-custom-input"}
                        placeholder={this.props.placeholder}
                        onClick={() => this.setState({open: !this.state.open})}
                    >
                        {this.state.text || ""}
                        {
                            !this.state.text &&
                            <div className={"elem-custom-input-placeholder"}>{this.props.placeholder}</div>
                        }
                    </div>
                    {
                        this.state.text &&
                        <div className={"clean"} onClick={this.cleanSubItems}>
                            {
                                this.props.searchEngineText && this.props.searchEngineText.common && this.props.searchEngineText.common.cleanTitle &&
                                <div className={"clean-title"}>{this.props.searchEngineText.common.cleanTitle}</div>
                            }
                            <div className={"clean-icon"}/>
                        </div>
                    }
                </>
            );
        }
    }

    /**
     * handle event close on panel
     */
    private handleClosePanel() {
        this.setState({open: false});
    }
}