import * as React from "react";
import {Component} from "react";
import {SearchEngineStore} from "../../SearchEngineStore";
import ListLinks from "../SelectSearchItem/ListLinks";
import {ISearchItemProps} from "../index";

export interface ILinkSearchItemProps extends ISearchItemProps {
    searchEngineStore: SearchEngineStore;
    addClass?: string;
    title?: string;
    links: ILinkGroupProps[];
}

export interface ILinkGroupProps {
    title?: string;
    columns?: number;
    options: ILinkOptionProps[];
}

export interface ILinkOptionProps {
    label: string;
    link: string;
    class?: string;
    icon?: string;
}

/**
 * the link search item component
 * @class LinkSearchItem
 */
export default class LinkSearchItem extends Component<ILinkSearchItemProps> {

    /**
     * Constructor
     * @param props
     */
    constructor(props: ILinkSearchItemProps) {
        super(props);
    }

    /**
     * Renders component
     */
    public render(): JSX.Element {
        return this.props.manageOwnLabel ? this.renderItemWithLabel() : this.renderItem();
    }

    /**
     *  Renders item with its label
     */
    private renderItemWithLabel() {
        return (
            <>
                <div className="item-label-se">{this.props.label}</div>
                <div className="item-content-se">{this.renderItem()}</div>
            </>
        );
    }

    /**
     * Renders item
     */
    private renderItem(): JSX.Element {
        const { title, links, addClass } = this.props;
        const prefix = "panel";
        return (
            <div className={"search-engine-panel " + (addClass || "")}>
                {title &&
                    <div className={prefix + "-title"}>{title}</div>
                }
                <div className={prefix + "-content"}>
                    {links && links.map((linkGroup: ILinkGroupProps, index: number) => {
                        return <ListLinks
                            key={prefix + "_type_" + index}
                            prefix={prefix}
                            linkGroup={linkGroup}
                        />;
                    })}
                </div>
            </div>
        );
    }
}