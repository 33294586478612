import moment = require("moment");

interface SchoolVacationMap {
    CLASS_001: string[];
    CLASS_010: string[];
    CLASS_011: string[];
    CLASS_100: string[];
    CLASS_101: string[];
    CLASS_110: string[];
    CLASS_111: string[];
}
interface AllDates {
    schoolVacationMap: SchoolVacationMap;
    holidays: string[]
}

export class HolidaysDates {
    holidays: Date[];
    classZones001: Date[];
    classZones010: Date[];
    classZones011: Date[];
    classZones100: Date[];
    classZones101: Date[];
    classZones110: Date[];
    classZones111: Date[];
}

export function initializeHolidays(dataUrl: string, callback?: (holidays: HolidaysDates) => void) {
    $.ajax({
        url: dataUrl,
        dataType: "json"
    }).done((response: AllDates) => {
        const holidaysData = convertsHolidays(response);
        callback(holidaysData);
    });
}

function addsDateInArray(stringDates: string[], dates: Date[], format: string) {
    if (stringDates) {
        stringDates.forEach(date => {
            dates.push(moment(date, format).toDate());
        });
    }
}

function convertsHolidays(allDates: AllDates) : HolidaysDates {
    const holidays = new HolidaysDates();
    holidays.holidays = [];
    holidays.classZones001 = [];
    holidays.classZones010 = [];
    holidays.classZones011 = [];
    holidays.classZones100 = [];
    holidays.classZones101 = [];
    holidays.classZones110 = [];
    holidays.classZones111 = [];
    addsDateInArray(allDates.holidays, holidays.holidays, "DD/MM/YYYY");
    if (allDates.schoolVacationMap) {
        addsDateInArray(allDates.schoolVacationMap.CLASS_001, holidays.classZones001, "DD/MM/YYYY");
        addsDateInArray(allDates.schoolVacationMap.CLASS_010, holidays.classZones010, "DD/MM/YYYY");
        addsDateInArray(allDates.schoolVacationMap.CLASS_011, holidays.classZones011, "DD/MM/YYYY");
        addsDateInArray(allDates.schoolVacationMap.CLASS_100, holidays.classZones100, "DD/MM/YYYY");
        addsDateInArray(allDates.schoolVacationMap.CLASS_101, holidays.classZones101, "DD/MM/YYYY");
        addsDateInArray(allDates.schoolVacationMap.CLASS_110, holidays.classZones110, "DD/MM/YYYY");
        addsDateInArray(allDates.schoolVacationMap.CLASS_111, holidays.classZones111, "DD/MM/YYYY");

    }
    return holidays;
}