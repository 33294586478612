import * as React from "react";
import {ChangeEvent, Component} from "react";
import {observer} from "mobx-react";
import DateSearchItemStore, {dateSearchItemConfiguration} from "./DateSearchItemStore";
import moment = require("moment");

/**
 * props of month head
 */
interface IMonthHeadProps {
    date: Date;
    store: DateSearchItemStore;
    firstMonth?: Date;
    allMonthLabel?: string;
    allMonthSingle?: boolean;
    allMonthNoFlex?: boolean;
}

/**
 * the component for the head of calendar by month
 */
@observer
export default class MonthHead extends Component<IMonthHeadProps> {

    private captionRef: HTMLDivElement;

    /**
     * component did update
     */
    public componentDidUpdate(): void {
        this.dealWithParentClassName();
    }

    /**
     * component did mount
     */
    public componentDidMount(): void {
        this.dealWithParentClassName();
    }

    /**
     * the render
     */
    public render(): JSX.Element {
        const {date, allMonthLabel} = this.props;
        const months = this.props.store.months;
        const momentDate = moment(date);
        const monthLabel = momentDate.format("MM");
        const month = momentDate.format(dateSearchItemConfiguration.monthYearPattern);
        const monthChecked = months.indexOf(month) !== -1;

        return (
            <div ref={(element: HTMLDivElement) => this.captionRef = element} className={"DayPicker-Caption " + month} role="heading">
                <div>{momentDate.format("MMMM YYYY")}</div>
                {
                    allMonthLabel &&
                    <div className="all-dates-month">
                        <input
                            id={"cbxAllMonth_" + monthLabel}
                            className="cbx-all-month"
                            checked={monthChecked}
                            type="checkbox"
                            onChange={(evt: ChangeEvent<HTMLInputElement>) => this.handleChange(evt, month)}
                            {...(this.props.firstMonth ?
                                {disabled: momentDate.startOf("month").isBefore(moment(this.props.firstMonth).startOf("month"))}: {})}
                        />
                        <label className={"label-entire-month" + (monthChecked ? " checked" : "")} htmlFor={"cbxAllMonth_" + monthLabel}>{allMonthLabel}</label>
                    </div>
                }
            </div>
        );
    }

    /**
     * Handle month selection change
     * @param evt
     * @param month
     */
    private handleChange(evt: ChangeEvent<HTMLInputElement>, month: string) {
        if (evt.currentTarget.checked) {
            if (this.props.allMonthSingle) {
                this.props.store.cleanMonths();
            }
            this.props.store.addMonth(month);
            if (this.props.allMonthNoFlex) {
                this.props.store.resetAdjustDate();
            }
        } else {
            this.props.allMonthSingle ?
                this.props.store.cleanMonths()
                : this.props.store.removeMonth(month);
        }
        this.dealWithParentClassName();
    }

    /**
     * FIX because parents to override is not accessible in daypicker react used
     * set 'all-day' class name on day piker parent if need and remove it if not
     */
    private dealWithParentClassName() {
        const months = this.props.store.months;
        const allMonthParent = $(this.captionRef).parents(".DayPicker-Months");
        allMonthParent
            .find(".DayPicker-Month")
            .removeClass("all-day");

        if (months && months.length > 0) {
            months.forEach((value: string) => {
                allMonthParent.find("." + value)
                    .parents(".DayPicker-Month")
                    .addClass("all-day");
            });
        }
    }
}