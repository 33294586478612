import * as React from "react";
import * as DOMPurify from "dompurify";
import {Component} from "react";
import {observer} from "mobx-react";
import {SearchEngineStore} from "../SearchEngineStore";
import {IMoreTabs} from "../text";

/**
 * a tab props
 */
export interface ITabProps {
    title: string;
    identifier: string;
    icon?: string;
}

/**
 * search engine tabs props
 */
export interface ITabsProps {
    searchEngineStore: SearchEngineStore;
    tabs: ITabProps[];
    addClass?: string;
    canUnselect?: boolean;
    afterTabs?: IMoreTabs[];
    beforeTabs?: IMoreTabs[];
}

/**
 * the abstract class to render tabs
 */
@observer
export default class SearchEngineTabs<T extends ITabsProps> extends Component<T> {
    private tabsRef: HTMLDivElement;

    constructor(props: Readonly<T>) {
        super(props);
    }

    /**
     * did mount
     */
    public componentDidMount(): void {
        if (this.tabsRef) {
            const tabs = this.props.tabs;
            const filterTabsSelected = tabs.filter((tab: ITabProps) => tab.identifier === this.props.searchEngineStore.getDefaultSearchEngine());
            const defaultIndex = filterTabsSelected.length > 0 ? tabs.indexOf(filterTabsSelected[0]) : 0;
            this.tabsRef.dataset.defaultIndex = String((this.props.beforeTabs ? this.props.beforeTabs.length : 0) + defaultIndex);
        }
    }

    /**
     * the render
     */
    public render(): JSX.Element {
        const currentSearchEngine = this.props.searchEngineStore.currentSearchEngine;
        const currentCode = currentSearchEngine ? currentSearchEngine.code : "";
        return (
            <div
                ref={(div: HTMLDivElement) => this.tabsRef = div}
                className={"cpt-search-engine-tabs " + (this.props.addClass || "")}
            >
                {this.renderTabs(
                    this.props.tabs.map((tab: ITabProps) => {
                        return (
                            <div
                                key={"tab_" + tab.identifier}
                                className={"elem-search-engine-tab " + (tab.identifier === currentCode ? "selected" : "")}
                                onClick={this.handleTabClicked(tab)}
                            >
                                {tab.icon &&
                                    <i className={"elem-ff-icon " + tab.identifier + "-icon"} data-icon={tab.icon} role="img"/>
                                }
                                {tab.title &&
                                    <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(tab.title)}}/>
                                }
                            </div>
                        );
                    })
                )}
            </div>
        );
    }

    /**
     * handle the click on a tab
     * @param tab
     */
    private handleTabClicked(tab: ITabProps) {
        return () => {
            const searchEngineStore = this.props.searchEngineStore;
            if (this.props.canUnselect
                && searchEngineStore.currentSearchEngine
                && searchEngineStore.currentSearchEngine.code === tab.identifier) {
                searchEngineStore.deselectSearchEngine();
            } else {
                searchEngineStore.selectSearchEngine(tab.identifier);
            }
        };
    }

    /**
     * render the tabs
     * @param tabs element to render
     */
    protected renderTabs(tabs: JSX.Element[]): JSX.Element {
        const afterTabs = this.props.afterTabs;
        const beforeTabs = this.props.beforeTabs;
        return (
            <div className="container d-flex">
                {beforeTabs && this.rendMoreTabs(beforeTabs)}
                {tabs}
                {afterTabs && this.rendMoreTabs(afterTabs)}
            </div>
        );
    }

    /**
     * create more tabs
     * @param moreTabs
     */
    private rendMoreTabs(moreTabs: IMoreTabs[]) {
        return moreTabs.map((tab: IMoreTabs, i: number) => (
            <div key={"more-tab_" + i} className={"elem-search-engine-tab"}>
                <span className={"tab-link-container"}>
                    <a className={"tab-link"} href={tab.link}>
                        {tab.image && <img src={tab.image} alt={""}/>}
                        {tab.icon && <i className={"elem-ff-icon"} data-icon={tab.icon} role="img"/>}
                        {tab.text && <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(tab.text)}}/>}
                    </a>
                </span>
            </div>
        ));
    }
}