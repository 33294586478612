import * as React from "react";
import {Component} from "react";
import {ILinkGroupProps, ILinkOptionProps} from "../LinkSearchItem";

interface IListLinksProps {
    prefix: string;
    linkGroup: ILinkGroupProps;
}

/**
 * list links
 * @class ListLinks
 */
export default class ListLinks extends Component<IListLinksProps> {

    /**
     * Constructor
     * @param props
     */
    constructor(props: any) {
        super(props);
    }

    /**
     * Render
     */
    public render(): JSX.Element {
        const { prefix, linkGroup } = this.props;

        return (
            <div className={prefix + "-type"}>
                {linkGroup.title &&
                    <div className="title">{linkGroup.title}</div>
                }
                <ul className={"reset-list" + ListLinks.getColumnClass(linkGroup.columns)}>
                    {linkGroup.options && linkGroup.options.map((option: ILinkOptionProps, optionIndex: number) => {
                        return (
                            <li key={"item_" + optionIndex} className="item">
                                <a href={option.link} className={"link text-truncate " + option.class}>
                                    {option.icon &&
                                        <i className="elem-ff-icon" data-icon={option.icon} role="img"/>
                                    }
                                    {option.label}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }

    /**
     * Returns the class of the column number
     * @param columnNumber
     */
    private static getColumnClass(columnNumber: number) {
        return columnNumber > 1 ? " multi-column-" + columnNumber : " search-list";
    }

}