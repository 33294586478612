import $$ from "jquery-selector-cache";
import {initMenuItem} from "../../elements/menuItem/menuItem";
import {toggleOverlay} from "../../components/overlay/overlay";
import {menuArrows} from "../../components/header/header";

/**
 * Initialize the menu
 */
export function initMenu() {
    if (!$$(".cpt-menu").length) {
        return;
    }

    initMenuItem();

    // close menu
    $$(".menu-close").on("click", function (){
        $$(".cpt-menu").removeClass("show");
        toggleOverlay();

    });

    // close submenu
    $$(".submenu-close").on("click", function (){
        $$(".menu-container").css("overflow", "auto");
        menuArrows();
    });
}
