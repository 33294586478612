import $$ from "jquery-selector-cache";
import {
    addUserSeenProduct,
    loggedCustomerAction
} from "../../_core/scripts/specific/customer/customer";
import {fetchEndpoint} from "../../_core/scripts/specific/tools/fetch";
import {initNoProduct} from "../../components/result/result";
import {initFavButton} from "../../components/header/header";

/**
 * Initialize favorite product button
 */
export function initManageFavoriteProduct() {

    // Exit when component don't exist
    if (!$$(".btn-toggle-favorite").length && !$$(".cpt-favorite-product").length) {
        return;
    }
    loggedCustomerAction(() => {
        displayUserFavorites();
        addUserSeenProduct(String($(".cpt-favorite-product").data("productId")));
    },
    () => {
        console.log("failed to add product as seen");
    });
    $$("body").on("click", ".btn-toggle-favorite", handleClickOnFavoriteProduct);
}

/**
 * Initialize favorite product button
 */
export function handleClickOnFavoriteProduct(evt: JQuery.ClickEvent) {
    const isDelete =  ($$("body").hasClass("page-account-favorites"));
    const elem = $(evt.currentTarget);
    loggedCustomerAction(() => {
        const id = elem?.data("productId");
        const action = elem.hasClass("selected") ? "remove" : "add";
        updateUserFavorites(id, action, () => updateFavoriteClass(elem));
        if (isDelete) {
            $(elem).closest("article").remove();
        }
    }, () => {
        // scroll to account popin
        window.scrollTo(0, 0);
        $("#OrxapiWidgetAccount").removeClass("d-none");
        (window as any).OrxapiWidgetAccount.openPopin("add-favorite");
        (window as any).OrxapiWidgetAccount.setSuccessRedirectUrl(undefined);

        (window as any).OrxapiWidgetAccount.callback = () => {
            const id = elem?.data("productId");
            const action = elem.hasClass("selected") ? "remove" : "add";
            updateUserFavorites(id, action, () => updateFavoriteClass(elem));
        };

    });
}

/**
 * Update Favorite element Class
 * @param elem
 */
function updateFavoriteClass(elem: JQuery<HTMLElement>) {
    elem.toggleClass("selected");
    //for product favorite
    if (elem.hasClass("cpt-favorite-product")) {
        elem.find(".ico-inactive").toggleClass("d-none");
        elem.find(".ico-active").toggleClass("d-none");
    }
    initFavButton();
}

/**
 * Handler of the delete favorite
 * @param evt
 */
export function handleDeleteFavorite(evt: JQuery.ClickEvent) {
    const itemWrap = $(evt.currentTarget).parents(".cpt-result");
    const overlay = itemWrap.find(".delete-favorite-overlay");

    // Show overlay
    overlay.removeClass("d-none");
    overlay.on("click.cancelDelete", ".btn-action-delete-favorite", {itemWrap}, handleActionDeleteFavorite);
}

/**
 * Handler of delete the favorite by the cancel or validate buttons
 * @param evt
 */
function handleActionDeleteFavorite(evt: JQuery.ClickEvent) {
    const overlay = $(evt.delegateTarget);
    const action = $(evt.currentTarget).data("action");
    const itemWrap: JQuery = evt?.data?.itemWrap;

    // Delete selected favorite when the button is validate
    if (action === "validate" && itemWrap) {
        const id = extractIdFromDataLink(itemWrap.data("link"));
        updateUserFavorites(id, "remove", () => {
            // Unbind event and remove item of the DOM
            overlay.off("click.cancelDelete");
            itemWrap.remove();
            initNoProduct();
        });
    } else {
        // Close overlay and unbind event
        overlay.addClass("d-none");
        overlay.off("click.cancelDelete");
    }
}

/**
 * Return the id of product from data link
 * @param dataLink
 */
export function extractIdFromDataLink(dataLink: string): string {
    const pid = /pid=[0-9]*/.exec(dataLink);
    return (pid !== null) ? pid[0]?.split("=")[1] ?? "" : "";
}


/**
 * Update the user favorites
 * @param id
 * @param action
 * @param successCallback
 */
export function updateUserFavorites(id: string, action: "add" | "remove", successCallback?: () => void) {
    // Exit whe id is empty
    if (id === "") {
        return;
    }

    // Add / remove favorite
    const errorMessage = "Unable to add/remove a favorite product";
    fetchEndpoint("/account/customer/products/favorites", undefined, id, action === "add" ? "POST" : "DELETE",
        (response) => {
            if (response && response.code && response.code === "200") {
                if (successCallback) {
                    successCallback();
                }
            } else {
                console.error(errorMessage);
            }
        },
        () => console.error(errorMessage)
    );
}

/**
 * Display the favorites into result items
 */
export function displayUserFavorites() {
    const errorMessage = "Unable to retrieve customer favorite products";
    fetchEndpoint("/account/customer/products/favorites", undefined, undefined, "GET",
        (response) => {
            if (response && response.code && response.code === "200") {
                if (response.data.length > 0) {
                    const productIds = response.data.map((favorite: any) => favorite.productId);
                    renderDisplayUserFavorites(productIds);
                }
            } else {
                console.error(errorMessage);
            }
        },
        () => console.error(errorMessage)
    );
}


/**
 * Add full hearts in favorites products
 * @param productIds
 */
function renderDisplayUserFavorites(productIds: string[]) {
    $(".cpt-favorite,.cpt-favorite-product").each((i: number, element: HTMLElement) => {
        const cptFavorite = $(element);
        productIds.forEach((code: string) => {
            if (String(cptFavorite.data("productId")) === code) {
                cptFavorite.addClass("selected");

                //for product favorite
                if(cptFavorite.hasClass("cpt-favorite-product")){
                    cptFavorite.find(".ico-inactive").addClass("d-none");
                    cptFavorite.find(".ico-active").removeClass("d-none");
                }
            }
        });
    });
}
