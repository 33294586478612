import $$ from "jquery-selector-cache";
import {getClickDevice} from "../../_core/scripts/specific/tools/events";
import {hideOverlay, toggleOverlay} from  "../../components/overlay/overlay";

/**
 * Initialize the menu account
 */
export function initMenuAccount() {
    if (!$$(".cpt-menu-account").length) {
        return;
    }

    $$("body").on(getClickDevice("toggleMenu"), ".trigger-account-menu", handleToggleMenu);
}

/**
 * Handler of toggle menu
 */
function handleToggleMenu(evt: JQuery.ClickEvent) {
    $$(".cpt-menu-account").toggleClass("d-none");
    $$("body").toggleClass("has-menu-account-open");
    toggleOverlay();

    // Click outside menu and trigger button
    // TODO fix touchstart e.stopPropagation();
    $$("body").on("click.accountMenu", (e) => {
        const target = $(e.target);

        if (!target.hasClass("cpt-menu-account") &&
            !target.parents(".cpt-menu-account").length &&
            e.target !== evt.currentTarget
        ) {
            $$(".cpt-menu-account").addClass("d-none");
            $$("body").removeClass("has-menu-account-open");
            toggleOverlay();
            $$("body").off("click.accountMenu");
        }
    });
}
