import * as React from "react";
import {Component} from "react";
import SearchItemContainer from "../SearchItemContainer";
import {ISearchItem} from "../SearchItem";

/**
 * the search engine content props interface
 */
interface IContentProps {
    searchItems: ISearchItem[];
}

/**
 * the search engine content which contains the search items
 */
export default class SearchItemsContainer extends Component<IContentProps> {
    /**
     * the render
     */
    public render(): JSX.Element {

        return (
            <div className="inner-block">
                {this.props.searchItems.map((item: ISearchItem) =>
                    <SearchItemContainer
                        key={"searchItemContainer_" + item.type + "_" + item.code + "_" + item.data.name}
                        {...item}
                    />)}
            </div>
        );
    }
}