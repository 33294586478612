import * as React from "react";
import SelectSearchItem from "./SelectSearchItem";
import DateSearchItem from "./DateSearchItem";
import CheckSearchItem from "./CheckSearchItem";
import PanelSearchItem from "./PanelSearchItem";
import PassengerSearchItem from "./PassengerSearchItem";
import RoomSearchItem from "./RoomSearchItem";
import AsyncSelectSearchItem from "./AsyncSelectSearchItem";
import LinkSearchItem from "./LinkSearchItem";
import InputDatesRangeSearchItem from "./InputDatesRangeSearchItem";

/**
 * interface described a search item
 */
export interface ISearchItem {
    label?: string;
    code?: string;
    type: SearchItemType;
    data: ISearchItemProps;
    disabled?: boolean;
    addClass?: string;
    onChange?: (label: string) => void;
}

/**
 * interface to describe search item props
 */
export interface ISearchItemProps {
    name: string;
    label?: string;
    manageOwnLabel?: boolean;
}

/**
 * type of search item
 */
export enum SearchItemType {
    SELECT = "select",
    ASYNC_SELECT = "async-select",
    DATE = "date",
    CHECK = "checkbox",
    PANEL = "panel",
    PASSENGER = "passenger",
    ROOM = "room",
    LINK = "link",
    INPUT_DATES_RANGE = "inputDatesRange"
}

/**
 * return the right component by type
 * @param type the type of component to return
 * @param data the data to fill component
 * @param label the label of search item
 * @param disabled
 * @param onChange
 * @param callback
 */
export function getSearchItemComponent(type: SearchItemType, data: any, label: string, disabled: boolean,
                                       onChange?: (label: string) => void,
                                       callback?: (label: string) => void): React.ReactNode {
    let item;
    let itemWrapperClass;

    if (type === SearchItemType.SELECT) {
        itemWrapperClass = "item-content-se";
        item = <SelectSearchItem label={label} {...data} disabled={disabled} onChange={onChange} callBack={callback}/>;
    } else if (type === SearchItemType.ASYNC_SELECT) {
        itemWrapperClass = "item-content-se";
        item = <AsyncSelectSearchItem label={label} {...data} disabled={disabled} onChange={onChange} callBack={callback}/>;
    } else if (type === SearchItemType.DATE) {
        itemWrapperClass = "item-content-se";
        item = <DateSearchItem label={label} {...data} disabled={disabled}/>;
    } else if (type === SearchItemType.CHECK) {
        if (data.options && data.options.length > 0) {
            itemWrapperClass = "item-content-se";
            item = <CheckSearchItem label={label} {...data} disabled={disabled} onChange={onChange}/>;
        } else {
            return null;
        }
    } else if (type === SearchItemType.PANEL) {
        itemWrapperClass = "item-content-panel";
        item = <PanelSearchItem label={label} {...data}/>;
    } else if (type === SearchItemType.PASSENGER) {
        itemWrapperClass = "item-content-se";
        item = <PassengerSearchItem label={label} {...data}/>;
    } else if (type === SearchItemType.ROOM) {
        itemWrapperClass = "item-content-se";
        item = <RoomSearchItem label={label} {...data}/>;
    } else if (type === SearchItemType.LINK) {
        itemWrapperClass = "item-content-se";
        item = <LinkSearchItem label={label} {...data} disabled={disabled} onChange={onChange} callBack={callback}/>;
    } else if (type === SearchItemType.INPUT_DATES_RANGE) {
        itemWrapperClass = "item-content-se";
        item = <InputDatesRangeSearchItem label={label} {...data} disabled={disabled} onChange={onChange} callBack={callback}/>;
    }
    return data.manageOwnLabel ? item : <div className={itemWrapperClass}>{item}</div>;
}

/**
 * Return a search item by its code from a search item list
 * @param searchItems search items to filter
 * @param code code of the select item to return
 */
export function getSearchItemByCode(searchItems: ISearchItem[], code: string) {
    return searchItems.find((item: ISearchItem) => item.code === code);
}

/**
 * Return search items filtered by excluded codes
 * @param searchItems search items to filter
 * @param excludedCodes codes to exclude from returned search items
 */
export function getSearchItemsExcludedByCode(searchItems: ISearchItem[], excludedCodes: string[]) {
    return searchItems.filter((item: ISearchItem) => excludedCodes.indexOf(item.code) <= -1);
}
