import $$ from "jquery-selector-cache";
import {getI18nMessage} from "../../_core/scripts/libs/orxapi/i18n";

type MessageTuple = {
    text: string;
    type: string;
}

interface NewsletterMessages {
    emptyField: MessageTuple;
    sendOk: MessageTuple;
    sendKo: MessageTuple;
    invalidFormat: MessageTuple;
}

/**
 * Initialize the newsletter
 */
function initNewsletter() {
    if (!$$(".cpt-newsletter").length) {
        return;
    }

    $$(".form-newsletter").on("click.newsletter", ".button-newsletter", handleSubscribeNewsletter);
}

/**
 * Handles the submission of the newsletter subscription form
 * @param evt The event object for the form submission
 */
function handleSubscribeNewsletter(evt: JQuery.SubmitEvent<HTMLFormElement>) {
    evt.preventDefault();

    const form = $$(evt.delegateTarget);
    const submitUrl = form.data("actionUrl");
    const postLoginUrl = form.data("postLoginUrl");
    const postSignUpUrl = form.data("postSignUpUrl");
    const emailValue = form.find("input[name=email]").val();

    // Clear the newsletter message
    $$(".newsletter-message-wrap").empty();

    // When the input field is empty
    if (!emailValue.length || !emailValue.replace(/\s+/, "").length) {
        displayNewsletterMessage("emptyField");
        return;
    }

    // Email format
    const emailRegex = getI18nMessage("signup.form.email.regexp");
    if (!new RegExp(emailRegex).test(emailValue)) {
        displayNewsletterMessage("invalidFormat");
        return;
    }

    displayLoaderToForm();

    fetch(
        submitUrl + "?email=" + encodeURIComponent(emailValue)
    ).then((response) => {
        return response.json();
    }).then((jsonData) => {
        if (jsonData && jsonData.status) {
            switch (jsonData.status) {
            case "CUSTOMER_CREATED_AND_SUBSCRIBED":
            case "CUSTOMER_SUBSCRIBED":
                displayNewsletterMessage("sendOk");
                displayLoaderToForm(false);
                redirectAfterNewsletterSubscribe(postSignUpUrl, emailValue);
                break;
            case "CUSTOMER_ALREADY_SUBSCRIBED":
                displayNewsletterMessage("sendOk");
                displayLoaderToForm(false);
                redirectAfterNewsletterSubscribe(postLoginUrl, emailValue);
                break;
            }
        } else {
            displayNewsletterMessage("sendKo");
            displayLoaderToForm(false);
        }
    }).catch(() => {
        displayNewsletterMessage("sendKo");
        displayLoaderToForm(false);
    });
}

/**
 * Redirects the user after they subscribe to a newsletter
 * @param redirectUrl The URL to redirect the user after subscribing to the newsletter
 * @param emailParameterValue The value of the email parameter to be added to the redirect URL
 */
function redirectAfterNewsletterSubscribe(redirectUrl: string, emailParameterValue: string) {
    if (redirectUrl) {
        if (redirectUrl.indexOf("#") >= 0) {
            // Url with hash: user has an account => redirect to login/personal page
            document.location.href = redirectUrl;
        } else if (emailParameterValue) {
            // URL without hash: user does not have an account => redirect to sign up page
            document.location.href = redirectUrl + "?email=" + encodeURIComponent(emailParameterValue);
        }
    }
}

/**
 * Display a loader to newsletter form
 * @param show The loader is added when it's true and removed when is at false
 */
function displayLoaderToForm(show = true) {
    $$(".button-newsletter").prop("disabled", show);
    $$(".button-newsletter").find(".label").toggleClass("d-none");
    $$(".button-newsletter").find(".cpt-loader").toggleClass("d-none");
}

/**
 * Display the newsletter message
 * @param key
 */
function displayNewsletterMessage(key: keyof NewsletterMessages) {
    const message = getNewsletterMessages()[key];
    const messageClass = `newsletter-message newsletter-message-${message.type}`;
    const addIcon = message.type === "success" ? `<i class="elem-ff-icon" data-icon="c"></i>` : "";
    const template = `<div class="${messageClass}">${addIcon} <span>${message.text}</span></div>`;
    $$(".newsletter-message-wrap").append(template);
}

/**
 * Return the list of messages
 */
function getNewsletterMessages(): NewsletterMessages {
    return {
        emptyField: {
            type: "error",
            text: getI18nMessage("newsletter.subscribe.error.email.empty")
        },
        sendOk: {
            type: "success",
            text: getI18nMessage("newsletter.subscribe.success.registered")
        },
        sendKo: {
            type: "error",
            text: getI18nMessage("newsletter.subscribe.error.generic")
        },
        invalidFormat: {
            type: "error",
            text: getI18nMessage("newsletter.subscribe.error.email.format")
        }
    };
}

export {
    initNewsletter,
};
