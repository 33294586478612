import $$ from "jquery-selector-cache";
import {toggleOverlay} from "../../components/overlay/overlay";

/**
 * Initialize the menu
 */
export function initMenuItem() {
    if (!$$(".elem-menu-item").length) {
        return;
    }
    // Open submenu
    $$(".has-submenu").on("click", function (){
        if($$(this).children(".submenu-content").length && !$$(this).children(".submenu-content").hasClass("show")) {
            $$(".menu-content").animate({bottom: 0});
            $$(".menu-arrow-top").removeClass("show");
            $$(".menu-arrow-bottom").removeClass("show");
            $$(".menu-container").css("overflow", "unset");
            $$(this).children(".submenu-content").addClass("show");
        } else if($$(this).children(".submenu-content").hasClass("show")) {
            $$(".submenu-content").removeClass("show");
        }
    });

    $$(".menu-content a").on("click", function () {
        $$(".cpt-menu").removeClass("show");
        toggleOverlay();
    });
}
