let i18n;

// Initialize the local object with i18n
if ((window as any).orxapi && (window as any).orxapi.locale) {
    initializeI18n((window as any).orxapi.locale.i18n);
}

/**
 * Initialize the I18N with messages JSON
 * @param jsonMessages The JSON messages
 */
function initializeI18n(jsonMessages: JSON) {
    i18n = jsonMessages;
}

/**
 * Return the message form key
 * @param key
 * @return The message
 */
export function getI18nMessage(key: string): string {
    if (!i18n) {
        return key;
    }
    return i18n[key];
}