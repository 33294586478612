import * as React from "react";
import {Component} from "react";
import AsyncSelect from "react-select/async";
import {inject, observer} from "mobx-react";
import {SearchEngineStore} from "../../SearchEngineStore";
import * as DOMPurify from "dompurify";
import {ISearchEngineText} from "../../text";
import {ISearchItemProps} from "../index";

interface IReplaceLabel {
    finalLabel: string;

    [name: string]: string;
}

interface IResponse {
    label: string;
    code?: string;
    value?: string;
    type?: string;
    codificationType?: string;
}

/**
 * props interface of select search item
 */
export interface IAsyncSelectSearchItemProps extends ISearchItemProps {
    url: string;
    loadingMessage?: string;
    placeholder?: string;
    noResultText?: string;
    autocompleteUrl?: string;
    disabled: boolean;
    searchEngineStore: SearchEngineStore;
    clearable?: boolean;
    hiddenValue?: string;
    checkboxLabel?: string;
    iconHtml?: string;
    keepOpen?: boolean;
    searchEngineText: ISearchEngineText;
    onChange?: (label: string) => void;
    callBack?: (label: string) => void;
    focusOnUpdate: boolean;
}

/**
 * search input with a select
 */
@inject("searchEngineStore")
@inject("searchEngineText")
@observer
export default class AsyncSelectSearchItem extends Component<IAsyncSelectSearchItemProps> {
    private readonly label: IReplaceLabel;
    private ref: AsyncSelect<IResponse>;
    constructor(props: Readonly<IAsyncSelectSearchItemProps>) {
        super(props);
        this.label = props.searchEngineText ? props.searchEngineText.items ? (props.searchEngineText.items[props.name] || {}) : {} : {};
        this.formatLabel = this.formatLabel.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.getOptionValue = this.getOptionValue.bind(this);
        const name = props.name;
        if (!props.searchEngineStore.values[name] || props.searchEngineStore.values[name].length <= 0) {
            props.searchEngineStore.setValue(name, undefined);
        }
    }

    /**
     * component did mount
     */
    public componentDidMount(): void {
        if (this.props.callBack) {
            const responseValue = this.getResponseValue();
            if (responseValue) {
                this.props.callBack(this.formatLabel(responseValue));
            }
        }
    }

    /**
     * component did update
     */
    public componentDidUpdate(): void {
        if (this.props.focusOnUpdate) {
            this.ref.focus();
        }
    }

    /**
     * Renders component
     */
    public render(): JSX.Element {
        return this.props.manageOwnLabel ? this.renderItemWithLabel() : this.renderItem();
    }

    /**
     *  Renders item with its label
     */
    private renderItemWithLabel(): JSX.Element {
        return (
            <>
                <div className="item-label-se">{this.props.label}</div>
                <div className="item-content-se">{this.renderItem()}</div>
            </>
        );
    }

    /**
     * Renders item
     */
    private renderItem(): JSX.Element {
        const {url, clearable, loadingMessage} = this.props;
        const responseValue = this.getResponseValue();

        return (
            <>
                <AsyncSelect
                    ref={(select: AsyncSelect<IResponse>) => this.ref = select}
                    cacheOptions={true}
                    // @ts-ignore
                    loadOptions={(inputValue: string) => url ? $.ajax(url.replace("{term}", inputValue)) : console.error("url to search value is not set")}
                    loadingMessage={() => loadingMessage}
                    isClearable={clearable}
                    className="async-select"
                    classNamePrefix="custom-async-select"
                    menuIsOpen={this.props.keepOpen ? true : undefined}
                    placeholder={this.props.placeholder || ""}
                    noOptionsMessage={() => this.props.noResultText}
                    isDisabled={this.props.disabled}
                    formatOptionLabel={this.formatLabel}
                    getOptionValue={this.getOptionValue}
                    value={responseValue}
                    onChange={this.updateValue}
                />
                {this.props.iconHtml &&
                    <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.iconHtml)}}/>
                }
                {responseValue &&
                    <>
                        {Object.keys(responseValue).map((inputValue: string) =>
                            <input
                                key={"async-select_" + inputValue}
                                type={"hidden"}
                                name={this.props.name + "." + inputValue}
                                value={responseValue[inputValue]}
                            />
                        )}
                    </>
                }
            </>
        );
    }

    /**
     * get the response value in store
     */
    private getResponseValue(): IResponse {
        const name = this.props.name;
        let responseValue;

        const valueElement = this.props.searchEngineStore.values[name][0];
        if (valueElement) {
            const values = valueElement.split("##");
            responseValue = {};
            values.forEach(((inputValue: string) => {
                const parameter = inputValue.split("=");
                responseValue[parameter[0]] = parameter[1];
            }));
        }
        return responseValue;
    }

    /**
     * format the label to show
     * @param response
     */
    private formatLabel(response: IResponse): string {
        if (response && response.label) {
            const replaceLabel = this.label;
            if (replaceLabel && replaceLabel.finalLabel) {
                const finalLabel = replaceLabel.finalLabel;
                return Object.keys(replaceLabel)
                    .filter((key: string) => key !== "finalLabel")
                    .reduce((last: string, key: string) => {
                        return last.replace(replaceLabel[key], response[key]);
                    }, finalLabel);
            }
            return response.label;
        }
    }

    /**
     * get the option value
     * @param response
     */
    private getOptionValue(response: IResponse): any {
        return {value: (response.code || response.value || response.label) + "_" + (response.type || response.codificationType || "")};
    }

    /**
     * update value on search engine store
     * @param response
     */
    private updateValue(response: IResponse) {
        const allValue = Object.keys(response)
            .map((name: string) => name + "=" + response[name])
            .join("##");
        this.props.searchEngineStore.setValue(this.props.name, allValue);
        if (this.props.onChange) {
            this.props.onChange(this.formatLabel(response));
        }
    }

}