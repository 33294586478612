import $$ from "jquery-selector-cache";

/**
 * Initialize last sales
 */
export function initLastSales() {
    if (!$$(".cpt-last-sales").length) {
        return;
    }

    $$(".cpt-last-sales").each((i: number, elem: HTMLElement) => {
        const display = $$(elem).data("display");
        // When is slider mode
        if (display === "slider") {
            $$(elem).find(".product-list").slick({
                row: 0,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
            });
            $$(elem).addClass("slider-mode");
        }
    });
}