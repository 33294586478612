import "mdn-polyfills/Array.prototype.includes";
import {IComponentManager} from "./IComponentManager";

/**
 * Configuration
 * @class
 */
export default class ComponentManager implements IComponentManager {

    private readonly selector: string;
    private readonly dataName: string;
    private config: string[];

    /**
     * @constructor
     * @param selector The selector name that you want initialized
     * @param dataName The data name in which load configuration
     */
    constructor(selector: string, dataName: string) {
        this.selector = selector;
        this.dataName = dataName;

        this.getDataConfigs(this.selector, this.dataName);
    }

    /**
     * Get the slider configuration for initialization
     * @param {string} className The class name for get data config
     * @param {string} dataName The data name of data config
     * @return {string[]} Return the unique list of config value
     */
    private getDataConfigs(className: string, dataName: string): string[] {
        this.config = [];
        const list: NodeList = document.querySelectorAll(className);
        Array.prototype.forEach.call(list, (elem: HTMLElement) => {
            this.config.push(elem.dataset[dataName]);
        });
        return this.config = this.config.reduce((x: string | any, y: string) => x.includes(y) ? x : [...x, y], []);
    }

    /**
     * Format the camel case to kebab case
     * @param {string} value The camel case format value
     * @return {string} The kebab case format
     */
    private static camelCaseToKebabCase(value: string): string {
        return value.replace(/\.?([A-Z])/g, (x: any, y: any) => "-" + y.toLowerCase());
    }

    /**
     * Trigger the instance for all configurations set
     * @param callback
     */
    public trigger(callback: (config: string, dataNameSelector: string) => void) {
        const dataNameSelector = "data-" + ComponentManager.camelCaseToKebabCase(this.dataName);
        this.config.forEach((config: string) => {
            callback(config, dataNameSelector);
        });
    }

    /**
     * Get the configuration setting
     * @return {string[]}
     */
    public getConfig(): string[] {
        return this.config;
    }

}