import * as React from "react";
import InputMask from "react-input-mask";

export interface IMaskedState {
    value?: string;
    selection?: { start: number; end: number };
}

export interface IMaskedStates {
    previousState?: IMaskedState;
    currentState?: IMaskedState;
    nextState?: IMaskedState;
}

export interface InputProps extends React.InputHTMLAttributes<any> {
    id?: string;
    label?: string;
    mask?: string | Array<string | RegExp>;
    maskChar?: string;
    alwaysShowMask?: boolean;
    beforeMaskedStateChange?: (maskedState: IMaskedStates) => void;
    children?: React.ReactElement;
}

export const Input = ({id, label, ...restProps}: InputProps) => {
    return (
        <>
            {label && <label htmlFor={id}>{label}</label>}
            <InputMask id={id} {...restProps} />
        </>
    );
};