import "../../_core/scripts/specific/layout/index";
import {initHeader} from "../../components/header/header";
import {autoHideSliderPreloader, initDiaporama} from "../../components/diaporama/diaporama";
import {initializeSearchEngine} from "../../components/searchEngine/searchEngine";
import {initFooter} from "../../components/footer/footer";
import {initAgencyChoice } from "../../components/agencySelect/agencySelect";
import {initTracking} from "../../components/tracking/tracking";
import {initPreloader} from "../../components/preloader/preloader";
import {initLoadFavList} from "../../components/mob_liste_fav/mob_liste_fav";
import {initLastSales} from "../../components/mob_push_last_sales/mob_push_last_sales";
import {initManageFavorite} from "../../components/result/result";
import {initNewsletter} from "../../components/newsletter/newsletter";

if (console !== undefined) {
    console.log("Initialize landing page ;-)");
}

initPreloader();
autoHideSliderPreloader(".cpt-slider-container");
initDiaporama();
initAgencyChoice();
initHeader(false);
initializeSearchEngine(undefined, () => console.log("Search engine initialized :)"));
initLoadFavList();
initLastSales();
initFooter();
initNewsletter();
initNewsletter();
initManageFavorite();
initTracking();