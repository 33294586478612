import $$ from "jquery-selector-cache";
import "slick-carousel";
import {diaporamaConfig} from "./diaporamaConfig";
import ComponentManager from "../../_core/scripts/libs/orxapi/ComponentManager";
import {hidePreloaderFromContainer} from "../preloader/preloader";

/**
 * Initialize the component
 */
export function initDiaporama() {
    // Exit when component don't exist
    if (!$$(".cpt-diaporama").length) {
        return;
    }

    const componentManager = new ComponentManager(".cpt-diaporama", "diaporamaConfig");
    displayCurrentTotalSlide();
    componentManager.trigger(initSlick);
}

/**
 * Initialize the slick plug-in
 * @param config
 * @param dataNameSelector
 */
export function initSlick(config: string, dataNameSelector: string) {
    const slider = $$(`[${dataNameSelector}=${config}]`).slick(diaporamaConfig[config]);
    // @ts-ignore
    slider.on("afterChange");
}

/**
 * Auto hide the preloader of a slider inside a container
 * @param containerSelector the container of the preloader and the slider
 */
export function autoHideSliderPreloader(containerSelector: string) {
    const slickElement = $$(containerSelector + " .cpt-diaporama");
    slickElement.on("init reInit afterChange", () => hidePreloaderFromContainer(containerSelector));
}

/**
 * Display current/total slide
 */
function displayCurrentTotalSlide() {
    const slickElement = $$(".cpt-diaporama");
    if (!slickElement.hasClass("no-slide-number")) {
        if (!$$(".slide-info").length) {
            $$("<div class=\"slide-info\"></div>").insertAfter(slickElement);
        }
        slickElement.on("init reInit afterChange", getSlideNumber);
    }
}

/**
 * Get current/total slide
 * @param evt
 * @param slick
 * @param currentSlide
 * @param nextSlide
 */
function getSlideNumber(evt, slick, currentSlide, nextSlide) {
    const i = (currentSlide ? currentSlide : 0) + 1;
    $(".slide-info").text(i + " / " + slick.slideCount);
}
