import * as React from "react";
import {Component} from "react";
import MoreLessNumberRow from "../Pax/MoreLessNumberRow";
import {inject, observer} from "mobx-react";
import {SearchEngineStore} from "../../SearchEngineStore";
import {ISearchEngineText} from "../../text";
import {ISearchItemProps} from "../index";

interface IPassengerItemText {
    inputText: string;
    inputTextSeparator: string;
    buttonText?: string;
    passengerTopTitle: string;
}

interface IPassengerText {
    label: string;
    inputText: string;
}

interface IPassengerItem {
    name: string;
    initialValue: number;
    maxValue?: number;
    minValue?: number;
}

interface IPassengerSearchItemProps extends ISearchItemProps {
    searchEngineStore: SearchEngineStore;
    paxItems: IPassengerItem[];
    searchEngineText: ISearchEngineText;
}

interface IPassengerSearchItemState {
    open: boolean;
}
interface IPassengerConstraintItem {
    name: string;
    target?: string;
    value?: number;
    message?: string;
}
export interface IPassengerConstraint {
    requiredParameters: string[];
    constraints: IPassengerConstraintItem[];
}

/**
 * the passenger search item
 */
@inject("searchEngineStore")
@inject("searchEngineText")
@observer
export default class PassengerSearchItem extends Component<IPassengerSearchItemProps, IPassengerSearchItemState> {
    private readonly itemText: IPassengerItemText;
    private readonly passengerText: { [name: string]: IPassengerText };
    private ref: HTMLDivElement;

    /**
     * component did mount
     */
    public componentDidMount() {
        this.ref.addEventListener("passenger-search-item:close", this.handleCloseItem);
    }

    /**
     * component will unmount
     */
    public componentWillUnmount() {
        this.ref.removeEventListener("passenger-search-item:close", this.handleCloseItem);
    }

    /**
     * constructor
     * @param props
     */
    constructor(props: Readonly<IPassengerSearchItemProps>) {
        super(props);
        this.handleCloseItem = this.handleCloseItem.bind(this);
        this.reset = this.reset.bind(this);
        this.state = {open: false};
        this.itemText = props.searchEngineText ? props.searchEngineText.items ? (props.searchEngineText.items[props.name] || {}) : {} : {};
        this.passengerText = {};
        const searchEngineStore = props.searchEngineStore;
        this.props.paxItems.forEach((item: IPassengerItem) => {
            const name = item.name;
            const storedValues = searchEngineStore.values[name];
            if (!storedValues || storedValues.length <= 0) {
                searchEngineStore.setValue(name, String(item.initialValue || item.minValue || 0));
            }
            this.passengerText[name] = props.searchEngineText ? props.searchEngineText.items ? (props.searchEngineText.items[name] || {}) : {} : {};
        });
    }

    /**
     * Renders component
     */
    public render(): JSX.Element {
        return this.props.manageOwnLabel ? this.renderItemWithLabel() : this.renderItem();
    }

    /**
     *  Renders item with its label
     */
    private renderItemWithLabel(): JSX.Element {
        return (
            <>
                <div className="item-label-se">{this.props.label}</div>
                <div className="item-content-se">{this.renderItem()}</div>
            </>
        );
    }

    /**
     * Renders item
     */
    private renderItem(): JSX.Element {
        const searchEngineStore = this.props.searchEngineStore;
        const inputValue = this.props.paxItems
            .filter((item: IPassengerItem) => {
                return searchEngineStore.values[item.name] && Number(searchEngineStore.values[item.name][0]) > 0;
            })
            .map((item: IPassengerItem, index: number) => {
                return (this.itemText.inputText || "{nb} {txt}")
                    .replace("{nb}", searchEngineStore.values[item.name][0])
                    .replace("{txt}", (this.passengerText[item.name].inputText || "passengers_" + index));
            })
            .join(this.itemText.inputTextSeparator || ", ");
        return (
            <>
                <div
                    className={"elem-custom-input "  + (this.state.open ? "open" : "close")}
                    onClick={() => this.setState({open: !this.state.open})}
                >
                    {inputValue || ""}
                </div>
                <div ref={(div: HTMLDivElement) => this.ref = div} className={"passengers-panel " + (this.state.open ? "open" : "close")}>
                    <div className={"header-content"}>
                        {this.itemText.passengerTopTitle && <div className={"passenger-top-title"}>{this.itemText.passengerTopTitle}</div>}
                        <div className={"clean"} onClick={this.reset}>
                            {this.props.searchEngineText && this.props.searchEngineText.common && this.props.searchEngineText.common.cleanTitle &&
                            <div className={"clean-title"}>{this.props.searchEngineText.common.cleanTitle}</div>
                            }
                            <div className={"clean-icon"}/>
                        </div>
                    </div>
                    {this.props.paxItems.map((paxItem: IPassengerItem) =>
                        <MoreLessNumberRow
                            open={this.state.open}
                            key={"paxItem_" + paxItem.name}
                            searchEngineStore={this.props.searchEngineStore}
                            {...paxItem}
                            {...this.passengerText[paxItem.name]}
                            classPrefix={"passenger-number"}
                        />
                    )}
                    {this.state.open && this.itemText.buttonText &&
                        <div className={"button-container"}>
                            <button onClick={() => this.setState({open: false})}>{this.itemText.buttonText}</button>
                        </div>
                    }
                </div>
            </>
        );
    }

    /**
     * handle event close on passenger item
     */
    private handleCloseItem() {
        this.setState({open: false});
    }

    /**
     * clean all data to initial value
     */
    private reset() {
        this.props.paxItems.forEach((item: IPassengerItem) => {
            const name = item.name;
            const searchEngineStore = this.props.searchEngineStore;
            searchEngineStore.setValue(name, String(item.initialValue || item.minValue || 0));
        });
    }
}