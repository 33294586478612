import * as ReactDOM from "react-dom";
import * as React from "react";
import {Provider} from "mobx-react";
import SearchEngineLeclercMobileLayout from "./SearchEngineLayout/SearchEngineLeclercMobileLayout";
import {SearchEngineStore} from "./SearchEngineStore";
import {getQueryString, getUrlVar} from "orxapi.tools.url";
import {ISearchEngine, SEARCH_ENGINE_CODE_PARAMETER} from "./SearchEngineLayout";
import {urlParameters} from "../tools/formTools";
import {ISearchEngineText} from "./text";
import {IUrlParam} from "orxapi.tools.url/dist/IUrl";
import {HolidaysDates} from "../../../../specific/holidays";
import {readCookie} from "../../../../specific/cookie";
import moment = require("moment");

export function initSearchEngineWithHolidays(root: HTMLElement, dataUrl: string, searchEngineText: ISearchEngineText, holidays: HolidaysDates, callback?: () => void) {
    $.ajax({
        url: dataUrl,
        dataType: "json"
    }).done((response: ISearchEngine[]) => {
        const $root = $(root);
        const searchEngineStore = new SearchEngineStore(response);
        if ($root.data("canUnselect")) {
            searchEngineStore.deselectSearchEngine();
        }
        if (holidays) {
            searchEngineStore.setHolidays(holidays);
        }
        for (const searchEngine of response) {
            const defaultValues = searchEngine.defaultValues;
            if (defaultValues && defaultValues.length > 0) {
                for (const defaultValue of defaultValues) {
                    if (defaultValue.code && defaultValue.code !== "" && defaultValue.value) {
                        searchEngineStore.selectValue(defaultValue.code, defaultValue.value.split(urlParameters.or));
                    }
                }
            }
        }

        let searchEngineCode;
        if (dataUrl && dataUrl.indexOf(SEARCH_ENGINE_CODE_PARAMETER) !== -1) {
            searchEngineCode = getQueryString(SEARCH_ENGINE_CODE_PARAMETER, dataUrl);
        }
        const parameters = getUrlVar();
        if (!searchEngineCode || searchEngineCode === "") {
            searchEngineCode = parameters[SEARCH_ENGINE_CODE_PARAMETER];
        }

        if (searchEngineCode && searchEngineCode !== "") {
            if (searchEngineCode.indexOf(",") === -1) {
                searchEngineStore.selectSearchEngine(searchEngineCode);
                searchEngineStore.setDefaultSearchEngine(searchEngineCode);
            } else {
                const codes = searchEngineCode.split(",");
                if (searchEngineStore.searchEngineExist(codes[0])) {
                    searchEngineStore.selectSearchEngine(codes[0]);
                    searchEngineStore.setDefaultSearchEngine(codes[0]);
                    if (codes.length > 1) {
                        const subSearchEngines = searchEngineStore.currentSearchEngine.subSearchEngines;
                        if (subSearchEngines && subSearchEngines.length > 0) {
                            const index = subSearchEngines.map((searchEngine: ISearchEngine) => searchEngine.code)
                                .indexOf(codes[1]);
                            if (index !== -1) {
                                searchEngineStore.getCurrentSearchEngineStatusStore()
                                    .selectSubSearchEngineIndex(index);
                            }
                        }
                    }
                } else if (codes.length > 1) {
                    searchEngineStore.selectSearchEngine(codes[1]);
                    searchEngineStore.setDefaultSearchEngine(codes[1]);
                }

            }
        }

        initStoreFromParameters(searchEngineStore, parameters);
        initCookies(searchEngineStore);

        ReactDOM.unmountComponentAtNode(root); // In case of the search engine was already initialized
        ReactDOM.render(
            (
                <Provider searchEngineStore={searchEngineStore} searchEngineText={searchEngineText}>
                    {getLayout($root)}
                </Provider>
            ),
            root,
            callback
        );
    });
}

/**
 * init react search engine inside root element
 * @param root
 * @param dataUrl url to retrieve the json
 * @param searchEngineText
 * @param callback
 */
export function initSearchEngineWithText(root: HTMLElement, dataUrl: string, searchEngineText: ISearchEngineText, callback?: () => void) {
    initSearchEngineWithHolidays(root, dataUrl, searchEngineText, null, callback);
}

/**
 * init react search engine inside root element
 * @param root
 * @param dataUrl url to retrieve the json
 * @param callback
 */
export function initSearchEngine(root: HTMLElement, dataUrl: string, callback?: () => void) {
    initSearchEngineWithText(root, dataUrl, null, callback);
}

/**
 * Initializes the search engine store from given url parameters
 * @param searchEngineStore search engine store
 * @param parameters url parameters
 */
function initStoreFromParameters(searchEngineStore: SearchEngineStore, parameters: IUrlParam) {
    let searchTypes;
    if (searchEngineStore && searchEngineStore.currentSearchEngine && searchEngineStore.currentSearchEngine.searchTypes) {
        searchTypes = searchEngineStore.currentSearchEngine.searchTypes;
    }
    for (const parametersKey of Object.keys(parameters)) {
        let searchType = urlParameters.or;
        if (searchTypes && searchTypes.length > 0) {
            for (const currentSearchType of searchTypes) {
                if (currentSearchType.code && currentSearchType.code === parametersKey && currentSearchType.value && currentSearchType.value === "and") {
                    searchType = urlParameters.and;
                }
            }
        }
        searchEngineStore.selectValue(parametersKey, parameters[parametersKey].split(searchType));
    }
}

/**
 * Fills the search engine input with cookie values
 * @param searchEngineStore
 */
export function initCookies(searchEngineStore: SearchEngineStore) {
    const departureDateCookie = readCookie("departDate");
    const cityCookie = readCookie("city");
    for (let i = 0; i < searchEngineStore.currentSearchEngine.searchItems.length; i++) {
        if (searchEngineStore.currentSearchEngine.searchItems[i].data.name === "m_dpci") {
            searchEngineStore.setValue(searchEngineStore.currentSearchEngine.searchItems[i].code, cityCookie);
        }
    }
    if (departureDateCookie !== "" && departureDateCookie !== "/") {
        searchEngineStore.getDateStoreOrCreate(searchEngineStore.currentSearchEngine.code + "_m_dd_m_dmy").selectDate(moment(departureDateCookie, "DD/MM/YYYY").toDate());
    }

}

/**
 * get the layout
 * @param root the root where the layout will be place
 */
function getLayout(root: JQuery): JSX.Element {
    return (
        <SearchEngineLeclercMobileLayout
            {...root.data()}
        />
    );
}
