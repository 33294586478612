import * as React from "react";
import {Fragment} from "react";
import {inject, observer} from "mobx-react";
import Select from "react-select";
import SearchEngineLayout, {ICodeLabelFilter, ISearchEngine, ISearchEngineLayoutProps, ISearchEngineLayoutState} from "..";
import SubmitButton from "../../../components/button/SubmitButton";
import {addHideQueryInputs} from "../../../tools/formTools";
import SearchItemsContainer from "../../SearchItemsContainer";
import {ISearchItem} from "../../SearchItem";
import {ISearchEngineText} from "../../text";
import SearchEngineTabs from "../../SearchEngineTabs";

interface ISearchEngineLeclercMobileLayoutProps extends ISearchEngineLayoutProps {
    canUnselect?: boolean;
    searchEngineText?: ISearchEngineText;
    subSearchEngineSelectionTitle?: string;
}

/**
 * the main search engine layout for mey club
 */
@inject("searchEngineStore")
@inject("searchEngineText")
@observer
export default class SearchEngineLeclercMobileLayout extends SearchEngineLayout<ISearchEngineLeclercMobileLayoutProps, ISearchEngineLayoutState> {
    private filterItemRef: HTMLDivElement;

    constructor(props: ISearchEngineLeclercMobileLayoutProps) {
        super(props);
    }

    /**
     * the render
     */
    public render(): JSX.Element {
        const searchEngineStore = this.props.searchEngineStore;
        const currentSearchEngine = searchEngineStore.currentSearchEngine;
        return (
            <div className="main-container">
                <SearchEngineTabs
                    beforeTabs={this.props.searchEngineText.beforeTabs}
                    afterTabs={this.props.searchEngineText.afterTabs}
                    canUnselect={this.props.canUnselect}
                    tabs={this.getTabs()}
                    searchEngineStore={searchEngineStore}/>
                {currentSearchEngine &&
                this.renderForm(currentSearchEngine)
                }
                { currentSearchEngine.type === "package" &&
                    <div className="title-express-search">
                        {this.props.quickSearchTitle}
                    </div>
                }
                {   currentSearchEngine.type === "package" &&
                    this.renderQuickSearchForm()
                }
            </div>
        );
    }

    /**
     * render the form
     * @param currentSearchEngine
     */
    private renderForm(currentSearchEngine: ISearchEngine) {
        const useDefaultSubmit = this.props.defaultSubmit || true;
        const searchEngineStore = this.props.searchEngineStore;

        const searchEngineStatusStore = searchEngineStore.getCurrentSearchEngineStatusStore();
        const selectedSubSearchEngine = searchEngineStatusStore ? currentSearchEngine.subSearchEngines[searchEngineStatusStore.subSearchEngineIndex] : undefined;
        const tabItemsContent = currentSearchEngine.subSearchEngines
            ? selectedSubSearchEngine.searchItems
            : null;
        const itemCodeToHide = this.getItemCodesToHide(selectedSubSearchEngine);
        const submitUrl = this.getSubmitUrl(selectedSubSearchEngine, currentSearchEngine);
        const searchEngineText = this.props.searchEngineText;
        const currentSearchEngineText = searchEngineText.searchEngines ? (searchEngineText.searchEngines[currentSearchEngine.code] || null) : null;
        return (
            <form
                action={submitUrl}
                className="cpt-search-engine-contents"
                {...(useDefaultSubmit ? {onSubmit: this.tryToSubmitForm(selectedSubSearchEngine || currentSearchEngine)} : {})}
            >
                {this.addBaseQueryInputs()}
                {selectedSubSearchEngine && addHideQueryInputs(selectedSubSearchEngine.baseQuery)}
                <div className={"container " + currentSearchEngine.code || ""}>
                    <div className="outer-block">
                        <div className="row no-gutters">
                            {currentSearchEngineText && currentSearchEngineText.title &&
                                <div className={"booking-form-title"}>{currentSearchEngineText.title}</div>
                            }
                            {searchEngineStatusStore && (currentSearchEngine.subSearchEngines || currentSearchEngine.itemFilter) &&
                                <div className={"search-engine-selection"}>
                                    {currentSearchEngine.subSearchEngines &&
                                    <div className={"sub-search-engine"}>
                                        {this.props.subSearchEngineSelectionTitle &&
                                        <div className={"sub-search-engine-title"}>{this.props.subSearchEngineSelectionTitle}</div>
                                        }
                                        {currentSearchEngine.subSearchEngines.map((searchEngine: ISearchEngine, index: number) =>
                                            <Fragment key={"subSearchEngine_" + index}>
                                                <input
                                                    id={"subSearchEngine_" + index}
                                                    type="radio"
                                                    onChange={() => searchEngineStatusStore.selectSubSearchEngineIndex(index)}
                                                    checked={selectedSubSearchEngine && index === searchEngineStatusStore.subSearchEngineIndex}
                                                />
                                                <label htmlFor={"subSearchEngine_" + index}>{searchEngine.title}</label>
                                            </Fragment>)
                                        }
                                    </div>
                                    }
                                    {selectedSubSearchEngine.itemFilter && selectedSubSearchEngine.itemFilter.itemFilters &&
                                    this.getItemFilterSelect(selectedSubSearchEngine)
                                    }
                                </div>
                            }
                            {currentSearchEngine.subSearchEngines && tabItemsContent &&
                                <div className={(!this.props.itemsColClass ? "col-md-12 col-lg-10" : this.props.itemsColClass) + " " + (selectedSubSearchEngine.code || "")}>
                                    <SearchItemsContainer
                                        searchItems={tabItemsContent.filter((item: ISearchItem) => !itemCodeToHide || itemCodeToHide.indexOf(item.data.name) === -1)}
                                    />
                                </div>
                            }
                            {currentSearchEngine.searchItems &&
                                <div className={!this.props.itemsColClass ? "col-md-12 col-lg-10" : this.props.itemsColClass}>
                                    <SearchItemsContainer searchItems={currentSearchEngine.searchItems}/>
                                </div>
                            }
                        </div>
                        <div id="se-error-message" className="hidden"/>
                        <SubmitButton
                            label={(currentSearchEngineText && currentSearchEngineText.buttonText) || this.props.submitLabel}
                            buttonClass={"elem-button-primary search-engine-submit"}
                        />
                    </div>
                </div>
            </form>
        );
    }

    private renderQuickSearchForm() {
        return (
            <form
                id={"searchExpress"}
                className={"search-express-form"}
                action={"/search"}
            >
                {
                    <input
                        className={"search-express-input"}
                        name={"rf"}
                        type="text"
                        placeholder = {this.props.quickInputText}
                    />
                }
                {
                    <>
                        <input type={"hidden"} name={"topList"} value={"false"}/>
                        <input type={"hidden"} name={"topHotel"} value={"false"}/>
                    </>
                }
                {
                    <SubmitButton
                        label={this.props.quickSubmitLabel}
                        buttonClass={"search-express-button"}
                    />
                }
            </form>

        );
    }

    /**
     * find the correct submit url
     * @param selectedSubSearchEngine
     * @param currentSearchEngine
     */
    private getSubmitUrl(selectedSubSearchEngine: ISearchEngine, currentSearchEngine: ISearchEngine) {
        if (selectedSubSearchEngine && selectedSubSearchEngine.submitUrl) {
            return selectedSubSearchEngine.submitUrl;
        } else {
            return currentSearchEngine.submitUrl || this.props.submitUrl;
        }

    }

    /**
     * get the item filter select
     * @param selectedSubSearchEngine
     */
    private getItemFilterSelect(selectedSubSearchEngine: ISearchEngine) {
        const searchEngineStore = this.props.searchEngineStore;
        const options = selectedSubSearchEngine.itemFilter.itemFilters;
        const selectedValue = this.getSelectedItemFilter(selectedSubSearchEngine);
        return (
            <div
                ref={(ref: HTMLDivElement) => this.filterItemRef = ref}
                className={"item-filter"}>
                {selectedSubSearchEngine.itemFilter.title &&
                <div className={"item-filter-title"}>{selectedSubSearchEngine.itemFilter.title}</div>
                }
                <Select
                    classNamePrefix={"custom-filter-select"}
                    getOptionValue={(item: ICodeLabelFilter) => item.code.split(":")[0]}
                    name={selectedSubSearchEngine.itemFilter.name}
                    isSearchable={false}
                    onChange={(option: ICodeLabelFilter) => {
                        $(this.filterItemRef).trigger("change");
                        searchEngineStore.setValue(selectedSubSearchEngine.itemFilter.name, option.code);
                    }}
                    value={selectedValue}
                    options={options}
                />
            </div>
        );
    }
}
