import * as React from "react";
import * as DOMPurify from "dompurify";
import Highlighter from "react-highlight-words";
import {observable} from "mobx";
import {inject, observer} from "mobx-react";
import {ChangeEvent, Component, Fragment} from "react";
import Select, {components, createFilter} from "react-select";
import {FormatOptionLabelMeta} from "react-select/base";
import {Config} from "react-select/src/filters";
import {SearchEngineStore} from "../../SearchEngineStore";
import {ISearchEngineText} from "../../text";
import LinkSearchItem, {ILinkSearchItemProps} from "../LinkSearchItem";
import {urlParameters} from "../../../tools/formTools";
import {startsWith} from "../../../tools/polyfillTools";
import {ISearchItemProps} from "../index";

/**
 * the option props interface
 */
export interface IOptionProps {
    code: string;
    label: string;
    group?: string;
    disabled?: boolean;
}

/**
 * the option for select
 */
export interface ISelectOption {
    value: string;
    label: string;
    isDisabled?: boolean;
}

/**
 * the group option for select
 */
export interface ISelectGroupOption {
    label?: string;
    code?: string;
    options: ISelectOption[];
}

/**
 * props interface of select search item
 */
export interface ISelectSearchItemProps extends ISearchItemProps {
    links?: ILinkSearchItemProps;
    options: IOptionProps[];
    groupLabels?: string[];
    placeholder?: string;
    noResultText?: string;
    autocomplete?: boolean;
    multi?: boolean;
    disabled: boolean;
    searchEngineStore: SearchEngineStore;
    searchEngineText: ISearchEngineText;
    disableIndicators?: boolean;
    clearable?: boolean;
    hiddenValue?: string;
    checkboxLabel?: string;
    iconHtml?: string;
    keepOpen?: boolean;
    onChange?: (label: string) => void;
    callBack?: (label: string) => void;
    focusOnUpdate?: boolean;
    submitOnSelect?: boolean;
    useNative?: boolean;
    highlightOption?: boolean;
    matchFromStart?: boolean;
}

interface ISelectSearchItemState {
    toSubmit: boolean;
}

/**
 * search input with a select and an optional links panel
 */
@inject("searchEngineStore")
@inject("searchEngineText")
@observer
export default class SelectSearchItem extends Component<ISelectSearchItemProps, ISelectSearchItemState> {

    @observable private check: boolean;
    private ref: Select;
    private wrapper: HTMLDivElement;
    private hasLabel: boolean;

    constructor(props: Readonly<ISelectSearchItemProps>) {
        super(props);
        this.check = false;
        if (this.props.options.length > 0 && !props.clearable && !props.placeholder && !props.searchEngineStore.values[props.name]) {
            props.searchEngineStore.selectValue(this.props.name, [this.props.options[0].code]);
        }
        this.hasLabel = false;
        this.handleUpdateSelect = this.handleUpdateSelect.bind(this);
    }

    /**
     * component did mount
     */
    public componentDidMount(): void {
        this.setState({toSubmit: false});
        if (this.props.callBack) {
            const selectedOptions = this.findSelectedValue(this.props.searchEngineStore.values[this.props.name]);
            if (selectedOptions && selectedOptions.length > 0) {
                this.props.callBack(selectedOptions
                    .map((option: ISelectOption) => option.label)
                    .join(","));
            }
        }
        if (this.props.searchEngineStore.values[this.props.name] && this.props.searchEngineStore.values[this.props.name][0]) {
            this.hasLabel = true;
        }
        if (this.props.onChange) {
            $(this.wrapper).on("select-search-item:update", this.handleUpdateSelect);
        }
    }

    /**
     * will unmount
     */
    public componentWillUnmount(): void {
        if (this.props.onChange) {
            $(this.wrapper).off("select-search-item:update", this.handleUpdateSelect);
        }
    }

    /**
     * handle select receive update event
     */
    private handleUpdateSelect() {
        const selected = this.findSelectedValue(this.props.searchEngineStore.values[this.props.name]);
        if (selected) {
            const newValueLabel = selected.map((o: ISelectOption) => o.label).join(",");
            this.props.onChange(newValueLabel);
        } else {
            this.props.onChange(undefined);
        }
    }

    /**
     * component did update
     */
    public componentDidUpdate(): void {
        if (this.ref && this.props.focusOnUpdate) {
            this.ref.focus();
        }
        if (this.state.toSubmit) {
            this.handleSubmit();
        }
        // trick to tell to panel the item has not label anymore
        if (this.props.onChange && this.hasLabel
            && (!this.props.searchEngineStore.values[this.props.name]
                || this.props.searchEngineStore.values[this.props.name].length <= 0
                || !this.props.searchEngineStore.values[this.props.name][0])) {
            this.props.onChange(undefined);
            this.hasLabel = false;
        }
    }

    /**
     * Renders component
     */
    public render(): JSX.Element {
        return this.props.manageOwnLabel ? this.renderItemWithLabel() : this.renderItem();
    }

    /**
     *  Renders item with its label
     */
    private renderItemWithLabel(): JSX.Element {
        return (
            <>
                <div className="item-label-se">{this.props.label}</div>
                <div className="item-content-se">{this.renderItem()}</div>
            </>
        );
    }

    /**
     * Renders item
     */
    private renderItem(): JSX.Element {
        const searchEngineStore = this.props.searchEngineStore;
        const groups = this.createGroups(this.props.options || [], this.props.groupLabels || []);
        const groupedOptions: ISelectGroupOption[] = [];
        groups.forEach((value: ISelectGroupOption) => groupedOptions.push(value));
        const {name, clearable, hiddenValue} = this.props;
        if (this.props.useNative) {
            return (
                this.renderNativeSelect(groupedOptions, name)
            );
        }
        let filterConfig: Config = null;
        if (this.props.matchFromStart) {
            filterConfig = {matchFrom: "start"};
        }

        return (
            <>
                {this.getCheckbox()}
                {(!this.isCheckable() || this.check) && this.getCheckboxLabel()}
                {(!this.isCheckable() || this.check) &&
                    <div ref={(wrapper: HTMLDivElement) => this.wrapper = wrapper} className={"select-search-item"}>
                        <Select
                            ref={(select: Select) => this.ref = select}
                            name={name}
                            options={groupedOptions}
                            isMulti={this.props.multi}
                            isClearable={clearable === undefined ? this.props.multi : clearable}
                            className="basic-select"
                            classNamePrefix="custom-select"
                            onMenuOpen={() => $(this.wrapper).trigger("search-item:opening")}
                            onMenuClose={() => $(this.wrapper).trigger("search-item:closing")}
                            menuIsOpen={this.props.keepOpen ? true : undefined}
                            placeholder={this.props.placeholder || ""}
                            isSearchable={this.props.autocomplete}
                            value={this.findSelectedValue(searchEngineStore.values[name])}
                            components={{
                                Group: SelectSearchItem.getGroupLayout(),
                                Option: SelectSearchItem.getOptionLayout(),
                                DropdownIndicator: this.getDropdownIndicatorContainer(),
                                IndicatorSeparator:  this.getSeparatorIndicatorContainer(),
                                ClearIndicator:  this.getClearIndicatorContainer(),
                                MenuList: this.getMenuLayout()
                            }}
                            formatOptionLabel={
                                (option: { label: string; value: string }, labelMeta: FormatOptionLabelMeta<{ label: string; value: string }>) => SelectSearchItem.formatOptionLabel(option, labelMeta, this.props.highlightOption)
                            }
                            filterOption={
                                (candidate: ISelectOption, input: string) => createFilter(filterConfig)({value: candidate.label, label: candidate.label, data: {}}, input)
                            }
                            noOptionsMessage={() => this.props.noResultText}
                            isDisabled={this.props.disabled}
                            onChange={(selected: ISelectOption | ISelectOption[]) => this.handleValueChange(selected)}
                        />
                    </div>
                }
                {
                    this.props.iconHtml &&
                    <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.iconHtml)}}/>
                }
                {
                    (this.isCheckable() && !this.check) &&
                    <input name={this.props.name} type="hidden" value={hiddenValue}/>
                }
            </>
        );
    }

    /**
     * Highlight matching label part of options
     * @param option option data
     * @param labelMeta current select value data
     * @param highlight true if values should be highlighted
     */
    private static formatOptionLabel(option: ISelectOption, labelMeta: FormatOptionLabelMeta<{ label: string; value: string }>, highlight: boolean) {
        if (highlight) {
            return (
                <Highlighter
                    highlightClassName={"select-highlight" + (option.isDisabled ? " disabled" : "")}
                    searchWords={[labelMeta.inputValue]}
                    autoEscape={true}
                    textToHighlight={option.label}
                />
            );
        } else {
            return (option.label);
        }
    }

    /**
     * Handles value change
     */
    private handleValueChange(selected: ISelectOption | ISelectOption[]) {
        let newValueLabel = "";
        if (!selected) {
            this.props.searchEngineStore.selectValue(this.props.name, []);
        } else if (Array.isArray(selected)) {
            this.props.searchEngineStore.selectValue(this.props.name, selected.map((o: ISelectOption) => o.value));
            newValueLabel = selected.map((o: ISelectOption) => o.label).join(",");
            if (this.props.submitOnSelect) {
                this.setState({toSubmit: true});
            }
        } else {
            if (selected.value && startsWith(selected.value, "href:")) {
                window.location.href = selected.value.replace("href:", "");
            } else {
                this.props.searchEngineStore.setValue(this.props.name, selected.value);
                newValueLabel = selected.label;
                if (this.props.submitOnSelect) {
                    this.setState({toSubmit: true});
                }
            }
        }
        if (this.props.onChange) {
            this.props.onChange(newValueLabel);
        }
        this.hasLabel = true;
    }

    /**
     * Handles submit
     */
    private handleSubmit() {
        if (this.props.submitOnSelect) {
            $(this.wrapper).trigger("search-engine:submit");
        }
    }

    /**
     * render native select
     * @param groupedOptions
     * @param name
     */
    private renderNativeSelect(groupedOptions: ISelectGroupOption[], name: string) {
        return (
            <select name={name}>
                {groupedOptions.map((group: ISelectGroupOption) => {
                    return (
                        <Fragment key={"select-group-" + name + "_" + group.code}>
                            {group.label &&
                            <optgroup label={group.label}>
                                {group.options.map((option: ISelectOption) =>
                                    <option key={"select-group-" + name + "_" + group.code + "_" + option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                )}
                            </optgroup>
                            }
                            {!group.label &&
                            group.options.map((option: ISelectOption) =>
                                <option key={"select-group-" + name + "_" + group.code + "_" + option.value} value={option.value}>
                                    {option.label}
                                </option>
                            )}

                        </Fragment>
                    );
                })}
            </select>
        );
    }

    /**
     * get checkbox label
     */
    private getCheckboxLabel() {
        const checkboxLabel = this.props.checkboxLabel;
        if (checkboxLabel && checkboxLabel !== "") {
            return (
                <span>{checkboxLabel}</span>
            );
        }
    }

    /**
     * get the checkbox html
     */
    private getCheckbox() {
        const hiddenValue = this.props.hiddenValue;
        if (hiddenValue && hiddenValue !== "") {
            return (
                <>
                    <input
                        type="checkbox"
                        id="cbx_openSubCheck"
                        checked={this.check}
                        className="input-open-sub-check"
                        onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                            this.check = evt.currentTarget.checked;
                        }}
                    />
                    <label htmlFor="cbx_openSubCheck" className="label-open-sub-check"/>
                </>
            );
        }
    }

    /**
     * is has checkbox
     */
    private isCheckable() {
        const hiddenValue = this.props.hiddenValue;
        return hiddenValue && hiddenValue !== "";
    }

    /**
     * find the selected value
     * @param selected
     */
    private findSelectedValue(selected: string[]): ISelectOption[] {
        if (selected && selected.length !== 0 && this.props.options && this.props.options.length !== 0) {
            let selectedOptions = this.props.options
                .filter(({code}: IOptionProps) => selected.indexOf(code) !== -1)
                .map(({code, label}: IOptionProps) => ({value: code, label}));
            if (selectedOptions.length === 0) {
                selectedOptions = this.props.options
                    .filter(({code}: IOptionProps) => selected.join(urlParameters.or) === code)
                    .map(({code, label}: IOptionProps) => ({value: code, label}));
            }
            return selectedOptions;
        }
    }

    /**
     * the menu layout
     */
    private getMenuLayout() {
        return (props: any) => {
            const input: string = props.selectProps.inputValue;
            if (!this.props.links) {
                return (
                    <components.MenuList {...props}>
                        {props.children}
                    </components.MenuList>
                );
            } else if (this.props.links && input && input.length > 2) {
                const prefix = "panel";
                return (
                    <div className={"search-engine-panel custom-select__menu-panel"}>
                        {
                            this.props.links.title &&
                            <div className={prefix + "-title"}>{this.props.links.title}</div>
                        }
                        <div className={prefix + "-content"}>
                            <components.MenuList {...props}>
                                {props.children}
                            </components.MenuList>
                        </div>
                    </div>
                );
            } else {
                return (
                    <LinkSearchItem
                        searchEngineStore={props.searchEngineStore}
                        name={this.props.name}
                        title={this.props.links.title}
                        links={this.props.links.links}
                        addClass="search-panel-links"
                    />
                );
            }
        };
    }

    /**
     * the dropdown layout
     */
    private getDropdownIndicatorContainer() {
        return (props: any) => {
            if (this.props.disableIndicators) {
                return (<></>);
            } else {
                return (
                    <components.DropdownIndicator {...props}/>
                );
            }
        };
    }

    /**
     * the dropdown layout
     */
    private getSeparatorIndicatorContainer() {
        return (props: any) => {
            if (this.props.disableIndicators) {
                return (<></>);
            } else {
                return (
                    <components.IndicatorSeparator {...props}/>
                );
            }
        };
    }

    /**
     * the dropdown layout
     */
    private getClearIndicatorContainer() {
        return (props: any) => {
            if (this.props.disableIndicators && !this.props.clearable) {
                return (<></>);
            } else {
                return (
                    <components.ClearIndicator {...props}/>
                );
            }
        };
    }

    /**
     * the option layout
     */
    private static getOptionLayout() {
        return (props: any) => {
            const value: string = props.value;
            let addClass = "";
            if (value && value.indexOf(".") !== -1) {
                addClass = "level-" + (value.split(".").length - 1);
            }
            if (!value || value === "") {
                addClass = "empty-item";
            }
            if (value && startsWith(value, "href:")) {
                addClass = "link-item";
            }
            return (
                <components.Option className={addClass} {...props}/>
            );
        };
    }

    /**
     * the group layout
     */
    private static getGroupLayout() {
        return (props: any) => (
            <div className={props.data.code || ""}>
                <components.Group {...props}/>
            </div>
        );
    }

    /**
     * create groups for select
     * @param options
     * @param groupNames
     */
    private createGroups(options: IOptionProps[], groupNames: string[]): Map<string, ISelectGroupOption> {
        const groups = new Map<string, ISelectGroupOption>();
        groups.set("", {options: []});
        options.forEach((option: IOptionProps) => {
            if (option.group) {
                const groupCode = option.group;
                if (!groups.has(groupCode)) {
                    groups.set(groupCode, {label: groupNames[groupCode], code: groupCode, options: []});
                }
                groups.get(groupCode).options.push({value: option.code, label: option.label, isDisabled: option.disabled});
            } else {
                groups.get("").options.push({value: option.code, label: option.label, isDisabled: option.disabled});
            }
        });
        return groups;
    }
}