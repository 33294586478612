import {isMobileDevice, isTabletDevice} from "../layout/device";

/**
 * Fix the compatibility of dispatch event
 * @param elem The HTML element to attach event
 * @param eventName The name of event
 */
function dispatchEvent(elem: HTMLElement, eventName: string) {
    if (elem) {
        let event;
        if (typeof (Event) === "function") {
            event = new Event(eventName);
        } else { // fix IE
            event = document.createEvent("Event");
            event.initEvent(eventName, true, true);
        }
        elem.dispatchEvent(event);
    }
}

/**
 * Get the click or touchstart event if it is a device
 * @param namespace The namespace of event
 * @returns {string} The name of the event, touchstart (mobile) or mouse (desktop)
 */
function getClickDevice(namespace?: string): string {
    const isDevice = isMobileDevice() || isTabletDevice();
    const np = namespace ? `.${namespace}` : "";
    return `${isDevice ? "touchstart" : "click"}${np}`;
}

export {
    dispatchEvent,
    getClickDevice
};
