import $$ from "jquery-selector-cache";
import {addsCookie} from "../../_core/scripts/specific/cookie";

/**
 * Initialize the footer component
 */
export function initFooter() {
    // Exit when the component doesn't exist
    if (!$$(".cpt-footer").length) {
        return;
    }

    // Events
    $$(".cpt-footer").on("click.desktop", ".desktop-version", handleSwitchToDesktop);
}

/**
 * handleDesktopVersionClick
 * @param evt
 */
function handleSwitchToDesktop(evt: JQuery.ClickEvent) {
    const expireDate = new Date();
    expireDate.setTime(expireDate.getTime() + (3 * 60 * 60 * 1000));
    addsCookie(
        "passage_site_mobile_desktop",
        "desktop",
        "; expires=" + expireDate.toUTCString(),
        $(evt.currentTarget).data("domain")
    );
}