import {action, computed, observable} from "mobx";

/**
 * the search engine status store
 */
export class SearchEngineStatusStore {
    @observable private _subSearchEngineIndex: number;

    constructor() {
        this._subSearchEngineIndex = 0;
    }

    @computed
    get subSearchEngineIndex(): number {
        return this._subSearchEngineIndex;
    }

    /**
     * change current sub tab
     */
    @action.bound
    public selectSubSearchEngineIndex(index: number) {
        this._subSearchEngineIndex = index;
    }
}