import * as React from "react";
import {createRef, ReactElement, RefObject} from "react";
import "moment/locale/fr";
import DayPickerInput from "react-day-picker/DayPickerInput";
// @ts-ignore
import MomentLocaleUtils, {formatDate, parseDate} from "react-day-picker/moment";
import {inject, observer} from "mobx-react";
import {ISearchItemProps} from "../index";
import moment = require("moment");

export interface IInputDatesRangeSearchItemProps extends ISearchItemProps {
    labelFrom: string;
    placeholderFrom: string;
    labelTo: string;
    placeholderTo: string;
    displayedDateFormat: string;
    monthToShow: number;
    dayName: string;
    monthName: string;
    durationName: string;
    checkinDateName: string;
    checkoutDateName: string;
    useCheckinCheckoutParams: boolean;
    locale?: string;
}

interface IInputDatesRangeSearchItemState {
    from: Date;
    to: Date;
}

/**
 * the search item by date of arrival (from) and date of departure (to)
 */

@inject("searchEngineText")
@observer
export default class InputDatesRangeSearchItem extends React.Component<IInputDatesRangeSearchItemProps, IInputDatesRangeSearchItemState> {

    private readonly locale: string;
    private readonly inputTo: RefObject<DayPickerInput>;

    constructor(props: Readonly<IInputDatesRangeSearchItemProps>) {
        super(props);
        this.inputTo = createRef();
        this.state = {
            from: undefined,
            to: undefined,
        };
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);

        this.locale = this.props.locale || "fr";
        moment.locale(this.locale);

    }

    /**
     * Render
     */
    public render() {
        const {from, to} = this.state;
        const modifiers = {start: from, end: to};
        return (
            <div className="cpt-input-from-to">
                <div className="input-from">
                    <DayPickerInput
                        value={from}
                        placeholder={this.props.placeholderFrom || ""}
                        format={this.props.displayedDateFormat || "ddd DD MMMM"}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        dayPickerProps={{
                            selectedDays: [from, {from, to}],
                            disabledDays: {before: new Date()},
                            // toMonth: to,
                            modifiers,
                            numberOfMonths: this.props.monthToShow || 2,
                            onDayClick: (date: Date) => this.handleOpenDepartureDate(date),
                            localeUtils: MomentLocaleUtils,
                            locale: this.locale
                        }}
                        onDayChange={this.handleFromChange}
                    />
                </div>
                <span className="input-sep"/>
                <div className="input-to">
                    <DayPickerInput
                        ref={this.inputTo}
                        value={to}
                        placeholder={this.props.placeholderTo || ""}
                        format={this.props.displayedDateFormat || "ddd DD MMMM"}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        dayPickerProps={{
                            selectedDays: [from, {from, to}],
                            disabledDays: {before: from || new Date()},
                            modifiers,
                            month: from,
                            fromMonth: from,
                            numberOfMonths: this.props.monthToShow || 2,
                            localeUtils: MomentLocaleUtils,
                            locale: this.locale
                        }}
                        onDayChange={this.handleToChange}
                    />
                </div>

                {this.createHiddenInputs(this.state)}
            </div>
        );
    }

    /**
     * create hidden inputs
     * @param from
     * @param to
     */
    private createHiddenInputs({from, to}: IInputDatesRangeSearchItemState): ReactElement {
        const useCheckinCheckoutParams = this.props.useCheckinCheckoutParams;
        return (
            <>
                {
                    useCheckinCheckoutParams &&
                    <>
                        <input type="hidden" name={this.props.checkinDateName} value={moment(from).format("YYYY-MM-DD") || ""}/>
                        <input type="hidden" name={this.props.checkoutDateName} value={moment(this.getCheckoutDate(from, to)).format("YYYY-MM-DD") || ""}/>
                    </>
                }
                {
                    !useCheckinCheckoutParams &&
                    <>
                        <input type="hidden" name={this.props.dayName} value={moment(from).format("DD") || ""}/>
                        <input type="hidden" name={this.props.monthName} value={moment(to).format("MM/YYYY") || ""}/>
                        <input type="hidden" name={this.props.durationName} value={this.getDurationInDays(to, from) || ""}/>
                    </>
                }
            </>
        );
    }

    /**
     * open to departure date calendar if checkin date is selected
     */
    private handleOpenDepartureDate(date: Date): unknown {
        const now = new Date();
        now.setHours(0, 0, 0, 0);
        if (date >= now) {
            return this.inputTo.current.getInput().focus();
        }
        return null;
    }

    /**
     * Change the from date, reset the to date and focus the "to" input field
     * @param from arrival date
     */
    private handleFromChange(from: Date) {
        this.setState({to: undefined});
        this.setState({from});
    }

    /**
     * set the state of the departure date
     * @param to departure date
     */
    private handleToChange(to: Date) {
        this.setState({to});
    }

    /**
     * calculate the duration in days between to and from
     * @param from arrival date
     * @param to departure date
     */
    private getDurationInDays(from: Date, to: Date): string {
        if (from && to) {
            let nbNights = moment(from).diff(to, "days");
            if (nbNights === 0) {
                nbNights = 1;
            }
            return nbNights + "," + nbNights;
        }
    }

    /**
     * return the checkout date (it must be al least one day after the checkin date)
     * @param from
     * @param to
     */
    private getCheckoutDate(from: Date, to: Date): Date {
        if (from && to && (from.getTime() === to.getTime())) {
            from.setDate(from.getDate() + 1);
            return from;
        } else {
            return to;
        }
    }
}
