import $$ from "jquery-selector-cache";
import {extractIdFromDataLink, handleClickOnFavoriteProduct, displayUserFavorites} from "../../components/favorite/favorite";
import {loggedCustomerAction, deleteUserSeenProduct} from "../../_core/scripts/specific/customer/customer";
import {addUrlParameter} from "orxapi.tools.url";

/**
 * Initialize the noProduct component
 */
export function initNoProduct() {
    if($(".cpt-result").length){
        return;
    }
    $(".no-product-wrapper").removeClass("d-none");
}

/**
 * Initialize the manage private sell
 */
export function initManagePrivateSell() {
    managePrivateSaleShow();
    $("body").on("click.togglePrivateSell", ".is-clickable-connected", handleConnectedClick);
}

export function managePrivateSaleShow() {
    const infoWrap = $(".description-wrap.private");
    const privateSale = $(".private-sale");
    if (privateSale.length > 0) {
        loggedCustomerAction(() => {
            privateSale.addClass("d-none");
        }, () => {
            infoWrap.addClass("d-none");
        });
    }
}

/**
 * handler of private sell click
 * @param evt
 */
function handleConnectedClick(evt: JQuery.ClickEvent) {
    const elem = $(evt.currentTarget);
    const target = $(evt.target);
    const link = elem.data("link") as string;
    const linkTarget = elem.data("linkTarget") as string;
    const noFollowClass = "un-clickable";

    // Escape when the data-link is empty
    if (!link.length) {
        return;
    }
    // Escape when noFollowClass
    if (target.hasClass(noFollowClass) || target.parents("." + noFollowClass).length) {
        return;
    }
    //do treatment depending on whether user connected or not
    loggedCustomerAction(() => {
        if (linkTarget && linkTarget.length) {
            window.open(link, linkTarget);
        } else {
            window.location.href = link;
        }
    }, () => {
        // scroll to account popin
        window.scrollTo(0, 0);
        $("#OrxapiWidgetAccount").removeClass("d-none");
        (window as any).OrxapiWidgetAccount.openPopin("private-sales");
        (window as any).OrxapiWidgetAccount.setSuccessRedirectUrl(link);
    });
}

/**
 * Initialize the manage favorite
 */
export function initManageFavorite() {
    if (!$$(".cpt-result").length) {
        return;
    }
    loggedCustomerAction(displayUserFavorites);
    // Add event for toggle favorite
    $("body").on("click.toggleFavorite", ".btn-toggle-favorite", handleClickOnFavoriteProduct);

    // Add event for delete seen product
    $$(".cpt-result").on("click.deleteSeenProduct", ".btn-delete-seen-product", handleToggleHistory);
}

export function initAvailsDetails() {
    const availItems = $(".avails-details").filter((_, elem) => elem.childElementCount === 0);
    const productIds = availItems.map((_, elem) => $(elem).data("productId")).get();
    if (productIds.length > 0) {
        const apiPath = addUrlParameter("s_pid",productIds.join(","), `/api/serp/products/avails/details${window.location.search}`);
        $.ajax(apiPath).then((results : string) => {
            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = results;
            productIds.forEach((productId : string) => {
                const targetElement = $(`.avails-details[data-product-id="${productId}"]`);
                const elements = tempDiv.querySelectorAll(`[data-product-id="${productId}"]`);

                elements.forEach((element) => {
                    targetElement.append(element);
                });
            });
        }).catch((error) => {
            console.error("Error fetching product details:", error);
        });
    }
}

export function initRatingDetails() {
    const ratingItems = $(".rating-holder").filter((_, elem) => (elem.childElementCount === 0 && !elem.className.includes("no-rating")));
    const productIds = ratingItems.map((_, elem) => $(elem).data("productId")).get();

    if (productIds.length > 0) {
        const apiPath = addUrlParameter("s_pid",productIds.join(","), `/api/serp/products/rating/details${window.location.search}`);
        $.ajax( apiPath).then((results : string) => {
            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = results;
            productIds.forEach((productId: string) => {
                const targetElement = $(`.rating-holder[data-product-id='${productId}']`);
                const elements = tempDiv.querySelectorAll(`[data-product-id='${productId}']`);
                if (elements.length === 0) {
                    // this is to help filter the products that have already been looped over once and avoid long URL issues
                    targetElement.addClass("no-rating");
                }
                elements.forEach((element) => {
                    targetElement.append(element);
                });
            });
        }).catch((error) => {
            console.error("Error fetching rating details:", error);
        });
    }
}

/**
 * Handler of the toggle history button
 * @param evt
 */
function handleToggleHistory(evt: JQuery.ClickEvent) {
    // When this is the account favorites page
    if ($$("body").hasClass("page-account-history")) {
        handleDeleteHistory(evt);
    }
}


/**
 * Handler of the delete seen product button
 * @param evt
 */
function handleDeleteSeenProduct(evt: JQuery.ClickEvent) {
    // Update services
    const overlay = $(evt.delegateTarget);
    const elem = $(evt.currentTarget);
    const action = elem.data("action");
    const elemWrap = elem.parents(".cpt-result");
    if (action ===  "validate" && elemWrap){
        const id = extractIdFromDataLink(elem.parents(".cpt-result")?.data("link"));
        deleteUserSeenProduct(id, () => {
            elemWrap.remove();
            initNoProduct();
        });
        overlay.off("click.cancelDelete");
    }
    else {
        // Close overlay and unbind event
        overlay.addClass("d-none");
        overlay.off("click.cancelDelete");
    }
}

/**
 * Handler of the delete history product
 * @param evt
 */
function handleDeleteHistory(evt: JQuery.ClickEvent) {
    const itemWrap = $(evt.currentTarget).parents(".cpt-result");
    const overlay = itemWrap.find(".delete-history-overlay");

    // Show overlay
    overlay.removeClass("d-none");
    overlay.on("click.cancelDelete", ".btn-action-delete-history", {itemWrap}, handleDeleteSeenProduct);
}
