import * as React from "react";
import {Component, SyntheticEvent} from "react";
import DayPicker, {DayModifiers} from "react-day-picker";
import DateSearchItemStore, {dateSearchItemConfiguration} from "./DateSearchItemStore";
import {inject, observer} from "mobx-react";
import MomentLocaleUtils from "react-day-picker/moment";
import DOMPurify from "dompurify";
import "moment/locale/fr";
import moment = require("moment");
import MonthHead from "./MonthHead";
import {SearchEngineStore} from "../../SearchEngineStore";
import {WrapDiv} from "../../../components/WrapDiv";
import {ISearchEngineText} from "../../text";
import {ISearchItemProps} from "../index";
import {replaceAll} from "../../../tools/stringTools";

interface IAdjustOption {
    code: number;
    label: string;
}

interface IRange {
    enableRangeSelect?: boolean;
    durationName: string;
    durationValueFormat: string;
    rangeDateLabel: string;
    maxDuration?: number;
}

interface IAdjust {
    name: string;
    label?: string;
    adjustOptions?: IAdjustOption[];
}

export interface IDateSearchItemProps extends ISearchItemProps {
    label: string;
    dayName: string;
    monthName: string;
    minDepDate: string;
    maxDepDate: string;
    validLabel?: string;
    submitOnValid?: boolean;
    placeholder?: string;
    monthToShow?: number;
    startAtFromToday?: number;
    triggerDiv?: boolean;
    triggerDivLabel?: string;
    containerTitle?: string;
    anyDateLabel?: string;
    anyDateButtonLabel?: string;
    footerControls?: boolean;
    adjust?: IAdjust;
    visibleDateFormat?: string;
    locale?: string;
    allMonthLabel?: string;
    allMonthSingle?: boolean;
    allMonthNoFlex?: boolean;
    searchEngineStore: SearchEngineStore;
    iconHtml?: string;
    inputForStartLimit?: string;
    inputForEndLimit?: string;
    mobileCloseLabel?: string;
    searchEngineText?: ISearchEngineText;
    toTargetName?: string;
    fromTargetName?: string;
    keepOnClean?: boolean;
    range?: IRange;
}

interface IDateSearchItemState {
    open: boolean;
}

/**
 * the search item by date
 */
@inject("searchEngineStore")
@inject("searchEngineText")
@observer
export default class DateSearchItem extends Component<IDateSearchItemProps, IDateSearchItemState> {
    private readonly store: DateSearchItemStore;
    private dateSearchItemRef: HTMLDivElement;
    private readonly locale: string;
    private oldDate: Date;
    private readonly itemText: any;

    /**
     * Constructor
     * @param props
     */
    public constructor(props: Readonly<IDateSearchItemProps>) {
        super(props);
        this.store = props.searchEngineStore.getDateStoreOrCreate(DateSearchItem.getCode(this.props, this.props.searchEngineStore));
        const dayValue = props.searchEngineStore.values[props.dayName];
        const monthYearValue = props.searchEngineStore.values[props.monthName];
        const fullDateValue = props.searchEngineStore.values[props.name];

        if (this.props.searchEngineText && this.props.searchEngineText.items) {
            this.itemText = this.props.searchEngineText.items[this.props.name ? this.props.name : this.props.dayName + "_" + this.props.monthName];
        } else {
            this.itemText = {};
        }
        this.state = {open: false};

        if (props.name && fullDateValue) {
            this.store.selectDate(moment(fullDateValue, "DD/MM/YYYY").toDate());
        } else if (!props.name && dayValue && monthYearValue) {
            const departureDate = moment(dayValue[0] + "/" + monthYearValue[0], "DD/MM/YYYY").toDate();
            this.store.selectDate(departureDate);
            if (props.range && props.range.enableRangeSelect) {
                const durationValue = props.searchEngineStore.values[props.range.durationName];
                if (durationValue) {
                    const arrivalDate = new Date(departureDate);
                    const durationNumber = parseInt(durationValue[0], 10);
                    arrivalDate.setDate(arrivalDate.getDate() + durationNumber);
                    this.store.updateRangeDate(arrivalDate);
                }
            }
        }

        if (props.adjust) {
            const adjustValue = props.searchEngineStore.values[props.adjust.name];
            if (adjustValue && adjustValue[0]) {
                this.store.setAdjustDateBy(Number(adjustValue[0]));
            }
        }

        const minDep = props.searchEngineStore.values[props.minDepDate];
        const maxDep = props.searchEngineStore.values[props.maxDepDate];
        if (minDep && maxDep) {
            const min = moment(minDep, "YYYY-MM-DD");
            const max = moment(maxDep, "YYYY-MM-DD");
            const adjust = this.store.adjustValue;
            if (adjust) {
                min.add(adjust, "day");
                max.add(-adjust, "day");
            }
            this.store.addMonth(min.format(dateSearchItemConfiguration.monthYearPattern));
            this.store.addMonth(max.format(dateSearchItemConfiguration.monthYearPattern));

        }

        this.handleCloseCalendarContainer = this.handleCloseCalendarContainer.bind(this);
        this.locale = this.props.locale || "fr";
        moment.locale(this.locale);
        this.cleanSelection = this.cleanSelection.bind(this);
        this.setOpen = this.setOpen.bind(this);
        this.setClose = this.setClose.bind(this);
        this.toggleOpen = this.toggleOpen.bind(this);
    }

    /**
     * create a code for this date search item
     * @param props
     * @param searchEngineStore
     */
    public static getCode(props: IDateSearchItemProps, searchEngineStore: SearchEngineStore) {
        let code = "";
        if (props.name) {
            code += props.name;
        }
        if (props.dayName && props.monthName) {
            code += props.dayName + "_" + props.monthName;
        }
        return this.buildDateStoreCode(searchEngineStore, code);
    }

    /**
     * create a code for this date search item
     * @param searchEngineStore
     * @param name
     */
    public static buildDateStoreCode( searchEngineStore: SearchEngineStore, name: string): string {
        return searchEngineStore.currentSearchEngine.code + "_" + name;
    }

    /**
     * component did mount
     */
    public componentDidMount() {
        // deprecated, use date-search-item:close
        this.dateSearchItemRef.addEventListener("dateSearchItem:close", this.handleCloseCalendarContainer);
        this.dateSearchItemRef.addEventListener("date-search-item:close", this.handleCloseCalendarContainer);
        this.dateSearchItemRef.addEventListener("date-search-item:clean", this.cleanSelection);
    }

    /**
     * component will unmount
     */
    public componentWillUnmount() {
        // deprecated, use date-search-item:close
        this.dateSearchItemRef.removeEventListener("dateSearchItem:close", this.handleCloseCalendarContainer);
        this.dateSearchItemRef.removeEventListener("date-search-item:close", this.handleCloseCalendarContainer);
        this.dateSearchItemRef.removeEventListener("date-search-item:clean", this.cleanSelection);
    }

    /**
     * component did update
     * @param prevProps
     * @param prevState
     */
    public componentDidUpdate(prevProps: Readonly<IDateSearchItemProps>, prevState: Readonly<IDateSearchItemState>) {
        // TODO do the same for month
        if (this.oldDate !== this.store.activeDepartureDate) {
            $(this.dateSearchItemRef).trigger("date-search-item:change", this.store.activeDepartureDate);
        }
        this.oldDate = this.store.activeDepartureDate;

        if (prevState.open !== this.state.open) {
            $(this.dateSearchItemRef).trigger("search-item:" + (this.state.open ? "opening" : "closing"));
        }
    }

    /**
     * Renders component
     */
    public render(): JSX.Element {
        return this.props.manageOwnLabel ? this.renderItemWithLabel() : this.renderItem();
    }

    /**
     *  Renders item with its label
     *  @private
     */
    private renderItemWithLabel(): JSX.Element {
        return (
            <>
                <div className="item-label-se">{this.props.label}</div>
                <div className="item-content-se">{this.renderItem()}</div>
            </>
        );
    }

    /**
     * Renders item
     * @private
     */
    private renderItem(): JSX.Element {
        const {
            label, name, footerControls, monthToShow, dayName, monthName, minDepDate, maxDepDate,
            adjust, validLabel, iconHtml, range
        } = this.props;
        const activeDepartureDate = this.store.activeDepartureDate;
        const activeArrivalDate = this.store.activeArrivalDate;
        const oldDepartureDate = this.store.oldDepartureDate;
        const oldArrivalDate = this.store.oldArrivalDate;
        const dateToStart = this.getDateToStart();
        const dateToEnd = this.getDateToEnd();
        const disabledFlexibility = this.props.allMonthNoFlex && this.store.months.length !== 0;
        const disabledClean = !this.props.anyDateLabel && !this.props.anyDateButtonLabel;
        const momentActiveDepartureDate = moment(activeDepartureDate);
        const momentActiveArrivalDate = moment(activeArrivalDate);
        const momentOldDepartureDate = moment(oldDepartureDate);
        const momentOldArrivalDate = moment(oldArrivalDate);
        const nightNumber = momentActiveArrivalDate.diff(momentActiveDepartureDate, "days");
        const durationValueFormat = this.getRange().durationValueFormat || "{nbNight},{nbNight}";
        const oldNightNumber = momentOldArrivalDate.diff(momentOldDepartureDate, "days");
        const durationValue = replaceAll(durationValueFormat, "{nbNight}", String(nightNumber));
        const oldDurationValue = replaceAll(durationValueFormat, "{nbNight}", String(oldNightNumber));

        const zone001 = this.props.searchEngineStore.getHolidays() ? this.props.searchEngineStore.getHolidays().classZones001 : [];
        const zone010 = this.props.searchEngineStore.getHolidays() ? this.props.searchEngineStore.getHolidays().classZones010 : [];
        const zone011 = this.props.searchEngineStore.getHolidays() ? this.props.searchEngineStore.getHolidays().classZones011 : [];
        const zone100 = this.props.searchEngineStore.getHolidays() ? this.props.searchEngineStore.getHolidays().classZones100 : [];
        const zone101 = this.props.searchEngineStore.getHolidays() ? this.props.searchEngineStore.getHolidays().classZones101 : [];
        const zone110 = this.props.searchEngineStore.getHolidays() ? this.props.searchEngineStore.getHolidays().classZones110 : [];
        const zone111 = this.props.searchEngineStore.getHolidays() ? this.props.searchEngineStore.getHolidays().classZones111 : [];
        const holidays = this.props.searchEngineStore.getHolidays() ? this.props.searchEngineStore.getHolidays().holidays : [];

        return (
            <div ref={(elem: HTMLDivElement) => this.dateSearchItemRef = elem} className={"date-search-item " + (this.state.open ? "open" : "")}>
                {this.getTriggerElement()}
                {iconHtml && <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(iconHtml)}}/>}
                {activeDepartureDate &&
                    <>
                        <div className={"clean"} onClick={this.cleanSelection}>
                            {
                                this.props.searchEngineText && this.props.searchEngineText.common && this.props.searchEngineText.common.cleanTitle &&
                                <div className={"clean-title"}>{this.props.searchEngineText.common.cleanTitle}</div>
                            }
                            <div className={"clean-icon"}/>
                        </div>
                        {!name && range.enableRangeSelect && this.isValidActiveRangeDate() &&
                            <>
                                <input type={"hidden"} name={dayName} value={momentActiveDepartureDate.format("DD")}/>
                                <input type={"hidden"} name={monthName} value={momentActiveDepartureDate.format("MM/YYYY")}/>
                            </>
                        }
                        {!name && range.enableRangeSelect && !this.isValidActiveRangeDate() && oldDepartureDate &&
                            <>
                                <input type={"hidden"} name={dayName} value={momentOldDepartureDate.format("DD")}/>
                                <input type={"hidden"} name={monthName} value={momentOldDepartureDate.format("MM/YYYY")}/>
                            </>
                        }
                        {!name && !range.enableRangeSelect &&
                            <>
                                <input type={"hidden"} name={dayName} value={momentActiveDepartureDate.format("DD")}/>
                                <input type={"hidden"} name={monthName} value={momentActiveDepartureDate.format("MM/YYYY")}/>
                            </>
                        }
                        { this.getRange().durationName && range.enableRangeSelect && nightNumber !== 0 &&
                           <input type={"hidden"} name={this.getRange().durationName} value={durationValue}/>
                        }
                        { this.props.range.durationName && range.enableRangeSelect && this.isValidOldRangeDate() && oldNightNumber !== 0 &&
                            <input type={"hidden"} name={this.props.range.durationName} value={oldDurationValue}/>
                        }
                        {name &&
                            <input type={"hidden"} name={name} value={momentActiveDepartureDate.format("DD/MM/YYYY")}/>
                        }
                        {adjust &&
                            <input type="hidden" name={adjust.name} value={this.store.adjustValue || 0}/>
                        }
                    </>
                }
                {minDepDate && maxDepDate && this.store.allMonthsMinDepartureDate && this.store.allMonthsMaxDepartureDate &&
                    <>
                        <input
                            type={"hidden"}
                            name={minDepDate}
                            value={moment(this.store.allMonthsMinDepartureDate).format("YYYY-MM-DD")}
                        />
                        <input
                            type={"hidden"}
                            name={maxDepDate}
                            value={moment(this.store.allMonthsMaxDepartureDate).format("YYYY-MM-DD")}
                        />
                        {adjust &&
                            <input type="hidden" name={adjust.name} value={this.store.adjustValue || 0}/>
                        }
                    </>
                }

                {this.state.open &&
                    <div className="datepicker-container">
                        <div className="cpt-popover">
                            {!disabledClean &&
                                <div className="title-mobile d-md-none">{label}
                                    {this.props.searchEngineText && this.props.searchEngineText.common && this.props.searchEngineText.common.cleanTitle &&
                                        <div className={"clean"} onClick={this.cleanSelection}>
                                            <div>{this.props.searchEngineText.common.cleanTitle}</div>
                                        </div>
                                    }
                                </div>
                            }
                            {this.props.containerTitle &&
                                <div className="panel-container-title">{this.props.containerTitle}</div>
                            }
                            <div className="scrollbar">
                                <DayPicker
                                    numberOfMonths={monthToShow ? monthToShow : 1}
                                    fromMonth={dateToStart}
                                    toMonth={dateToEnd}
                                    initialMonth={activeDepartureDate ||
                                        (this.store.months[0] ? moment(this.store.months[0], dateSearchItemConfiguration.monthYearPattern).toDate() : dateToStart)}
                                    disabledDays={{before: dateToStart, after: dateToEnd}}
                                    selectedDays={this.getSelectedDays()}
                                    onDayClick={(date: Date, modifiers: DayModifiers) => {
                                        return this.handleDayClick(date, modifiers);
                                    }}
                                    modifiers={{included: this.store.includedDates, start: activeDepartureDate, end: activeArrivalDate, holidays, zone001, zone010, zone011, zone100, zone101, zone110, zone111 }}
                                    localeUtils={MomentLocaleUtils}
                                    locale={this.locale}
                                    captionElement={({date}: { date: Date }) => (
                                        <MonthHead
                                            store={this.store}
                                            date={date}
                                            allMonthLabel={this.props.allMonthLabel}
                                            allMonthSingle={this.props.allMonthSingle}
                                            allMonthNoFlex={this.props.allMonthNoFlex}
                                            firstMonth={dateToStart}
                                        />
                                    )}
                                />
                                <div className="legend-block text-center">
                                    <div className="title primary">Zones du calendrier scolaire</div>
                                    <div className="zone-wrap">
                                        <span className="zone-a">Zone A</span><span className="zone-b">Zone B</span><span className="zone-c">Zone C</span>
                                    </div>
                                    <div className="public-holidays">
                                        <span className="zone-h">Jours fériés</span>
                                    </div>
                                </div>
                                <div className={"flexibility-block" + (disabledFlexibility ? " disabled" : "")}>
                                    <WrapDiv className="d-flex" test={!footerControls}>
                                        {this.getAdjustOptions(adjust, disabledFlexibility)}
                                        {!footerControls && validLabel &&
                                            <div className="button-wrap" onClick={this.toggleOpen}>
                                                <span className="elem-button-primary">{validLabel}</span>
                                            </div>
                                        }
                                    </WrapDiv>
                                </div>
                                {this.getFooterControls()}
                            </div>
                        </div>
                    </div>
                }
                {this.props.fromTargetName && this.createNightNumberDiv("night-from", this.props.fromTargetName)}
                {this.props.toTargetName && this.createNightNumberDiv("night-to", this.props.toTargetName, -1)}
            </div>
        );
    }
    /**
     * add div with the number of night from or to active date
     * @param className
     * @param targetName
     * @param multiplier
     * @private
     */
    private createNightNumberDiv(className: string, targetName: string, multiplier = 1) {
        const targetActiveDate = this.props.searchEngineStore.getDateStoreOrCreate(DateSearchItem.buildDateStoreCode(this.props.searchEngineStore, targetName)).activeDepartureDate;
        const activeDate = this.store.activeDepartureDate;
        if (!targetActiveDate || !activeDate) {
            return;
        }
        const nightNumber = moment(activeDate).diff(moment(targetActiveDate), "days") * multiplier;
        const nightTarget = this.itemText ?
            (nightNumber > 1 ? this.itemText.nightsTarget : this.itemText.nightTarget)
            || "{night}" : "{night}";
        const nightText = nightTarget.replace("{night}", String(nightNumber));
        return (<div className={className} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(nightText)}}/>);
    }

    /**
     * Renders footer
     * @private
     */
    private getFooterControls() {
        if (this.props.footerControls && this.props.validLabel) {
            return (
                <div className="footer-controls-block">
                    <div className="button-wrap">
                        <span className="elem-button--border-default btn-panel" onClick={() => {
                            this.store.setEmptyLabel(this.props.anyDateLabel || this.props.placeholder || "");
                            this.store.cleanMonths();
                            this.store.selectDate(null);
                            this.store.resetAdjustDate();
                        }}>
                            {this.props.anyDateButtonLabel || this.props.anyDateLabel}
                        </span>
                        <button className="elem-button--primary btn-panel" onClick={() => this.handleValidation()}>
                            {this.props.validLabel}
                        </button>
                    </div>
                </div>
            );
        }
    }

    /**
     * Handles validation click
     * @private
     */
    private handleValidation() {
        this.setClose();
        if (this.props.submitOnValid) {
            $(this.dateSearchItemRef).trigger("search-engine:submit");
        }
    }

    /**
     * Renders adjust options if needed
     * @param adjust option
     * @param disabled if the flexibility is disabled
     * @private
     */
    private getAdjustOptions(adjust: IAdjust, disabled: boolean) {
        if (adjust && adjust.adjustOptions) {
            return (
                <div className="inner-flexibility-block">
                    <div className="label">{adjust.label}</div>
                    <div className="flexibility-list d-flex">
                        {adjust.adjustOptions.map((option: IAdjustOption, index: number) => {
                            return this.getAdjustOption(option, index, disabled);
                        })}
                    </div>
                </div>
            );
        }
    }

    /**
     * Renders an adjust option
     * @param option option to render
     * @param index current index of the option
     * @param disabled if the flexibility is disabled
     * @private
     */
    private getAdjustOption(option: IAdjustOption, index: number, disabled: boolean) {
        const selected: boolean = this.store.adjustValue === option.code;
        const optionClass = "item " + (disabled ? "disabled" : (selected ? "selected" : ""));
        return (
            <div key={"adjust_" + index} className={optionClass} onClick={() => {if (!disabled) { this.store.setAdjustDateBy(option.code); }}}>
                {option.label}
            </div>
        );
    }

    /**
     * clean all date selected
     * @private
     */
    private cleanSelection() {
        this.store.setEmptyLabel(this.props.anyDateLabel || "");
        this.store.cleanMonths();
        if (!this.props.keepOnClean) {
            this.setClose();
        }
        this.store.selectDate(null);
    }

    /**
     * get the date to start the selectable dates
     * @private
     */
    private getDateToStart(): Date {
        const {startAtFromToday, inputForStartLimit} = this.props;
        if (inputForStartLimit) {
            const dayOffset = !inputForStartLimit.includes("midpda") ? 1 : 0;
            const dateFromInput: Date = this.getDateFromInput(inputForStartLimit, dayOffset);
            if (dateFromInput !== undefined) {
                return dateFromInput;
            }
        }
        if (startAtFromToday) {
            const dateToStart = new Date();
            dateToStart.setDate(dateToStart.getDate() + startAtFromToday);
            return dateToStart;
        } else {
            return new Date();
        }
    }

    /**
     * get the date to end the selectable dates
     * @private
     */
    private getDateToEnd(): Date {
        const {inputForEndLimit} = this.props;
        if (!this.getRange().enableRangeSelect) {
            if (inputForEndLimit) {
                const dayOffset = !inputForEndLimit.includes("mxdpda") ? -1 : 0;
                const dateFromInput: Date = this.getDateFromInput(inputForEndLimit, dayOffset);
                if (dateFromInput !== undefined) {
                    return dateFromInput;
                }
            }
        } else {
            if (this.getRange().maxDuration && this.store.activeDepartureDate) {
                return moment(this.store.activeDepartureDate).add(this.getRange().maxDuration, "d").toDate();
            }
        }
    }

    /**
     *
     * @param inputName
     * @param dayOffset
     * @private
     */
    private getDateFromInput(inputName: string, dayOffset: number): Date {
        const inputItem = $(this.dateSearchItemRef)
            .parents("form")
            .find("input[name='" + inputName + "']");
        if (inputItem.length > 0) {
            const limit = inputItem.val();
            if (limit) {
                const dateFormat = limit.toLocaleString().includes("/") ? "DD/MM/YYYY" : "YYYY-MM-DD";
                let momentStartDate: moment.Moment =  moment(limit, dateFormat);
                if (dayOffset && dayOffset != 0) {
                    momentStartDate = momentStartDate.add(dayOffset, "d");
                }
                return momentStartDate.toDate();
            }
        }
        return undefined;
    }

    /**
     * get the selected dates
     * @private
     */
    private getSelectedDays() {
        return !this.getRange().enableRangeSelect ? (
            this.store.activeDepartureDate || {
                from: this.store.allMonthsMinDepartureDate,
                to: this.store.allMonthsMaxDepartureDate
            }) :
            [this.store.activeDepartureDate, { from: this.store.activeDepartureDate, to: this.store.activeArrivalDate}];
    }

    /**
     * get the adjust label
     * @private
     */
    private getAdjustLabel(): string {
        if (this.props.adjust && this.props.adjust.adjustOptions) {
            const values = this.props.adjust.adjustOptions
                .filter((option: IAdjustOption) => option.code !== 0)
                .filter((option: IAdjustOption) => option.code === this.store.adjustValue);
            return values.length === 0 ? "" : ("[" + values[0].label + "]");
        }
        return "";
    }

    /**
     * close calendar container
     * @private
     */
    private handleCloseCalendarContainer() {
        if (this.state.open) {
            this.setClose();
            if (this.getRange().enableRangeSelect && !this.store.activeArrivalDate) {
                this.store.clearSelectedDates();
            }
        }
    }

    /**
     * Get any date element
     * @private
     */
    private getAnyDateElement(): JSX.Element {
        if (this.props.anyDateLabel) {
            return (
                <div className="default-block">
                    <div className="d-flex">
                        <div className="item" onClick={this.cleanSelection}>
                            {this.props.anyDateLabel}
                        </div>
                    </div>
                </div>
            );
        }
    }

    /**
     * Get trigger element
     * @private
     */
    private getTriggerElement(): JSX.Element {
        if (this.props.triggerDiv) {
            const date = this.getTriggerElementValue(false);
            const flexibility = this.getAdjustLabel().replace("[", "").replace("]", "");
            return (
                <div className="trigger-panel open-datepicker" onClick={this.toggleOpen}>
                    <div className="label-wrap">
                        {this.props.triggerDivLabel &&
                            <span className="label">
                                {this.props.triggerDivLabel}
                            </span>
                        }
                        <span className="input">
                            <span className="date-input">{date || this.props.placeholder}</span>
                            {
                                this.store.isSelectedValue && flexibility &&
                                <span className="flexi-input">{flexibility}</span>
                            }
                        </span>
                    </div>
                    <div className="arrow">
                        <i
                            className="elem-ff-icon"
                            data-icon={this.store.isSelectedValue ? "x" : ">"}
                            role="img"
                            onClick={this.store.isSelectedValue ? (evt: SyntheticEvent<HTMLElement>) => {
                                evt.stopPropagation();
                                this.cleanSelection();
                            } : undefined}
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <input
                    type="text"
                    className="elem-custom-input open-datepicker"
                    readOnly={true} value={this.getTriggerElementValue(true)}
                    placeholder={this.props.placeholder || ""}
                    onClick={this.toggleOpen}
                />
            );
        }
    }

    /**
     * Returns the value of the trigger element
     * @param flexibility
     * @private
     */
    private getTriggerElementValue(flexibility: boolean): string {
        const rangeDateLabel = this.getRange().rangeDateLabel || "{from} au {to}";
        if ( !this.getRange().enableRangeSelect) {
            const visibleDateFormat = this.props.visibleDateFormat || "DD/MM/YYYY {flex}";
            const adjustLabel = flexibility ? this.getAdjustLabel() : "";
            if (this.store.activeDepartureDate) {
                return moment(this.store.activeDepartureDate).format(visibleDateFormat.replace("{flex}", adjustLabel).trim());
            } else if (this.props.allMonthSingle && this.store.visibleMonthValue) {
                return this.store.visibleMonthValue;
            } else if (this.store.visibleMonthsValue) {
                return this.store.visibleMonthsValue;
            }
        } else {
            const visibleDateFormat = this.props.visibleDateFormat || "DD/MM/YYYY";
            const departureDate = this.store.activeDepartureDate;
            const  arrivalDate = this.store.activeArrivalDate;
            if (departureDate && arrivalDate && departureDate < arrivalDate) {
                const from = moment(this.store.activeDepartureDate).format(visibleDateFormat);
                const to = moment(this.store.activeArrivalDate).format(visibleDateFormat);
                return rangeDateLabel.replace("{from}", from).replace("{to}", to).trim();
            } else {
                const oldDepartureDate = this.store.oldDepartureDate;
                const oldArrivalDate = this.store.oldArrivalDate;
                if (oldDepartureDate && oldArrivalDate && oldDepartureDate < oldArrivalDate) {
                    const oldFrom = moment(oldDepartureDate).format(visibleDateFormat);
                    const oldTo = moment(oldArrivalDate).format(visibleDateFormat);
                    return rangeDateLabel.replace("{from}", oldFrom).replace("{to}", oldTo).trim();
                }
            }
        }

        return this.store.empty;
    }

    /**
     * open datepicker
     * @private
     */
    private setOpen() {
        this.setState({open: true});
    }

    /**
     * close datepicker
     * @private
     */
    private setClose() {
        this.setState({open: false});
    }

    /**
     * toggle datepicker
     * @private
     */
    private toggleOpen() {
        this.setState({open: !this.state.open});
        if (this.props.range.enableRangeSelect) {
            if (this.isValidOldRangeDate() && !this.isValidActiveRangeDate()) {
                this.store.updateSelectedDatesWithOldSelectedDates();
            } else if (!this.isValidActiveRangeDate()) {
                this.store.clearSelectedDates();
            }
        }
    }

    /**
     * handle day click
     * @param date
     * @param modifiers
     * @private
     */
    private handleDayClick(date: Date, modifiers: DayModifiers) {
        if (!modifiers.disabled) {
            if (!this.getRange().enableRangeSelect) {
                return this.store.selectDate(date);
            } else {
                return this.store.updateRangeDate(date);
            }
        }
    }

    /**
     * returns whether the range date is valid
     * @private
     */
    private isValidActiveRangeDate(): boolean {
        return this.store.activeDepartureDate !== undefined && this.store.activeArrivalDate !== undefined && (this.store.activeDepartureDate < this.store.activeArrivalDate);
    }

    /**
     * returns whether the range date is valid
     * @private
     */
    private isValidOldRangeDate(): boolean {
        return this.store.oldDepartureDate !== undefined && this.store.oldArrivalDate !== undefined && (this.store.oldDepartureDate < this.store.oldArrivalDate);
    }

    /**
     * get range or default
     * @private
     */
    private getRange(): IRange | any {
        return this.props.range || {};
    }
}