import * as React from "react";
import {Component} from "react";
import {observer} from "mobx-react";
import {SearchEngineStore} from "../../SearchEngineStore";
import {ISearchItemProps} from "../index";

interface IMoreLessNumberRowProps extends ISearchItemProps {
    searchEngineStore: SearchEngineStore;
    open?: boolean;
    label?: string;
    subLabel?: string;
    title?: string;
    maxValue?: number;
    minValue?: number;
    classPrefix?: string;
}

/**
 * the more less button row for number
 */
@observer
export default class MoreLessNumberRow extends Component<IMoreLessNumberRowProps> {

    constructor(props: IMoreLessNumberRowProps) {
        super(props);
    }

    /**
     * the renderer
     */
    public render(): JSX.Element {
        const searchEngineStore = this.props.searchEngineStore;
        const name = this.props.name;
        const value = Number(searchEngineStore.values[name][0]);
        const prefix = this.props.classPrefix || "number-row";
        const canLess = value > (this.props.minValue || 0);
        const canMore = value < (this.props.maxValue || Number.POSITIVE_INFINITY);
        return (
            <>
                {this.props.open &&
                    <div className={prefix + "-container"}>
                        {this.props.title &&
                            <div className={prefix + "-title"}>{this.props.title}</div>
                        }
                        <div className={prefix + "-content"}>
                            {this.props.label &&
                                <div className={prefix + "-label"}>
                                    {this.props.label}
                                    {this.props.subLabel && <span className={prefix + "-sub-label"}>{this.props.subLabel}</span>}
                                </div>
                            }

                            <div className={prefix + "-count-wrapper"}>
                                <div
                                    className={prefix + "-less " + (canLess ? "" : "disabled")}
                                    onClick={() => {
                                        if (canLess) {
                                            searchEngineStore.setValue(name, String(value - 1));
                                        }
                                    }}
                                >-</div>
                                <div className={prefix + "-value"}>{value}</div>
                                <div
                                    className={prefix + "-more " + (canMore ? "" : "disabled")}
                                    onClick={() => {
                                        if (canMore) {
                                            searchEngineStore.setValue(name, String(value + 1));
                                        }
                                    }}
                                >+</div>
                            </div>
                        </div>
                    </div>
                }
                <input type={"hidden"} name={this.props.name} value={value}/>
            </>
        );
    }
}