import * as React from "react";
import {ReactElement} from "react";

export type ErrorMessageProps = {
    errorLabel: string;
    display?: boolean;
    addClass?: string;
};

/**
 * ErrorMessage
 * @param errorCode
 * @param addClass
 * @constructor
 */
const ErrorMessage = ({errorLabel, display = true, addClass = ""}: ErrorMessageProps): ReactElement => {
    const displayClass = display ? "" : "d-none";

    return (
        <div className={`cpt-error-message ${displayClass} ${addClass}`}>
            {errorLabel}
        </div>
    );
};

export {
    ErrorMessage
};
