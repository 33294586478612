/**
 * Return the value of cookie
 * @param name
 */
function readCookie(name: string): string {
    const keyName = name + "=";
    const cookieValues = document.cookie.split(";");
    for (const val of cookieValues) {
        let value = val;
        while (value.charAt(0) == " ") {
            value = value.substring(1, value.length);
        }
        if (value.indexOf(keyName) == 0) {
            return value.substring(keyName.length, value.length);
        }
    }
    return "";
}

/**
 * Create the expiry date for cookie
 * @param days
 */
function createCookieExpireDate(days: number): string {
    const expireDate = new Date();
    expireDate.setTime(expireDate.getTime() + (days * 24 * 60 * 60 * 1000));
    return `; expires=${expireDate.toUTCString()}`;
}

/**
 * Add a new cookie
 * @param name
 * @param value
 * @param expires
 * @param domain
 */
function addsCookie(name: string, value: string, expires: string, domain = "") {
    const domainValue = domain != "" ? `domain=${domain}` : "";
    document.cookie = `${name}=${value}${expires}; path=/; ${domainValue}`;
}

export {
    readCookie,
    createCookieExpireDate,
    addsCookie,
};