import {fetchEndpoint} from "../tools/fetch";

/**
 * Handler to get the customer ID
 */
function getCustomerID(successCallback: (customerId: string) => void) {
    $.ajax({
        type: "GET",
        url: "/account/customer/customer-id",
        dataType: "json",
        success: function (data) {
            if (data.code == "200" && data.data && data.data.id) {
                successCallback(data.data.id);
            }
        }
    });
}

/**
 * check if customer is logged in with low identification
 * @param successCallback callback function on success
 * @param errorCallback callback function on error
 */
function loggedCustomerAction(successCallback: () => void, errorCallback?: () => void) : void {
    const errorMessage = "Unable to retrieve customer login";
    const customerNotLoggedMessage = "Customer is not logged";
    const customerLoggedMessage = "Customer is logged (lowly logged test)";
    fetchEndpoint("/account/customer/lowly-logged-in", undefined, undefined, "GET",
        (response) => {
            if (response && response.code && response.code === "200") {
                if (response.data) {
                    console.log(customerLoggedMessage);
                    successCallback();
                } else {
                    console.log(customerNotLoggedMessage);
                    errorCallback?.();
                }
            } else {
                console.log(customerNotLoggedMessage);
                errorCallback?.();
            }
        },
        () => {
            console.error(errorMessage);
            errorCallback?.();
        }
    );
}

/**
 * Delete the user seen product
 * @param id ID of the product
 * @param successCallback callback
 */
function deleteUserSeenProduct(id: string, successCallback?: () => void) {
    // Exit when id is empty
    if (id === "") {
        return;
    }

    // Remove seen product
    const errorMessage = "Unable to remove a seen product.";
    fetchEndpoint("/account/customer/products/seen", undefined, id, "DELETE",
        (response) => {
            if (response && response.code && response.code === "200") {
                if (successCallback) {
                    successCallback();
                }
            } else {
                console.error(errorMessage);
            }
        },
        () => console.error(errorMessage)
    );
}

/**
 * add the user seen product
 * @param id ID of the product
 */
function addUserSeenProduct(id: string) {
    const errorMessage = "Unable to adds product in customer history (product id : " + id + ")";
    const okMessage = "Product " + id + " added in customer history";
    fetchEndpoint("/account/customer/products/seen", undefined, id, "POST",
        (response) => {
            if (response && response.code && response.code === "200") {
                if (response.data) {
                    console.log(okMessage);
                } else {
                    console.log(errorMessage);
                }
            } else {
                console.log(errorMessage);
            }
        },
        () => {
            console.log(errorMessage);
        });
}


export {
    getCustomerID,
    loggedCustomerAction,
    deleteUserSeenProduct,
    addUserSeenProduct
};
