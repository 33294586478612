import $$ from "jquery-selector-cache";
import {fetchEndpoint} from "../../_core/scripts/specific/tools/fetch";
import {loggedCustomerAction} from "../../_core/scripts/specific/customer/customer";
import {readCookie, addsCookie, createCookieExpireDate} from "../../_core/scripts/specific/cookie";

/**
 * Initialize the selected agency
 */
function initAgencySelect() {
    if (!$$(".select-agency").length) {
        return;
    }

    retrievesMainAgencyCustomer((data) => {
        $$(".select-agency").each(function() {
            if (data == $(this).data("agencyId")) {
                agencyToggleSelected($(this), true);
            }
        });
    }, () => {
        $$(".select-agency").each(function() {
            const currentAgencyCode = $(this).data("agencyCode");
            const agencyCodeInCookie = readCookie("agencyCode");
            if (currentAgencyCode == agencyCodeInCookie) {
                agencyToggleSelected($(this), true);
            }
        });
    });

    $$(".select-agency").each(function() {
        $(this).on("click", () => {
            const agencyCode = $(this).data("agencyCode"),
                agencyFav = $(this).data("agencyFav"),
                agencyId = $(this).data("agencyId"),
                agencyData = $(this).data("mapItem");
            let agencyPostalCode = "",
                agencyCity = "";
            if (agencyData.city != "") {
                agencyPostalCode = agencyData.city.substring(0, 5);
                agencyCity = agencyData.city.substring(6);
            }
            if (!$(this).hasClass("selected")) {
                addAgencyChoice(agencyCode, agencyId, agencyFav, agencyPostalCode, agencyData.name, agencyCity, agencyData.email2);
                $$(".select-agency").each(function() {
                    agencyToggleSelected($(this), false);
                });
                agencyToggleSelected($(this), true);
            }
        });
    });
}

/**
 * Initialize the agency choice
 */
function initAgencyChoice() {
    const agencyData = $("#agencyId");
    if (agencyData.data()) {
        const {
            agencyCode,
            agencyFav,
            agencyId,
            agencyEmail,
            agencyPostalCode,
            agencyName,
            agencyCity
        } = agencyData.data();
        addAgencyChoice(agencyCode, agencyId, agencyFav, agencyPostalCode, agencyName, agencyCity, agencyEmail);
        window.location.href = window.location.href.replace(/agency=\d+/, "");
    }
}

/**
 * Add the favorite agency
 * @param agencyCode
 */
function addsFavoriteAgency(agencyCode: string) {
    // Set main agency in customer account
    const errorMessage = `Unable to set a main favorite agency, agency code : ${agencyCode}`;

    fetchEndpoint("/account/customer/agency/main", undefined, agencyCode, "POST",
        (response) => {
            if (response && response.code && response.code === "200") {
                console.log("set main agency OK, agency code : " + agencyCode);
            } else {
                console.error(errorMessage);
            }
        },
        () => console.error(errorMessage)
    );
}

/**
 * Retrieves the agency's main client
 * @param onSuccess
 * @param onError
 */
function retrievesMainAgencyCustomer(onSuccess: (data) => void, onError: () => void) {
    const errorMessage = "Unable to retrieve main agency customer";
    fetchEndpoint("/account/customer/agency/main", undefined, undefined, "GET",
        (response) => {
            if (response && response.code && response.code === "200") {
                onSuccess(response.data.id);
            } else {
                console.error(errorMessage);
                onError();
            }
        },
        () => {
            console.error(errorMessage);
            onError();
        }
    );
}

/**
 * Add the agency choice
 * @param agencyCode
 * @param agencyId
 * @param favCity
 * @param agencyPostalCode
 * @param agencyName
 * @param agencyCity
 * @param agencyEmail2
 */
function addAgencyChoice(agencyCode: string, agencyId: string, favCity: string, agencyPostalCode: string, agencyName: string, agencyCity: string, agencyEmail2: string) {
    const expires = createCookieExpireDate(3650);
    const location = "." + window.location.hostname;
    // Add default cookies
    addsCookie("agencyCode", agencyCode, expires, location);
    addsCookie("agencyPostalCode", agencyPostalCode, expires, location);
    addsCookie("agencyName", agencyName, expires, location);
    addsCookie("agencyCity", agencyCity, expires, location);
    addsCookie("agencyEmail2", agencyEmail2, expires, location);

    // City favorite
    if (favCity && favCity != "") {
        addsCookie("fav2", favCity, expires, location);
    } else {
        addsCookie("fav2", "", "", location);
    }

    // Agency ID
    if (agencyId && agencyId != "") {
        addsCookie("agencyId", agencyId, expires, location);
    }

    // Check if customer is logged in with low identification
    loggedCustomerAction(() => {
        addsFavoriteAgency(agencyId);
    }, () => {
        /* do nothing*/
    });
}

/**
 * Toggle the selected agency
 * @param agencyButton
 * @param select
 */
function agencyToggleSelected(agencyButton: JQuery, select: boolean) {
    if (select) {
        agencyButton.addClass("selected");
        agencyButton.html(agencyButton.data("selectedText"));
    } else {
        agencyButton.removeClass("selected");
        agencyButton.html(agencyButton.data("toSelectText"));
    }
}

export {
    initAgencySelect,
    initAgencyChoice,
};