import $$ from "jquery-selector-cache";
import {toggleOverlay} from "../../components/overlay/overlay";

/**
 * Initialize the menu
 */
export function initSearchBar() {
    if (!$$(".cpt-search-bar").length) {
        return;
    }

    // close menu
    $$(".search-bar-close").on("click", function (){
        $$(".cpt-search-bar").removeClass("show");
        $$(".cpt-search-bar").removeClass("popin");
        toggleOverlay();
    });
}
