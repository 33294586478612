import * as React from "react";
import {Component} from "react";
import {inject, observer} from "mobx-react";
import {SearchEngineStore} from "../../SearchEngineStore";
import MoreLessNumberRow from "../Pax/MoreLessNumberRow";
import {ISearchEngineText} from "../../text";
import {ISearchItemProps} from "../index";
import {BirthDateFormat} from "../../../components/BirthDateInput";
import {Input} from "../../../components/Input";

interface IRoomText {
    inputText?: string;
    inputTextSeparator?: string;
    roomText?: string;
    adultText?: string;
    childText?: string;
    roomLabel?: string;
    roomTopTitle?: string;
    selectRoomTitle?: string;
    selectRoomLabel?: string;
    selectRoomSubLabel?: string;
    buttonText?: string;
    addRoomText?: string;
    adultLabel?: string;
    adultSubLabel?: string;
    childLabel?: string;
    childSubLabel?: string;
    childAgeTitle?: string;
    childAgeLabel?: string;
    childAgeOptionLabel?: Array<{ number: string }>;
}

interface IRoomSearchItemProps extends ISearchItemProps {
    searchEngineStore: SearchEngineStore;
    searchEngineText: ISearchEngineText;
    initialRoom?: number;
    maxRoom?: number;
    minRoom?: number;
    initialAdult?: number;
    minAdult?: number;
    maxAdult?: number;
    initialChild?: number;
    minChild?: number;
    maxChild?: number;
    childAges?: number[];
    addDeleteDiv?: boolean;
    hideChildrenAges?: boolean;
}

interface IRoomSearchItemState {
    open: boolean;
}

/**
 * the passenger search item
 */
@inject("searchEngineStore")
@inject("searchEngineText")
@observer
export default class RoomSearchItem extends Component<IRoomSearchItemProps, IRoomSearchItemState> {
    private readonly roomText: IRoomText;
    private ref: HTMLDivElement;

    /**
     * constructor
     * @param props
     */
    constructor(props: Readonly<IRoomSearchItemProps>) {
        super(props);
        this.handleCloseItem = this.handleCloseItem.bind(this);
        const name = props.name;
        this.roomText = props.searchEngineText ? props.searchEngineText.items ? (props.searchEngineText.items[name] || {}) : {} : {};
        this.state = {open: false};
        const searchEngineStore = props.searchEngineStore;
        const roomValues = searchEngineStore.values[name];
        if (!roomValues || roomValues.length <= 0) {
            const minRoom = props.minRoom || 1;
            const initialRoom = props.initialRoom || minRoom;
            searchEngineStore.setValue(name, String(Math.max(initialRoom, minRoom)));
        }
        this.reset = this.reset.bind(this);
        this.handleDeleteRoom = this.handleDeleteRoom.bind(this);
    }

    /**
     * component did mount
     */
    public componentDidMount() {
        this.ref.addEventListener("room-search-item:close", this.handleCloseItem);
    }

    /**
     * component will unmount
     */
    public componentWillUnmount() {
        this.ref.removeEventListener("room-search-item:close", this.handleCloseItem);
    }

    /**
     * Renders component
     */
    public render(): JSX.Element {
        return this.props.manageOwnLabel ? this.renderItemWithLabel() : this.renderItem();
    }

    /**
     *  Renders item with its label
     */
    private renderItemWithLabel(): JSX.Element {
        return (
            <>
                <div className="item-label-se">{this.props.label}</div>
                <div className="item-content-se">{this.renderItem()}</div>
            </>
        );
    }

    /**
     * Renders item
     */
    private renderItem(): JSX.Element {
        const searchEngineStore = this.props.searchEngineStore;
        const inputValue = this.getInputValue();
        return (
            <>
                <div
                    className={"elem-custom-input " + (this.state.open ? "open" : "close")}
                    onClick={() => this.setState({open: !this.state.open})}
                >
                    {inputValue || ""}
                </div>
                <div
                    ref={(div: HTMLDivElement) => this.ref = div}
                    className={"rooms-panel " + (this.state.open ? "open" : "close")}
                >
                    <div className={"header-content"}>
                        {this.roomText.roomTopTitle &&
                        <div className={"room-top-title"}>{this.roomText.roomTopTitle}</div>}
                        <div className={"clean"} onClick={this.reset}>
                            {this.props.searchEngineText && this.props.searchEngineText.common && this.props.searchEngineText.common.cleanTitle &&
                            <div className={"clean-title"}>{this.props.searchEngineText.common.cleanTitle}</div>
                            }
                            <div className={"clean-icon"}/>
                        </div>
                    </div>
                    <div className={"select-room"}>
                        <MoreLessNumberRow
                            open={this.state.open}
                            searchEngineStore={searchEngineStore}
                            title={this.roomText.selectRoomTitle}
                            name={this.props.name}
                            label={this.roomText.selectRoomLabel}
                            subLabel={this.roomText.selectRoomSubLabel}
                            minValue={this.props.minRoom || 1}
                            maxValue={this.props.maxRoom}
                            classPrefix={"select-room"}
                        />
                    </div>
                    <div className={"rooms-container"}>
                        {this.createRoom()}
                    </div>
                    {this.state.open && this.roomText.buttonText &&
                        <div className={"button-container"}>
                            <button onClick={() => this.setState({open: false})}>{this.roomText.buttonText}</button>
                        </div>
                    }
                    {this.state.open && this.roomText.addRoomText &&
                        <div className={"button-add-container"}>
                            <button className={"button-add-room"}>{this.roomText.addRoomText}</button>
                        </div>
                    }
                </div>
            </>
        );
    }

    /**
     * get the input value to show
     */
    private getInputValue() {
        const searchEngineStore = this.props.searchEngineStore;
        const roomNb = Number(searchEngineStore.values[this.props.name][0]);
        let totalAdultNb = 0;
        let totalChildrenNb = 0;
        let adultNb = 0;
        let childNb = 0;
        for (let i = 0; i < roomNb; i++) {
            const adultName = this.props.name + "_adult_" + String(i);
            const childName = this.props.name + "_child_" + String(i);
            if (!searchEngineStore.values[adultName]) {
                const minAdult = this.props.minAdult || 0;
                const initialAdult = this.props.initialAdult || minAdult;
                searchEngineStore.setValue(adultName, String(Math.max(initialAdult, minAdult)));
            }
            if (!searchEngineStore.values[childName]) {
                const minChild = this.props.minChild || 0;
                const initialChild = this.props.initialChild || minChild;
                searchEngineStore.setValue(childName, String(Math.max(initialChild, minChild)));
            }
            adultNb = Number(searchEngineStore.values[adultName][0]);
            childNb = Number(searchEngineStore.values[childName][0]);
            totalAdultNb += adultNb;
            totalChildrenNb += childNb;
            if (childNb > 0 && this.props.childAges && this.props.childAges.length > 0) {
                for (let j = 0; j < childNb; j++) {
                    const childAgeName = this.props.name + "_child_" + String(i) + "_age_" + String(j);
                    if (!searchEngineStore.values[childAgeName]) {
                        const minAge = this.props.childAges[0] || 0;
                        searchEngineStore.setValue(childAgeName, String(minAge));
                    }
                }
            }
        }
        const inputText = this.roomText.inputText || "{nb} {txt}";
        const inputTextSeparator = this.roomText.inputTextSeparator || ", ";
        let inputValue = inputText.replace("{nb}", String(roomNb)).replace("{txt}", this.roomText.roomText || "room(s)");
        if (totalAdultNb > 0) {
            inputValue += inputTextSeparator;
            inputValue += inputText.replace("{nb}", String(totalAdultNb)).replace("{txt}", this.roomText.adultText || "adult(s)");
        }
        if (totalChildrenNb > 0) {
            inputValue += inputTextSeparator;
            inputValue += inputText.replace("{nb}", String(totalChildrenNb)).replace("{txt}", this.roomText.childText || "child(ren)");
        }
        return inputValue;
    }

    /**
     * create rooms
     */
    private createRoom() {
        const searchEngineStore = this.props.searchEngineStore;
        const rooms = [];
        const roomLabel = this.roomText.roomLabel || "";
        const roomNb = Number(searchEngineStore.values[this.props.name][0]);
        for (let i = 0; i < roomNb; i++) {
            const adultName = this.props.name + "_adult_" + String(i);
            const childName = this.props.name + "_child_" + String(i);
            const childNb = Number(searchEngineStore.values[childName][0]);
            const isLastRoom = (i === roomNb - 1) && (i !== 0);
            rooms.push(
                <div key={"roomNumber_" + i} className={"room"}>
                    {this.state.open &&
                        <>
                            <div className="room-title">{roomLabel.replace("{number}", String(i + 1))}</div>
                            {isLastRoom && this.props.addDeleteDiv &&
                                <div className="delete-room" onClick={this.handleDeleteRoom}/>
                            }
                        </>
                    }
                    <MoreLessNumberRow
                        open={this.state.open}
                        searchEngineStore={searchEngineStore}
                        name={adultName}
                        label={this.roomText.adultLabel}
                        subLabel={this.roomText.adultSubLabel}
                        minValue={(this.props.minAdult === 0) ? 0 : (this.props.minAdult || 1)}
                        maxValue={this.props.maxAdult}
                        classPrefix={"room"}
                    />
                    <MoreLessNumberRow
                        open={this.state.open}
                        searchEngineStore={searchEngineStore}
                        name={childName}
                        label={this.roomText.childLabel}
                        subLabel={this.roomText.childSubLabel}
                        minValue={this.props.minChild || 0}
                        maxValue={this.props.maxChild}
                        classPrefix={"room"}
                    />
                    {!this.props.hideChildrenAges && childNb > 0 && this.props.childAges && this.props.childAges.length > 0 &&
                    <div className={"child-age-panel"}>
                        {this.roomText.childAgeTitle && this.state.open &&
                        <div className={"child-age-title"}>{this.roomText.childAgeTitle}</div>
                        }
                        {this.renderAgeContents(childNb, i)}
                    </div>
                    }
                </div>
            );
        }
        return <>{rooms}</>;
    }

    /**
     * delete the last room
     */
    private handleDeleteRoom() {
        const values = this.props.searchEngineStore.values;
        let value = "";
        if (values) {
            value = values[this.props.name][0];
        }
        const valueInt = Number(value);
        if (valueInt > 0) {
            this.props.searchEngineStore.setValue(this.props.name, String(valueInt - 1));
        }
    }

    /**
     * render age contents
     * @param childNb
     * @param roomNumber
     */
    private renderAgeContents(childNb: number, roomNumber: number) {
        const getFormatProps = (birthDateFormat?: BirthDateFormat) => {
            switch (birthDateFormat) {
            case BirthDateFormat.SLASH:
                return {mask: "99/99/9999", pattern: "[0-9]{2}/[0-9]{2}/[0-9]{4}"};
            case BirthDateFormat.DASH:
                return {mask: "99-99-9999", pattern: "[0-9]{2}-[0-9]{2}-[0-9]{4}"};
            case BirthDateFormat.INVERTED_SLASH:
                return {mask: "9999/99/99", pattern: "[0-9]{4}/[0-9]{2}/[0-9]{2}"};
            case BirthDateFormat.INVERTED_DASH:
                return {mask: "9999-99-99", pattern: "[0-9]{4}-[0-9]{2}-[0-9]{2}"};
            }
        };
        const contents = [];
        for (let i = 0; i < childNb; i++) {
            const birthDateNamePrefix = "room_child_" + String(roomNumber) + "_";
            const inputKey = "birthdate_" + i;
            contents.push(
                <div key={"age-content_" + i} className={"child-age-content"}>
                    {this.roomText.childAgeLabel && this.state.open &&
                        <div className={"child-age-label"}>
                            {this.roomText.childAgeLabel.replace("{number}", String(i + 1))}
                        </div>
                    }
                    {
                        <Input className={"child-age-select"}
                            id={birthDateNamePrefix + inputKey}
                            name={birthDateNamePrefix + inputKey}
                            key={birthDateNamePrefix + inputKey}
                            type={"text"}
                            {...getFormatProps(BirthDateFormat.SLASH)}
                        />
                    }
                </div>
            );
        }
        return contents;
    }

    /**
     * handle event close on passenger item
     */
    private handleCloseItem() {
        this.setState({open: false});
    }

    /**
     * clean all data to initial value
     */
    private reset() {
        const searchEngineStore = this.props.searchEngineStore;
        const name = this.props.name;
        const roomNb = Number(searchEngineStore.values[name][0]);
        for (let i = 0; i < roomNb; i++) {
            const adultName = name + "_adult_" + String(i);
            const childName = name + "_child_" + String(i);
            const childNb = Number(searchEngineStore.values[childName][0]);
            const minAdult = this.props.minAdult || 0;
            const initialAdult = this.props.initialAdult || minAdult;
            searchEngineStore.setValue(adultName, String(Math.max(initialAdult, minAdult)));
            const minChild = this.props.minChild || 0;
            const initialChild = this.props.initialChild || minChild;
            searchEngineStore.setValue(childName, String(Math.max(initialChild, minChild)));
            if (childNb > 0 && this.props.childAges && this.props.childAges.length > 0) {
                for (let j = 0; j < childNb; j++) {
                    const childAgeName = name + "_child_" + String(i) + "_age_" + String(j);
                    const minAge = this.props.childAges[0] || 0;
                    searchEngineStore.setValue(childAgeName, String(minAge));
                }
            }
        }
        const minRoom = this.props.minRoom || 1;
        const initialRoom = this.props.initialRoom || minRoom;
        searchEngineStore.setValue(name, String(Math.max(initialRoom, minRoom)));
    }
}
