import $$ from "jquery-selector-cache";
import {observesLazyImage} from "../../_core/scripts/specific/layout/lazyload";
import {diaporamaConfig} from "../diaporama/diaporamaConfig";
/**
 * Initialize load fav list
 */
export function initLoadFavList() {
    if (!$$(".cpt-bloc-fav").length) {
        return;
    }

    $$(".cpt-bloc-fav").each((i: number, elem: HTMLElement) => {
        const url = $(elem).data("url");
        $.ajax({
            method: "GET",
            url: url,
            success(response: any) {
                const favModule = $(response).find(".cpt-fav-module");
                if (favModule.length) {
                    const display = favModule.data("display");
                    $$(elem).html(favModule);
                    $$(elem).find(".cpt-diaporama").slick(diaporamaConfig["diaporamaConfig"]);
                    observesLazyImage();

                    // When is slider mode
                    if (display === "slider") {
                        $$(elem).find(".fav-list").slick({
                            row: 0,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: false,
                            dots: true,
                        });
                        $$(elem).addClass("slider-mode");
                    }
                } else {
                    $(elem).remove();
                }
            },
            error(){
                console.log("error when load ", url);
                $(elem).remove();
            }
        });
    });
}