import $$ from "jquery-selector-cache";
import {isMobileDevice} from "../../_core/scripts/specific/layout/device";
import {dispatchEvent} from "../../_core/scripts/specific/tools/events";
import {HolidaysDates, initializeHolidays} from "../../_core/scripts/specific/holidays";
import {initSearchEngineWithHolidays} from "../../_core/scripts/plugins/orxapi.widgets.framework/src/searchEngine/searchEngine";
import {getLoaderTemplate} from "../loadingPopin/loadingPopin";
import {createHotelRequestBody, callDeeplinkV2, createDPRequestBody, buildHotelRoomDeeplinkRequest} from "../../_core/scripts/tools/Deeplink";
import {
    DeeplinkRequestData,
    Departure,
    Destination,
    DynamicPackageAvail,
    Room,
    DeeplinkRooms,
    Transport,
    HotelDeeplinkLocationRequest,
    HotelDeeplinkAvailRequest,
    DeeplinkRoom,
    HotelDeeplinkBookingRequest
} from "../../_core/scripts/specific/typings/deeplink/deeplink";
import {readCookie} from "../../_core/scripts/specific/cookie";
import moment = require("moment");

/**
 * initialize the search engine
 * @param complementaryText
 * @param callback
 */
export function initializeSearchEngine(complementaryText?: { [code: string]: orxapi.ISpecificSearchEngine }, callback?: () => void) {
    const searchEngine = $("#searchEngine");

    if (!searchEngine.length) {
        return;
    }

    const dataUrl = searchEngine.data("url");
    let query = "isMobile=" + isMobileDevice();
    if (window.location.hash) {
        query += "&type=" + window.location.hash.substr(1);
    }

    const text = orxapi.model.searchEngineText.text;
    text.searchEngines = complementaryText;
    initializeHolidays("/api/holidays", function(holidays: HolidaysDates) {
        initSearchEngineWithHolidays(searchEngine[0], dataUrl + "?" + query, text, holidays, callback);
    });

    // Event
    $$("body").on("click.closeItemOfSearchEngine", handleClickOnBodyToCloseItem);
    searchEngine.on("click.displayLoader", ".search-engine-submit", handleClickSubmitForm);
}

/**
 * Handler of submit form for display the loader
 * @param evt
 */
function handleClickSubmitForm(evt: JQuery.SubmitEvent) {
    $("#searchEngine").append(getLoaderTemplate());
    $$("body").addClass("no-scroll");
    const searchEngine = $("#searchEngine");
    const enableDeeplinkV2 = searchEngine.data("enableDeeplinkV2");
    const pageNode = searchEngine.data("pageNode");
    if (enableDeeplinkV2 && (pageNode === "mob2_recherche-hotel" || pageNode === "mob2_recherche-dp")) {
        evt.preventDefault();
        const form: JQuery<HTMLFormElement> = $(evt.target).parents("form");
        const formData = new FormData(form[0]);
        const serializeArray: JQuery.NameValuePair[] = form.serializeArray();
        const dynamicDepartureDate = serializeArray.filter((nameValue: JQuery.NameValuePair) => nameValue.name === "departureDate");
        const returnDate = serializeArray.filter((nameValue: JQuery.NameValuePair) => nameValue.name === "returnDate");
        const departureDate = dynamicDepartureDate[0].value;
        const checkinDateArray = departureDate.split("/");
        const checkinDateFormatted = `${checkinDateArray[2]}-${checkinDateArray[1]}-${checkinDateArray[0]}`;
        const checkOutDateArray = returnDate[0].value.split("/");
        const checkOutDateFormatted = `${checkOutDateArray[2]}-${checkOutDateArray[1]}-${checkOutDateArray[0]}`;

        const roomSets = serializeArray.filter(item => item.name.startsWith("room_adult_"));
        const roomSetsNbRooms = roomSets ? roomSets.length : 0;
        const searchEngineType = formData.get("type");

        let request: any = null;
        let deeplinkUrl = "";
        const extra = {};
        extra["homeUrl"] = window.location.origin + "/";
        if (searchEngineType === "searchEngineHotel") {
            //hotel
            const destinationCode = formData.get("hotelDestination.code") as string;
            const destinationLabel = formData.get("hotelDestination.label") as string;
            const destinationCodificationType = formData.get("hotelDestination.codificationType") as string;
            const destinationLocationType = formData.get("hotelDestination.locationType") as string;

            const hotelDestination: HotelDeeplinkLocationRequest = {
                codificationType: destinationCodificationType,
                type: destinationLocationType,
                code: destinationCode,
                label: destinationLabel,
            };

            const hotelAvailRequest: HotelDeeplinkAvailRequest = {
                checkinDate: checkinDateFormatted,
                checkoutDate: checkOutDateFormatted
            };
            const hotelRoom: DeeplinkRoom[] = buildHotelRoomDeeplinkRequest(roomSetsNbRooms, formData);
            const hotelBookingRequest: HotelDeeplinkBookingRequest = {
                avail: hotelAvailRequest,
                quantity: {
                    rooms: hotelRoom,
                    type: "rooms"
                }
            };

            request = createHotelRequestBody(readCookie("agencyCode"), extra, hotelBookingRequest, hotelDestination);
            deeplinkUrl = "/deeplink/v2/hotel";

        } else if (searchEngineType == "search-engine-dp") {
            // DP
            const dynamicPackageAvail: DynamicPackageAvail = {
                departureDate: checkinDateFormatted,
                returnDate: checkOutDateFormatted,
                departureCityCode: formData.get("flightDeparture.code") as string,
                departureCityLabel: formData.get("flightDeparture.label") as string,
                destinationCityCode: formData.get("hotelDestination.code") as string,
                destinationCityLabel: formData.get("hotelDestination.label") as string,
            };

            const paxRooms: Room[] = [];

            for (let i = 0; i < roomSetsNbRooms; i++) {
                const childAgesArray = [];
                const nbAdults = formData.get("room_adult_" + i);
                const nbChildren = formData.get("room_child_" + i);
                if (nbChildren) {
                    for (let j = 0; j < Number(nbChildren); j++) {
                        const childAge = $("#room_child_" + i + "_birthdate_" + j).val() as string;
                        if (childAge) {
                            childAgesArray.push(moment(childAge, "DD/MM/YYYY").format("YYYY-MM-DD"));
                        }
                    }
                }
                const room: Room = {
                    nbAdults: Number(nbAdults),
                    nbChildren: nbChildren ? Number(nbChildren) : 0,
                    childBirthDates: childAgesArray
                };
                paxRooms.push(room);
            }

            const rooms: DeeplinkRooms = {
                rooms: paxRooms,
                type: "rooms"
            };

            const deeplinkRequestData: DeeplinkRequestData = {
                avail: dynamicPackageAvail,
                quantity: rooms
            };

            const dpDeparture: Departure = {
                code: formData.get("flightDeparture.code") as string,
                label: formData.get("flightDeparture.label") as string,
            };

            const dpDestination: Destination = {
                code: formData.get("hotelDestination.code") as string,
                label: formData.get("hotelDestination.label") as string,
            };

            const dpTransport: Transport = {
                transportClass: "",
                roundTrip: true
            };

            request = createDPRequestBody(deeplinkRequestData, dpDeparture, dpDestination, dpTransport, extra, readCookie("agencyCode"));
            deeplinkUrl = "/deeplink/v2/dynamic-package";
        }

        if (request && deeplinkUrl) {
            callDeeplinkV2(request, deeplinkUrl);
        }
    }
}

/**
 * handle click on body to close date opened date panel
 * @param evt
 */
function handleClickOnBodyToCloseItem(evt: JQuery.TriggeredEvent) {
    const target = $(evt.target);

    if ((
        target.hasClass("open-datepicker") &&
        !target.parents(".date-search-item").hasClass("open")
    ) || target.parents(".date-search-item").length === 0) {
        const dateSearchItem = $(".date-search-item");
        if (dateSearchItem.length) {
            dateSearchItem.each((i: number, e: HTMLElement) => {
                dispatchEvent(e, "dateSearchItem:close");
            });
        }
    }

    if (target.parents(".panel-item.open").length === 0) {
        $(".panel-item").each((i: number, e: HTMLElement) => {
            dispatchEvent(e, "panel-search-item:close");
        });
    }

    if (target.parents(".passenger").length === 0) {
        $(".passengers-panel").each((i: number, e: HTMLElement) => {
            dispatchEvent(e, "passenger-search-item:close");
        });
    }

    if (target.parents(".room").length === 0) {
        $(".rooms-panel").each((i: number, e: HTMLElement) => {
            dispatchEvent(e, "room-search-item:close");
        });
    }
}
