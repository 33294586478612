import {
    BPParam,
    DeeplinkBookingRequest,
    DeeplinkRequestData,
    DeeplinkResponse,
    Departure,
    Destination,
    Transport,
    HotelDeeplinkBookingRequest,
    HotelDeeplinkLocationRequest,
    HotelDeeplinkRequest,
    DeeplinkRoom, DeeplinkPerson
} from "../../specific/typings/deeplink/deeplink";

/**
 * create Hotel Booking request
 */
export const createHotelRequestBody = (agencyId: string, extraParam: any,
                                       hotelBookingRequest: HotelDeeplinkBookingRequest, hotelDestination: HotelDeeplinkLocationRequest): HotelDeeplinkRequest => {
    return {
        url: window.location.href,
        deviceType: "MOBILE",
        agencyId: agencyId,
        bookingRequest: hotelBookingRequest,
        destination: hotelDestination,
        extra: extraParam
    };
};

/**
 * create Package Booking request
 */
export const createPackageRequestBody = (departureDate: string,
                                         deptCity: string, dayNbr: number,
                                         nightNbr: number, productId: string, persons: DeeplinkPerson[], agencyCode: string, extraParam: any ): DeeplinkBookingRequest => {
    return {
        deviceType: "MOBILE",
        url: window.location.href,
        agencyId: agencyCode,
        bookingRequest: {
            productCatalogCode: Number(productId),
            avail: {
                departureDate: departureDate,
                daysNumber: dayNbr,
                nightsNumber: nightNbr,
                departureCityCode: deptCity,
            },
            quantity: {
                persons,
                type: "persons"
            }
        },
        extra: extraParam
    };
};


/**
 * create DP Booking request
 */
export const createDPRequestBody = (deeplinkRequestData: DeeplinkRequestData,
                                    dpDeparture: Departure, dpDestination: Destination, dpTransport: Transport, extraParam: any, agencyId: string): DeeplinkBookingRequest => {
    return {
        url: window.location.href,
        deviceType: "MOBILE",
        agencyId: agencyId,
        bookingRequest: deeplinkRequestData,
        departure: dpDeparture,
        destination: dpDestination,
        transport: dpTransport,
        extra: extraParam
    };
};

/**
 * build hotel request
 * @param roomSetsNbRooms
 * @param formData
 */
export function buildHotelRoomDeeplinkRequest(roomSetsNbRooms: number, formData: FormData): DeeplinkRoom[] {
    const hotelRoom: DeeplinkRoom[] = [];

    for (let i = 0; i < roomSetsNbRooms; i++) {
        const childAgesArray = [];
        const nbAdults = formData.get("room_adult_" + i);
        const nbChildren = formData.get("room_child_" + i);
        if (nbChildren) {
            for (let j = 0; j < Number(nbChildren); j++) {
                const childAge = String(formData.get("room_child_" + i + "_birthdate_" + j)).split("/");
                if (childAge) {
                    childAgesArray.push(`${childAge[2]}-${childAge[1]}-${childAge[0]}`);
                }
            }
        }

        const room: DeeplinkRoom = {
            nbAdults: Number(nbAdults),
            nbChildren: nbChildren ? Number(nbChildren) : 0,
            childBirthDates: childAgesArray,
            disabledCategoryCode: true
        };
        hotelRoom.push(room);

    }

    return hotelRoom;

}


/**
 * is go return
 * @param flightType
 */
function isGoReturn(flightType: string): boolean {
    return (flightType && "goReturn" === flightType) ? true : false;
}

/**
 * fetch deeplink v2
 * @param data
 * @param url
 */
export function callDeeplinkV2(data: any, url: string) {
    void $.ajax(url, {
        method: "POST",
        data: JSON.stringify(data),
        contentType: "application/json",
        dataType: "json",
        statusCode: {
            401: () => {
                console.log("Unauthorized, redirecting...");
                window.location.reload();
            }
        }
    }).done((response: DeeplinkResponse) => {
        submitResa(response.bpUrl, response.bpParams, response.bpRequestMethod);
    });
}

/**
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the parameters to add to the url
 * @param method
 */
export function submitResa(path: string, params: BPParam[], method: string) {

    // The rest of this code assumes you are not using a library.
    // It can be made less wordy if you use one.
    const form = document.createElement("form");
    form.method = method;
    form.action = path;

    for (const item of params) {

        const hiddenField = document.createElement("input");
        hiddenField.type = "hidden";
        hiddenField.name = item.name;
        hiddenField.value = String(item.value);

        form.appendChild(hiddenField);
    }
    document.body.appendChild(form);
    form.submit();
}