
/**
 * Initialize tracking
 */
export function initTracking() {
    updateUserConnectionInfo();
}

/**
 * Update user ConnectionInfo
 */
function updateUserConnectionInfo() {
    $.ajax({
        type: "GET",
        url: "/account/updateConnectionInfo"
    });
}