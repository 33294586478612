
/**
 * Return the template of loading popin
 */
function getLoaderTemplate() {
    if (navigator.platform === "iPad" || navigator.platform === "iPhone" || navigator.platform === "iPod") {
        return `
          <img class="cpt-loading-popin" src="/leclerc-mobile/_core/css_img/loading_page_light_ios.gif" alt="">
          <div class="ios-txt">Veuillez patienter, nous affichons votre devis</div>
        `;
    } else {
        return `
          <img class="cpt-loading-popin" src="/leclerc-mobile/_core/css_img/loading_page_light.gif" alt="">
        `;
    }
}

export {
    getLoaderTemplate,
};
