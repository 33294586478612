import $$ from "jquery-selector-cache";

interface DisplayOverlayOptions {
    hiddenClass?: "d-none" | string;
    overlayBodyClass?: "is-overlay" | string;
    noScrollClass?: "no-scroll" | string;
}

/**
 * Toggle display the overlay component
 * @param options {DisplayOverlayOptions}
 */
function toggleOverlay(options?: DisplayOverlayOptions) {
    const isOpen = $$("body").hasClass(options?.overlayBodyClass || "is-overlay");
    displayOverlay(!isOpen, options);
}

/**
 * Show the overlay component
 * @param options {DisplayOverlayOptions}
 */
function showOverlay(options?: DisplayOverlayOptions) {
    displayOverlay(true, options);
}

/**
 * Hide the overlay component
 * @param options {DisplayOverlayOptions}
 */
function hideOverlay(options?: DisplayOverlayOptions) {
    displayOverlay(false, options);
}

/**
 * Display the overlay component
 * @param open {boolean} The value that allows to open the overlay
 * @param hiddenClass {string} The name of the class hidden the overlay
 * @param overlayBodyClass {string} The name of the class added into body
 * @param noScrollClass {string} The name of the class added into body
 */
function displayOverlay(
    open: boolean,
    {
        hiddenClass = "d-none",
        overlayBodyClass = "is-overlay",
        noScrollClass = "no-scroll"
    }: DisplayOverlayOptions = {}
) {
    $$(".cpt-overlay").toggleClass(hiddenClass, !open);
    $$("body").toggleClass(`${overlayBodyClass} ${noScrollClass}`, open);
}

export {
    toggleOverlay,
    showOverlay,
    hideOverlay
};