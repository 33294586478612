/**
 * check if value stare with
 * @param value
 * @param startWith
 */
export function startsWith(value: string, startWith: string): boolean {
    if (!value.startsWith) {
        return value.indexOf(startWith) === 0;
    } else {
        return value.startsWith(startWith);
    }
}