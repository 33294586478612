import React from "react";

export interface WrapDivProps extends React.HTMLAttributes<any> {
    test?: boolean;
    children?: any;
}

/**
 * Wrap div component
 */
const WrapDiv = ({test = true, children = "", ...restProps}: WrapDivProps): JSX.Element => {
    return test ? <div {...restProps}>{children}</div> : <>{children}</>;
};

export {
    WrapDiv
};