/**
 * Fetch data from endpoint
 * TODO refactor signature by adding an object for optional parameters
 *
 * @param endpoint endpoint to fetch
 * @param header optional request header
 * @param body optional request body
 * @param method optional http method (GET, POST, PUT, ...)
 * @param onSuccess callback function on success
 * @param onError callback function on error
 */
export function fetchEndpoint(endpoint: string, header?, body?, method?: string, onSuccess?: (data) => void, onError?: () => void) {
    if (endpoint) {
        header = header || {};
        header["X-Requested-With"] = "XMLHttpRequest";
        fetch(endpoint, {
            method,
            headers: header,
            body: body,
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                onSuccess(data);
            })
            .catch((reason: any) => {
                console.log("Fetch error :", reason);
                if (onError) {
                    onError();
                }
            });
    }
}

/**
 * Post to the provided URL with the specified parameters.
 * This will change the window location.
 * @param path path of URL
 * @param parameters parameters
 * @param method
 */
export function submitForm(path, parameters, method= "post") {
    const form = document.createElement("form");
    form.method = method;
    form.action = path;

    for (const key in parameters) {
        if (Object.prototype.hasOwnProperty.call(parameters, key)) {
            const hiddenField = document.createElement("input");
            hiddenField.type = "hidden";
            hiddenField.name = key;
            hiddenField.value = parameters[key];
            form.appendChild(hiddenField);
        }
    }
    document.body.appendChild(form);
    form.submit();
}
