import $$ from "jquery-selector-cache";
import {initMenu} from "../menu/menu";
import {initSearchBar} from "../searchBar/searchBar";
import {initMenuAccount} from "../menuAccount/menuAccount";
import {toggleOverlay} from "../overlay/overlay";
import {fetchEndpoint} from "../../_core/scripts/specific/tools/fetch";

/**
 * Initialize the header
 */
export function initHeader(isPageWithAccountMenu: boolean) {
    if (!$$(".cpt-header").length) {
        return;
    }

    initMenu();
    initSearchBar();
    initFavButton();
    if (isPageWithAccountMenu) {
        initMenuAccount();
    } else {
        initAccountButton();
    }

    const menu = $$(".cpt-menu");
    const searchBar = $$(".cpt-search-bar");
    const popinResa = $$(".cpt-popin-resa");


    // Open menu
    $$(".menu").on("click", function (){

        sortMenuIsOpen ();
        filterMenuIsOpen ();
        //accountMenuIsOpen ();

        if(searchBar.hasClass("show")) {
            searchBar.removeClass("show");
            toggleOverlay();
        }
        if(popinResa.hasClass("show")) {
            popinResa.removeClass("show");
            toggleOverlay();
        }
        $$(".cpt-menu").addClass("show");
        toggleOverlay();

        menuArrows();
    });

    // Open search bar
    $$(".search").on("click", function (){

        sortMenuIsOpen ();
        filterMenuIsOpen ();
        accountMenuIsOpen();

        if(menu.hasClass("show")) {
            menu.removeClass("show");
            toggleOverlay();
        }
        if(popinResa.hasClass("show")) {
            popinResa.removeClass("show");
            toggleOverlay();
        }
        $$(".cpt-search-bar").addClass("show");
        toggleOverlay();
    });

    initCustomHistory();

    // Back button
    if(document.referrer.indexOf(window.location.host) == -1){
        $$(".back").hide();
    }
    $$(".back").on("click", function() {
        const historyStack = JSON.parse(localStorage.getItem("customHistory")) || [];
        historyStack.pop();

        if (historyStack.length > 0) {
            const previousPage = historyStack.pop();
            localStorage.setItem("customHistory", JSON.stringify(historyStack));
            window.location.href = previousPage;
        } else {
            window.history.back();
        }
    });

    // Display header according to scroll direction
    let lastScrollTop = 0;
    let st = 0;
    const minScroll = 100; // Min value before hiding Header
    $$(window).scroll(function(){
        st = $$(this).scrollTop();
        if (st > lastScrollTop && st > minScroll){
            // hide header
            $$(".cpt-header").addClass("scroll-top").removeClass("scroll-bottom");
        } else {
            // display header
            $$(".cpt-header").addClass("scroll-bottom").removeClass("scroll-top");
        }
        lastScrollTop = st;
    });
}

/**
 * Initialize the local history stack
 */
export function initCustomHistory() {
    const historyStack = JSON.parse(localStorage.getItem("customHistory")) || [];
    const currentUrl = window.location.pathname + window.location.search;

    if (historyStack.length === 0 || historyStack[historyStack.length - 1] !== currentUrl) {
        historyStack.push(currentUrl);
    }

    if (historyStack.length > 10) {
        historyStack.shift();
    }
    localStorage.setItem("customHistory", JSON.stringify(historyStack));
}

/**
 * Initialize the account button
 */
export function initAccountButton() {
    if (!$$(".cpt-header").length) {
        return;
    }

    const headerHtml = $$(".cpt-header");
    headerHtml.on("click", ".trigger-account-menu", () => {
        window.location.href = "/account/landing";
    });
}
function sortMenuIsOpen () {
    // checking if sort-menu is open or not
    const overlay = $$(".cpt-overlay");
    const sortMenu = $$(".sort-menu");
    const isOpen = sortMenu.hasClass("show");
    if(isOpen) {
        sortMenu.toggleClass("show", !isOpen);
        toggleOverlay();
        overlay.toggleClass("menu-overlay", isOpen);
        overlay.toggleClass("filter-overlay", !isOpen);
    }
}

function accountMenuIsOpen () {
    // checking if account Menu is open or not
    const body = $$("body");
    const overlay = $$(".cpt-overlay");
    const isOpen = body.hasClass("has-menu-account-open");
    if(isOpen) {
        $$(".cpt-menu-account").addClass("d-none");
        body.removeClass("has-menu-account-open");
        body.off("click.accountMenu");
        toggleOverlay(isOpen);
        overlay.toggleClass("menu-overlay", isOpen);
        overlay.toggleClass("filter-overlay", !isOpen);
    }
}
function filterMenuIsOpen () {
    // checking if sort-menu is open or not
    const overlay = $$(".cpt-overlay");
    const filterMenu = $$(".cpt-filter-menu");
    const isOpen = filterMenu.hasClass("show");
    if(isOpen) {
        filterMenu.toggleClass("show", !isOpen);
        toggleOverlay();
        overlay.toggleClass("menu-overlay", isOpen);
        overlay.toggleClass("filter-overlay", !isOpen);
    }
}

export function menuArrows() {
    // manage top and bottom button inside menu
    const headerHeight = 60;
    const padding = 14;
    const containerHeight = window.innerHeight - headerHeight;
    const contentHeight = $$(".menu-content").height();
    const scrollHeight = (contentHeight + padding) - containerHeight;
    //console.log(containerHeight + "---" + contentHeight);

    if (containerHeight < contentHeight) {
        $$(".menu-arrow-bottom").addClass("show");
    }

    // Scroll up and down
    $$(".menu-arrow-bottom").on("click", function () {
        $$(".menu-content").animate({bottom: scrollHeight + "px"});
        $$(this).removeClass("show");
        $$(".menu-arrow-top").addClass("show");

    });

    $$(".menu-arrow-top").on("click", function () {
        $$(".menu-content").animate({bottom: 0});
        $$(this).removeClass("show");
        $$(".menu-arrow-bottom").addClass("show");

    });
}

export function initFavButton() {
    if (!$$(".cpt-header").find(".heart").length) {
        return;
    }
    const headerHtml = $$(".cpt-header");
    headerHtml.on("click", ".heart", () => {
        window.location.href = "/account/favorites";
    });
    const isConnected = JSON.parse(headerHtml.find(".fav-div").data("url"));
    if(isConnected){
        fetchEndpoint("/account/favorites/number", undefined, undefined, "GET",
            (response) => {
                const num = Number(response);
                if (!isNaN(num) && Number.isInteger(num) && num !== 0) {
                    $$(".fav-number").text(num).removeClass("d-none");
                } else {
                    $$(".fav-number").text("").addClass("d-none");
                }
            }
        );
    }

}