import * as React from "react";
import {ChangeEvent, Component} from "react";
import {inject, observer} from "mobx-react";
import {SearchEngineStore} from "../../SearchEngineStore";
import {ISearchItemProps} from "../index";

export interface ICheckOption {
    code: string;
    label: string;
    disabled?: boolean;
}

export interface ICheckSearchItemProps extends ISearchItemProps {
    options?: ICheckOption[];
    showMoreItemsLabel?: string;
    hideMoreItemsLabel?: string;
    moreItemsLimit?: number;
    searchEngineStore: SearchEngineStore;
    withoutCheckbox?: boolean;
    singleChoice?: boolean;
    onChange?: (label: string) => void;
}

interface ICheckSearchItemState {
    moreItemsDisplayed: boolean;
}

interface ICheckUpdateData {
    [name: string]: {
        options: ICheckOption[];
    };
}

/**
 * the check boxes search component
 */
@inject("searchEngineStore")
@observer
export default class CheckSearchItem extends Component<ICheckSearchItemProps, ICheckSearchItemState> {

    private ref: HTMLDivElement;
    private hasLabel: boolean;

    constructor(props: Readonly<ICheckSearchItemProps>) {
        super(props);
        this.isChecked = this.isChecked.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.toggleMoreItems = this.toggleMoreItems.bind(this);
        this.updateCheckSearchItem = this.updateCheckSearchItem.bind(this);
        this.props.searchEngineStore.addValue(this.props.name);
        this.state = {moreItemsDisplayed: false};
    }

    /**
     * component did update
     */
    public componentDidUpdate(): void {
        // trick to tell to panel the item has not label anymore
        if (this.props.onChange && this.hasLabel
            && (!this.props.searchEngineStore.values[this.props.name]
                || this.props.searchEngineStore.values[this.props.name].length <= 0
                || !this.props.searchEngineStore.values[this.props.name][0])) {
            this.props.onChange(undefined);
            this.hasLabel = false;
            $(this.ref).trigger("check-search-item:change");
        }
    }

    /**
     * component did mount
     */
    public componentDidMount(): void {
        if (this.props.onChange) {
            this.props.onChange(this.getCurrentLabel(this.props.searchEngineStore));
        }
        $(this.ref).on("check-search-item:update", this.updateCheckSearchItem);

    }

    /**
     * will unmount
     */
    public componentWillUnmount(): void {
        $(this.ref).off("check-search-item:update", this.updateCheckSearchItem);
    }

    /**
     * update item
     * @param e
     * @param data
     */
    private updateCheckSearchItem(e: JQuery.Event, data: ICheckUpdateData) {
        if (this.props.onChange) {
            this.props.onChange(this.getCurrentLabel(this.props.searchEngineStore));
        }
        if (data) {
            this.updateCheckBoxStatus(e, data);
        }

    }

    /**
     * update checkbox status
     * @param e
     * @param data
     */
    private updateCheckBoxStatus(e: JQuery.Event, data: ICheckUpdateData) {
        const updateOptions = data[this.props.name];
        if (updateOptions) {
            updateOptions.options
                .forEach((updateOption: ICheckOption) => this.props.options
                    .filter((option: ICheckOption) => option.code === updateOption.code)
                    .forEach((option: ICheckOption) => option.disabled = updateOption.disabled));
        }
    }

    /**
     * Renders component
     */
    public render(): JSX.Element {
        return this.props.manageOwnLabel ? this.renderItemWithLabel() : this.renderItem();
    }

    /**
     *  Renders item with its label
     */
    private renderItemWithLabel(): JSX.Element {
        return (
            <>
                <div className="item-label-se">
                    {this.renderItemLabel()}
                </div>
                <div className="item-content-se">
                    {this.renderItem()}
                </div>
            </>
        );
    }

    /**
     * Renders label of item
     */
    private renderItemLabel() {
        return (
            <>
                {this.props.label}
                {
                    this.props.manageOwnLabel
                    && this.props.showMoreItemsLabel && this.props.hideMoreItemsLabel
                    && this.props.options.length > this.props.moreItemsLimit &&
                    <span className={"display-all-" + this.props.name} onClick={this.toggleMoreItems}>
                        {this.state.moreItemsDisplayed ? this.props.hideMoreItemsLabel : this.props.showMoreItemsLabel}
                    </span>
                }
            </>
        );
    }

    /**
     * Renders item
     */
    private renderItem(): JSX.Element {
        return (
            <div ref={(ref: HTMLDivElement) => this.ref = ref} className="inner-item-se">
                {this.props.options
                    .filter((value: ICheckOption, index: number) => this.isItemDisplayed(index))
                    .map((option: ICheckOption) => {
                        return (
                            <div key={this.props.name + "_" + option.code} className="input-wrap">
                                <input
                                    type="checkbox"
                                    name={this.props.name}
                                    value={option.code}
                                    id={this.props.name + "_" + option.code}
                                    className="check-box"
                                    style={(this.props.withoutCheckbox ? {display: "none"} : {})}
                                    checked={this.isChecked(option)}
                                    disabled={option.disabled}
                                    onChange={(evt: ChangeEvent<HTMLInputElement>) => this.handleCheck(evt, option)}
                                />
                                <label htmlFor={this.props.name + "_" + option.code}  className="label">{option.label}</label>
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    /**
     * Checks if item at this index should be hidden or not
     * @param index index of item
     */
    private isItemDisplayed(index: number): boolean {
        const isDisplayMoreButton = this.props.showMoreItemsLabel && this.props.hideMoreItemsLabel;
        const isDisplayMoreButtonAndAllItemsShowed: boolean = this.state.moreItemsDisplayed;
        const isDisplayMoreButtonAndCurrentItemShowed: boolean = isDisplayMoreButton && !isDisplayMoreButtonAndAllItemsShowed && index < this.props.moreItemsLimit;
        return isDisplayMoreButtonAndCurrentItemShowed || isDisplayMoreButtonAndAllItemsShowed || !isDisplayMoreButton;
    }

    /**
     * Tests if the option is checked
     * @param option option to test
     */
    private isChecked(option: ICheckOption): boolean {
        const searchEngineStore: SearchEngineStore = this.props.searchEngineStore;
        const optionSelectedValues = searchEngineStore.values[this.props.name];
        const isChecked: boolean = optionSelectedValues && optionSelectedValues.length !== 0 && optionSelectedValues.indexOf(option.code) !== -1;
        if (!isChecked) {
            // Specific parsing for parameters like s_minMan where a single value contains a comma, for ex : [1,3] or [2,4]
            const allValues = optionSelectedValues.join(",");
            return allValues.indexOf(option.code) !== -1;
        }
        return isChecked;
    }

    /**
     * Handles option (un)check event
     * @param evt
     * @param option
     */
    private handleCheck(evt: ChangeEvent<HTMLInputElement>, option: ICheckOption) {
        const searchEngineStore: SearchEngineStore = this.props.searchEngineStore;

        if (evt.currentTarget.checked) {
            if (this.props.singleChoice) {
                searchEngineStore.clearOptions(this.props.name);
            }
            searchEngineStore.addOption(this.props.name, option.code);
        } else {
            if (this.props.singleChoice) {
                searchEngineStore.clearOptions(this.props.name);
            } else {
                searchEngineStore.removeOption(this.props.name, option.code);
                // if value stored separatly ("2","3","4" are stored but option is "2,3,4")
                if (this.isChecked(option)) {
                    option.code
                        .split(",")
                        .forEach((code: string) => searchEngineStore.removeOption(this.props.name, code));
                }
            }
        }

        if (this.props.onChange) {
            this.props.onChange(this.getCurrentLabel(searchEngineStore));
        }

        $(this.ref).trigger("check-search-item:change");
    }

    /**
     * Handle more items toggle event
     */
    public toggleMoreItems() {
        this.setState({moreItemsDisplayed: !this.state.moreItemsDisplayed});
    }

    /**
     * Get current label of option group
     * @param searchEngineStore
     */
    private getCurrentLabel(searchEngineStore: SearchEngineStore): string {
        const selectedValues = searchEngineStore.values[this.props.name];
        if (selectedValues && selectedValues.length > 0) {
            this.hasLabel = true;
            const allValues = selectedValues.join(",");
            return this.props.options
                .filter((option: ICheckOption) => allValues.indexOf(option.code) !== -1)
                .map((option: ICheckOption) => option.label)
                .join(", ");
        }
        return "";
    }
}