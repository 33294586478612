import * as React from "react";
import {Component, SyntheticEvent} from "react";
import {ISearchItem} from "../SearchItem";
import {SearchEngineStore} from "../SearchEngineStore";
import {addHideQueryInputs, ISearchType, setCookiesWithSelectedValues, submitForm} from "../../tools/formTools";
import {IPassengerConstraint} from "../SearchItem/PassengerSearchItem";
import {readCookie} from "../../../../../specific/cookie";

/**
 * interface to describe a search engine
 */
export interface ISearchEngine {
    title: string;
    code: string;
    type: string;
    searchItems?: ISearchItem[];
    baseQuery?: string;
    moreItems?: ISearchItem[];
    defaultValues?: IDefaultValue[];
    searchTypes?: ISearchType[];
    subSearchEngines?: ISearchEngine[];
    iconUrl?: string;
    itemFilter?: IItemFilter;
    submitUrl?: string;
    requiredParameters?: Array<{ name: string; target: string }>;
    passengerConstraints?: IPassengerConstraint;
}

/**
 * the search engine layouts props
 */
export interface ISearchEngineLayoutProps {
    submitUrl?: string;
    submitLabel?: string;
    quickSubmitLabel?: string;
    quickSearchTitle?: string;
    quickInputText?: string;
    defaultSubmit?: boolean;
    pageNode?: string;
    searchEngineStore?: SearchEngineStore;
    itemsColClass?: string;
    buttonColClass?: string;
}

/* eslint-disable */
export interface ISearchEngineLayoutState {}
/* eslint-enable */

/**
 * interface described a code label
 */
export interface IItemFilter {
    title: string;
    name: string;
    itemFilters?: ICodeLabelFilter[];
}

/**
 * interface described a code label
 */
export interface ICodeLabelFilter {
    code?: string;
    label?: string;
    filter: string[];
}

/**
 * interface described a default value
 */
interface IDefaultValue {
    code?: string;
    value?: string;
}

export const SEARCH_ENGINE_CODE_PARAMETER = "type";
export const PAGE_NODE_PARAMETER = "pageNode";

/**
 * the base abstract search engine layout
 */
export default abstract class SearchEngineLayout<T extends ISearchEngineLayoutProps, S extends ISearchEngineLayoutState> extends Component<T, S> {
    /**
     * search engine layout constructor
     * @param props
     */
    protected constructor(props: T) {
        super(props);
    }

    /**
     * get search engine tabs to select a search engine
     */
    protected getTabs(): Array<{ title: string, identifier: string }> {
        return this.props.searchEngineStore.allSearchEngines.map((searchEngine: ISearchEngine) => ({
            title: searchEngine.title,
            identifier: searchEngine.code,
            iconUrl: searchEngine.iconUrl
        }));
    }

    /**
     * add input from the base query of selected search engine
     */
    protected addBaseQueryInputs(): JSX.Element {
        const currentSearchEngine = this.props.searchEngineStore.currentSearchEngine;
        return (
            <>
                <input type="hidden" name={SEARCH_ENGINE_CODE_PARAMETER} value={this.getType()}/>
                <input type="hidden" name={PAGE_NODE_PARAMETER} value= {this.props.pageNode}/>
                <input type="hidden" name={"displayType"} value= {readCookie("displayType")}/>
                {addHideQueryInputs(currentSearchEngine.baseQuery)}
            </>
        );
    }

    /**
     * get the type of search engine to add to query to find the right search engine
     */
    private getType() {
        const currentSearchEngine = this.props.searchEngineStore.currentSearchEngine;
        const searchEngineStore = this.props.searchEngineStore;

        const searchEngineStatusStore = searchEngineStore.getCurrentSearchEngineStatusStore();
        const selectedSubSearchEngine = searchEngineStatusStore ? currentSearchEngine.subSearchEngines[searchEngineStatusStore.subSearchEngineIndex] : undefined;
        if (selectedSubSearchEngine) {
            return currentSearchEngine.code + "," + selectedSubSearchEngine.code;
        } else {
            return currentSearchEngine.code;
        }
    }

    /**
     * submit form if it's possible according to required parameters
     * @param searchEngine
     * @param searchTypes
     */
    protected tryToSubmitForm(searchEngine: ISearchEngine, searchTypes?: ISearchType[]) {
        return (evt: SyntheticEvent) => {
            const form = $(evt.currentTarget);
            const requiredParameters = searchEngine.requiredParameters;
            let errorMessage = "";
            let missingParameters;
            const message = form.serializeArray();
            if (requiredParameters) {
                const itemCodesToHide = this.getItemCodesToHide(searchEngine) || [];
                const filledValueName = message
                    .filter((value: JQuery.NameValuePair) => value.value)
                    .map((value: JQuery.NameValuePair) => value.name);
                missingParameters = requiredParameters
                    .filter(({name}: { name: string; target: string }) => filledValueName.indexOf(name) === -1)
                    .filter(({name}: { name: string; target: string }) => itemCodesToHide.indexOf(name) === -1)
                    .map((parameter: { name: string; target: string }) => ({name: parameter.name, target: parameter.target}));
            }
            const passengerConstraints = searchEngine.passengerConstraints;
            if (passengerConstraints) {
                const requiredParams = passengerConstraints.requiredParameters;
                const constraints = passengerConstraints.constraints;
                if (requiredParams && constraints) {
                    const totalPax = message
                        .filter((value: JQuery.NameValuePair) => requiredParams.indexOf(value.name) !== -1)
                        // tslint:disable-next-line:no-shadowed-variable
                        .reduce((totalPax: number, nb: JQuery.NameValuePair) => totalPax + parseInt(nb.value, 10), 0);
                    const minPaxConstraint = constraints.filter(({name}: { name: string }) => name === "min");
                    if (minPaxConstraint && minPaxConstraint.length > 0) {
                        if (totalPax < minPaxConstraint[0].value) {
                            missingParameters.push({name: "passenger", target: minPaxConstraint[0].target});
                            errorMessage += minPaxConstraint[0].message;
                        }
                    }
                    const maxPaxConstraint = constraints.filter(({name}: { name: string }) => name === "max");
                    if (maxPaxConstraint && maxPaxConstraint.length > 0) {
                        if (totalPax > maxPaxConstraint[0].value) {
                            missingParameters.push({name: "passenger", target: maxPaxConstraint[0].target});
                            errorMessage += maxPaxConstraint[0].message;
                        }
                    }
                }
            }
            $("#se-error-message").html(errorMessage);
            if (errorMessage && errorMessage !== "") {
                $("#se-error-message").removeClass("hidden");
            }
            if (missingParameters && missingParameters.length !== 0) {
                form.trigger("form:error", {errors: missingParameters});
                evt.preventDefault();
                return;
            }
            setCookiesWithSelectedValues(message, searchEngine);
            submitForm(evt, searchTypes || []);
        };
    }

    /**
     * get selected item filter value
     * @param searchEngine
     */
    protected getSelectedItemFilter(searchEngine: ISearchEngine): ICodeLabelFilter {
        const searchEngineStore = this.props.searchEngineStore;

        if (searchEngine && searchEngine.itemFilter) {
            const options = searchEngine.itemFilter.itemFilters;
            if (options && options.length > 0) {
                let selectedValue = options[0];
                if (searchEngineStore.values[searchEngine.itemFilter.name]) {
                    const codeLabels = options
                        .filter((option: ICodeLabelFilter) => option.code === searchEngineStore.values[searchEngine.itemFilter.name][0]);
                    if (codeLabels.length !== 0) {
                        selectedValue = codeLabels[0];
                    }
                }
                if (!searchEngineStore.values[searchEngine.itemFilter.name]) {
                    searchEngineStore.setValue(searchEngine.itemFilter.name, selectedValue.code);
                }
                return selectedValue;
            }
        }
    }

    /**
     * get the item code to not show from the item filter value
     * @param searchEngine
     */
    protected getItemCodesToHide(searchEngine: ISearchEngine): string[] {
        const selectedItemFilter = this.getSelectedItemFilter(searchEngine);
        if (selectedItemFilter) {
            return selectedItemFilter.filter;
        }
    }

}