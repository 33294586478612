/**
 * Initialize the preloader
 */
export function initPreloader() {
    // Hide all preloader after dom is loaded
    $(window).on("load", hideAllPreloader);
}

/**
 * Hide all preloader components
 */
export function hideAllPreloader() {
    $(".cpt-preloader").addClass("d-none");
}

/**
 * Hide the preloader inside a container
 * @param containerSelector string, container of the preloader to hide
 */
export function hidePreloaderFromContainer(containerSelector: string) {
    $(containerSelector).find(".cpt-preloader").addClass("d-none");
}