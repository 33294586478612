import * as React from "react";
import {ErrorMessage} from "../ErrorMessage";
import {Input, InputProps} from "../Input";

export enum BirthDateFormat {
    SLASH,
    DASH,
    INVERTED_SLASH,
    INVERTED_DASH
}

export interface BirthDateInputProps extends InputProps {
    birthDateFormat?: BirthDateFormat;
    displayError?: boolean;
    error?: string;
}

export const BirthDateInput = ({birthDateFormat = BirthDateFormat.SLASH, displayError, error, ...restProps}: BirthDateInputProps) => {
    const getFormatProps = (birthDateFormat?: BirthDateFormat) => {
        switch (birthDateFormat) {
        case BirthDateFormat.SLASH:
            return {mask: "99/99/9999", pattern: "[0-9]{2}/[0-9]{2}/[0-9]{4}"};
        case BirthDateFormat.DASH:
            return {mask: "99-99-9999", pattern: "[0-9]{2}-[0-9]{2}-[0-9]{4}"};
        case BirthDateFormat.INVERTED_SLASH:
            return {mask: "9999/99/99", pattern: "[0-9]{4}/[0-9]{2}/[0-9]{2}"};
        case BirthDateFormat.INVERTED_DASH:
            return {mask: "9999-99-99", pattern: "[0-9]{4}-[0-9]{2}-[0-9]{2}"};
        }
    };

    return (
        <>
            <Input
                type={"text"}
                {...getFormatProps(birthDateFormat)}
                {...restProps}
            />
            {displayError && <ErrorMessage errorLabel={error} />}
        </>
    );
};