import $$ from "jquery-selector-cache";
import Breakpoints from "breakpoints-js";
import {breakpointsConfig} from "../config/responsive";

/**
 * Get the handler callbacks of Breakpoints library
 * @return {IBreakpointsCallbacks} the Event enter handler function
 */
export function getBreakpointsCallbacks(): IBreakpointsCallbacks {
    return {
        enter() {
            addBreakpointClass(this.name);
        }
    };
}

/**
 * Get the array name of Breakpoints configuration
 * @return {string[]} the key of the breakpoints configuration
 */
export function getBreakpointsNames(): string[] {
    return $.map(breakpointsConfig, (propertyOfObject: IBreakpointsBoundary, key: string) => {
        return key;
    });
}

/**
 * Initialize the right breakpoint on starting page.
 */
export function initStartingBreakpoints() {
    Breakpoints(breakpointsConfig);
    addBreakpointClass(Breakpoints.current().name);
}

/**
 * Add and update the body breakpoint class
 * @param breakpointName
 */
function addBreakpointClass(breakpointName: string) {
    const bodyClass: string = $$("body").attr("class") || "";
    const removeClass: string = bodyClass.replace(/(bp[-\w]*)/g, "").trim();
    $$("body").removeClass().addClass(`${removeClass} bp-${breakpointName}`);
}

/**
 * Check it is mobile breakpoint on desktop
 * @returns {boolean}
 */
export function isMobileBreakpoint(): boolean {
    return Breakpoints.is("xs") || Breakpoints.is("sm");
}

/**
 * Check it is tablet breakpoint on desktop
 * @returns {boolean}
 */
export function isTabletBreakpoint(): boolean {
    return Breakpoints.is("md");
}

/**
 * Check it is desktop breakpoint large and extra large
 * @returns {boolean}
 */
export function isDesktopBreakpoint(): boolean {
    return Breakpoints.is("lg") || Breakpoints.is("xl");
}
