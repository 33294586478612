import * as React from "react";
import {SyntheticEvent} from "react";
import {addsCookie} from "../../../../specific/cookie";
import {ISearchEngine} from "../searchEngine/SearchEngineLayout";

/**
 * helper constants to build url
 */
export const urlParameters = {
    and: "++",
    or: ","
};
/**
 * interface described a search type
 */
export interface  ISearchType {
    code?: string;
    value?: string;
}
/**
 * handle submit of the form
 * @param evt
 * @param searchTypes
 */
export function submitForm(evt: SyntheticEvent, searchTypes: ISearchType[]) {
    evt.preventDefault();
    const form = $(evt.currentTarget);
    const submitUrl = form.prop("action");
    const query = filterParameters(form.serializeArray(), searchTypes, submitUrl);
    window.location.href = submitUrl + (query ? ("?" + $.param(query)) : "");
}

/**
 * Creates cookies with the values selected in the Search engine destination form
 * @param message
 * @param searchEngine
 */
export function setCookiesWithSelectedValues(message: JQuery.NameValuePair[], searchEngine: ISearchEngine) {
    const today = new Date();
    today.setHours(23);
    today.setMinutes(59);
    today.setSeconds(59);
    const todayExpires = "; expires=" + today.toUTCString().replace("UTC", "GMT");
    let departDate = "";
    if (searchEngine.code === "searchEngineDestination") {
        for (let i = 0; i < message.length; i++) {
            if (message[i].name === "m_dpci") {
                const city = message[i].value;
                addsCookie("city", city, todayExpires);
            }
            if (message[i].name === "m_dd") {
                departDate += message[i].value;
            }
            if (message[i].name === "m_dmy") {
                departDate += '/' + message[i].value;
            }
        }
        addsCookie("departDate", departDate, todayExpires);
    }
}

/**
 * get search type from searchTypes array, or by default
 * @param name
 * @param searchTypes
 */
export function getSearchType(name: string, searchTypes: ISearchType[]) {
    let searchType = urlParameters.or;
    if (searchTypes && searchTypes.length > 0) {
        for (const type of searchTypes) {
            if (type.code && type.code === name && type.value && type.value === "and") {
                searchType = urlParameters.and;
            }
        }
    }
    return searchType;
}
/**
 * handle submit of the form
 * @param evt
 * @param callBack
 */
export function submitFormWithAjax(evt: SyntheticEvent, searchTypes: ISearchType[], callBack: (response: any) => void) {
    evt.preventDefault();
    const form = $(evt.currentTarget);
    const submitUrl = form.prop("action");
    const query = filterParameters(form.serializeArray(), searchTypes, submitUrl);
    $.ajax(submitUrl + (query ? ("?" + $.param(query)) : "")).done(callBack);
}

/**
 * group same name parameter and filter empty param
 * @param params
 * @param searchTypes
 * @param url
 */
export function filterParameters(params: JQuery.NameValuePair[], searchTypes: ISearchType[], url : string) {
    const finalParam = {};

    params.forEach((param: { value: string, name: string }) => {
        if (param.value.length) {
            const value = param.value;
            const name = param.name;
            if (finalParam[name]) {
                if (finalParam[name].indexOf(value) === -1) {
                    let searchType = urlParameters.or;
                    if (searchTypes && searchTypes.length > 0) {
                        for (const type of searchTypes) {
                            if (type.code && type.code === name && type.value && type.value === "and") {
                                searchType = urlParameters.and;
                            }
                        }
                    }
                    finalParam[name] += searchType + value;
                }

            } else {
                finalParam[name] = value;
            }
        }
    });

    // PRDLV-3645
    // Don't include travellers parameters in query send to salesprocess-v2/searchHotel and salesprocess-v2/searchDP
    if (!url.endsWith("/dynamicDeeplink") && !url.endsWith("/hotelDeeplink")) {
        let travelerIndex = 0;
        let totalNumber = 0;
        for (let roomId = 0; roomId < Number(finalParam["room"]); ++roomId) {
            totalNumber += Number(finalParam["room_adult_" + roomId]);

            for (; travelerIndex < totalNumber; travelerIndex++) {
                finalParam["traveller[" + travelerIndex + "].type"] = "A";
            }

            totalNumber += Number(finalParam["room_child_" + roomId]);
            for (let childBirthDateIndex = 0; travelerIndex < totalNumber; childBirthDateIndex++) {
                const childBirthDateKey = "room_child_" + roomId + "_birthdate_" + childBirthDateIndex;
                if (finalParam.hasOwnProperty(childBirthDateKey)) {
                    finalParam["traveller[" + travelerIndex + "].type"] = "C";
                    finalParam["traveller[" + travelerIndex + "].birthDate"] = finalParam[childBirthDateKey];
                    ++travelerIndex;
                }
            }
        }
    }

    return finalParam;
}

/**
 * add inputs from the given query
 * @param query
 */
export function addHideQueryInputs(query: string): JSX.Element {
    return (
        <>
            {
                query &&
                    query
                        .split("&")
                        .map((parameters: string) => {
                            const codeValue = parameters.split("=");
                            if (codeValue.length === 2) {
                                return <input key={"baseInput_" + codeValue[0]} type="hidden" name={codeValue[0]} value={codeValue[1]}/>;
                            }
                        })
            }
        </>
    );
}